import React, { useContext, useState } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const UnoQuattro = () => {
  const context = useContext(EnpamContext)
  const [borsaMMg, setBorsaMMg] = useState(false)
  const [intramoenia, setIntramoenia] = useState(false)
  const [altriFondi, setAltriFondi] = useState(false)
  const [nessuna, setNessuna] = useState(false)

  const avanti = () => {
    if (borsaMMg) context.setScheda('.15_due')
    if (intramoenia) context.setScheda('.15_due')
    if (altriFondi) context.setScheda('.15_nove')
    if (nessuna) context.setScheda('.pre_modello_d')
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={50} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        È IL MOMENTO DI CAPIRE SE HAI I REQUISITI PER RICHIEDERE LA CONTRIBUZIONE
        RIDOTTA
      </h4>
      <h6 className="font-regular mb-5 interlinea-30">
        Tenendo presente le situazioni che prevedono l&apos;obbligo di versare
        l&apos;aliquota intera, lo scorso anno rientravi in una di queste tre
        categorie? O ci rientri quest&apos;anno?
      </h6>
      <Button
        className={classNames(
          { 'bottone-verde': borsaMMg },
          { 'bottone-verde-outline': !borsaMMg },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(true)
          setIntramoenia(false)
          setAltriFondi(false)
          setNessuna(false)
        }}
      >
        Sono tirocinante nel corso di formazione di medicina generale
      </Button>
      <Button
        className={classNames(
          { 'bottone-verde': intramoenia },
          { 'bottone-verde-outline': !intramoenia },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(true)
          setAltriFondi(false)
          setNessuna(false)
        }}
      >
        Sono un lavoratore dipendente e ho percepito compensi per l&apos;attività
        intramoenia
      </Button>
      <Button
        className={classNames(
          { 'bottone-verde': altriFondi },
          { 'bottone-verde-outline': !altriFondi },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(false)
          setAltriFondi(true)
          setNessuna(false)
        }}
      >
        Sono soggetto a un&apos;altra forma di previdenza obbligatoria (INPS, Fondo
        della medicina convenzionata e accreditata ENPAM...)
      </Button>
      <Button
        className={classNames(
          { 'bottone-verde': nessuna },
          { 'bottone-verde-outline': !nessuna },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(false)
          setAltriFondi(false)
          setNessuna(true)
        }}
      >
        No, non rientro in nessuna di queste categorie
      </Button>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default UnoQuattro
