import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import AllegaFile from './AllegaFile'
import RiferimentoAmministrazione from './RiferimentoAmministrazione'
import ScadenzaPagamento from './ScadenzaPagamento'

const ModalCampiAggiuntivi = (props) => {
  const fiscoContext = useContext(FiscomedContext)
  const [modal, setModal] = useState(false)
  const [importoContributoCassa, setImportoContributoCassa] = useState(
    fiscoContext.dati.datiAggiuntivi.contributoCassa
  )
  const [aliquota, setAliquota] = useState(
    fiscoContext.dati.datiAggiuntivi.aliquotaCassa
  )

  const aggiungiDati = () => {
    if (
      importoContributoCassa === '' ||
      aliquota === '' ||
      importoContributoCassa === 0 ||
      aliquota === 0
    ) {
      annulla()
      return
    }
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      contributoCassa:
        typeof importoContributoCassa === 'string'
          ? Number(importoContributoCassa.replace(',', '.'))
          : Number(importoContributoCassa),
      aliquotaCassa:
        typeof aliquota === 'string'
          ? Number(aliquota.replace(',', '.'))
          : Number(aliquota),
    })
    setImportoContributoCassa(
      typeof importoContributoCassa === 'string'
        ? Number(importoContributoCassa.replace(',', '.'))
        : Number(importoContributoCassa)
    )
    setAliquota(
      typeof aliquota === 'string'
        ? Number(aliquota.replace(',', '.'))
        : Number(aliquota)
    )
  }

  const annulla = () => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      contributoCassa: '',
      aliquotaCassa: '',
    })
    setImportoContributoCassa('')
    setAliquota('')
  }

  const componentModal = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Aggiungi dati alla tua fattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Potrebbe capitare che il cliente ti chieda di inserire dei dati particolari
          per emettere la tua fattura.
          <br /> Attraverso questa schermata potrai aggiungere i dati richiesti.
          <br />
          Ricorda che il file che alleghi in questa schermata non è visibile nella
          preview della fattura, ma è comunque inserito correttamente nella fattura.
        </h6>
        <br />
        {['Fattura elettronica', 'Rettifica pr', 'Fattura PA'].includes(
          fiscoContext.dati.tipologiaFattura
        ) && (
          <>
            <Form>
              <Label>Ritenuta ENPAM</Label>
              <br />
              <span className="text-muted">
                TipoRitenuta RT05, CausalePagamento A
              </span>
              <Row>
                <Col xs="7">
                  <FormGroup>
                    <Label>ImportoRitenuta</Label>
                    <Input
                      type="text"
                      value={importoContributoCassa}
                      onChange={(e) => setImportoContributoCassa(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="5">
                  <FormGroup>
                    <Label>Aliquota %</Label>
                    <Input
                      type="number"
                      value={aliquota}
                      onChange={(e) => setAliquota(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <br />
          </>
        )}
        {['Fattura elettronica', 'Rettifica pr', 'Fattura PA'].includes(
          fiscoContext.dati.tipologiaFattura
        ) && (
          <>
            <RiferimentoAmministrazione />
            <br />
            <ScadenzaPagamento />
            <br />
          </>
        )}
        <AllegaFile />
        <br />
        <Button
          className="bottone-blu float-right ml-2"
          onClick={() => {
            setModal(false)
            aggiungiDati()
          }}
        >
          AGGIUNGI I DATI
        </Button>
        <Button
          className="bottone-rosso float-right"
          onClick={() => {
            setModal(false)
            annulla()
          }}
        >
          CANCELLA I DATI
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      <small
        className="d-inline align-middle testo-blu"
        style={{ cursor: 'pointer' }}
        onClick={() => setModal(true)}
      >
        AGGIUNGI <i className="bi bi-plus-lg"></i>
      </small>
      {componentModal}
    </div>
  )
}

export default ModalCampiAggiuntivi
