import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FormGroup, Input, Alert } from 'reactstrap'
import request from 'superagent'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Footer from '../landing/Footer'

const BlogHomeDesktop = () => {
  const [articoli, setArticoli] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const { categoriaPrimaria } = useParams()
  const [categoria, setCategoria] = useState(categoriaPrimaria)

  useEffect(() => {
    request
      .get(
        `${process.env.REACT_APP_API_URL}/blog/list-articoli-categoria/${categoria}`
      )
      .then((response) => setArticoli(response.body.articoli))
      .catch((e) => {
        console.error(e.message)
      })
  }, [categoria])

  const articoliComponent = () =>
    articoli.map((elemento, i) => {
      if (elemento.articolo.titolo.toLowerCase().includes(searchKey.toLowerCase())) {
        return (
          <Col xs={6} key={i}>
            <div key={i} className="m-5">
              <img
                style={{ width: '100%' }}
                src={elemento.articolo.miniatura_base64}
                alt={elemento.articolo.titolo}
                className="mb-2"
              />
              <div className="mt-4">
                <h5 className="testo-landing d-inline-block mr-3">
                  {moment(elemento.articolo.data_aggiornamento)
                    .locale('it')
                    .format('D MMMM YYYY')}
                </h5>
                •
                <h5 className="testo-landing d-inline-block ml-3">
                  {parseInt(elemento.articolo?.testo.length / 1260) + 1}{' '}
                  {parseInt(elemento.articolo?.testo.length / 1260) === 0
                    ? 'minuto'
                    : 'minuti'}{' '}
                  di lettura
                </h5>
              </div>
              <h4 className="font-bold mt-4">{elemento.articolo.titolo}</h4>
              <h5 className="testo-landing mt-4 interlinea-30">
                {elemento.articolo?.descrizione?.substring(0, 300)}
              </h5>
              <div className="d-flex justify-content-between my-5">
                <div>
                  {elemento.tag_associati[0]?.nome && (
                    <div
                      type="button"
                      onClick={() => setCategoria(elemento.tag_associati[0]?.nome)}
                      style={{
                        backgroundColor: '#66d98e',
                        color: 'white',
                        padding: '6px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        borderRadius: '7px',
                        display: 'inline-block',
                        marginRight: '15px',
                        fontWeight: 500,
                      }}
                    >
                      {elemento.tag_associati[0]?.nome}
                    </div>
                  )}
                  {elemento.tag_associati[1]?.nome && (
                    <div
                      type="button"
                      onClick={() => setCategoria(elemento.tag_associati[1]?.nome)}
                      style={{
                        backgroundColor: '#66d98e',
                        color: 'white',
                        padding: '6px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        borderRadius: '7px',
                        marginRight: '10px',
                        display: 'inline-block',
                        fontWeight: 500,
                      }}
                    >
                      {elemento.tag_associati[1]?.nome}
                    </div>
                  )}
                </div>
                <Link to={'/blog/articolo/' + elemento.articolo.url}>
                  <i
                    className="bi bi-arrow-right-short"
                    style={{ fontSize: '40px', marginTop: '-11px' }}
                    type="button"
                  ></i>
                </Link>
              </div>
            </div>
          </Col>
        )
      } else {
        return null
      }
    })

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta property="title" content="Blog" />
        <meta property="og:site_name" content="fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/blog/:categoria" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Il blog di fiscomed.it" />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <br />
        <br />
        <div className="header-desktop-blog" />
        <center>
          <h1 className="titolo-landing-fiscomed font-medium mt-5">
            Questo è il nostro blog
          </h1>
          <br />
          <h5 className="testo-landing-fiscomed">
            Qui puoi trovare articoli sulla fiscalità della professione medica,
            <br />
            scritti in parole semplici e approvati dai nostri commercialisti
          </h5>
        </center>
        <br />
        <br />
        <FormGroup className="mb-3">
          <Input
            type="text"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="🔎&nbsp;&nbsp; Digita una parola"
            bsSize="lg"
            style={{
              maxWidth: '1200px',
              margin: 'auto',
              height: 'calc(2.5em + 1rem + 2px)',
              fontSize: '1.2rem',
              borderRadius: '10px',
            }}
          />
        </FormGroup>
        <br />
        <center>
          <span
            className="testo-landing-fiscomed text-muted"
            style={{ lineHeight: '15px', fontSize: '1rem' }}
          >
            oppure
          </span>
          <br />
          <span
            className="testo-landing-fiscomed text-muted"
            style={{ lineHeight: '15px' }}
          >
            cerca per categoria
          </span>
        </center>
        <div className="d-flex mt-5">
          <div
            type="button"
            onClick={() => setCategoria('home')}
            style={{
              backgroundColor: categoria === 'home' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            In evidenza
          </div>
          <div
            type="button"
            onClick={() => setCategoria('recenti')}
            style={{
              backgroundColor: categoria === 'recenti' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            I più recenti
          </div>
          <div
            type="button"
            onClick={() => setCategoria('Fatturazione')}
            style={{
              backgroundColor: categoria === 'Fatturazione' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            Fatturazione
          </div>
          <div
            type="button"
            onClick={() => setCategoria('ENPAM')}
            style={{
              backgroundColor: categoria === 'ENPAM' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            ENPAM
          </div>
          <div
            type="button"
            onClick={() => setCategoria('Sistema tessera sanitaria')}
            style={{
              backgroundColor:
                categoria === 'Sistema tessera sanitaria' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            Sistema Tessera Sanitaria
          </div>
          <div
            type="button"
            onClick={() => setCategoria('Regime forfettario')}
            style={{
              backgroundColor:
                categoria === 'Regime forfettario' ? '#0583f2' : '#66d98e',
              color: 'white',
              padding: '6px',
              paddingLeft: '20px',
              paddingRight: '20px',
              borderRadius: '7px',
              margin: 'auto',
              fontWeight: 500,
            }}
          >
            Regime forfettario
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        {articoli.length === 0 ? (
          <center>
            <Alert color="warning" style={{ maxWidth: '400px' }}>
              Mi dispiace, ma ancora non ci sono articoli che rientrano in questa
              categoria 😢
            </Alert>
          </center>
        ) : (
          <Row>{articoliComponent()}</Row>
        )}
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default BlogHomeDesktop
