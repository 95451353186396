import React, { useContext } from 'react'
import { DropdownItem } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ItemFatturaDiCortesia = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <DropdownItem
      onClick={() => riepilogoContext.scaricaFatturaCortesia(props.dati.fattura.id)}
    >
      Scarica fattura di cortesia
    </DropdownItem>
  )
}

export default ItemFatturaDiCortesia
