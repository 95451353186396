import React, { useContext } from 'react'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import moment from 'moment'

const ContainerIncasso = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <>
      {props.incassata === 'Si' && (
        <span className="testo-verde">
          {moment(props?.dati?.fattura?.data_incasso).format('DD/MM/YYYY')}
        </span>
      )}
      {props.incassata === 'No' && (
        <span
          onClick={() => {
            riepilogoContext.apriModalIncasso(props.id)
          }}
          style={{ cursor: 'pointer' }}
          className="text-primary"
        >
          <i className="bi bi-piggy-bank"></i> Incassa
        </span>
      )}
    </>
  )
}
export default ContainerIncasso
