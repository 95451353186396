import React from 'react'
import { UserContext } from './User'
import moment from 'moment'
import {
  idrataFatture,
  caricaFattura,
  cancellaFattura,
  inviaModificaFattura,
  scaricaFattura,
} from '../utility/QueryImportaFattura'

const ImportaFatturaContext = React.createContext()

const emptyState = {
  fatture: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConFattureEmesse: [],
  loading: true,
  modalImportOpen: false,
  modalUpdateOpen: false,
  tipologia: 'fattura',
  fatturaSelezionata: null,
  trattenutaEnpam: 0,
  dataIncasso: null,
  numeroFattura: null,
  importo: null,
  trasmessaTS: false,
  fileFattura: null,
  upLoading: false,
}

class ImportaFatturaProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  idrata = () =>
    idrataFatture(this.context.user, this.state.annoDiRiferimento).then(
      (responseBody) => {
        this.setState({
          ...this.state,
          loading: false,
          fatture: responseBody.fatture,
          anniConFattureEmesse: responseBody.anniConFattureEmesse,
        })
      }
    )

  impostaAnnoEIdrata = (anno) =>
    this.setState({ loading: true }, () => {
      idrataFatture(this.context.user, anno).then((responseBody) => {
        this.setState({
          ...this.state,
          annoDiRiferimento: anno,
          fatture: responseBody.fatture,
          anniConFatturaEmessa: responseBody.anni_con_fattura_emessa,
          loading: false,
        })
      })
    })

  statoModalImport = (stato) => {
    this.setState({
      ...this.state,
      dataIncasso: null,
      tipologia: 'fattura',
      numeroFattura: null,
      importo: null,
      trattenutaEnpam: 0,
      fileFattura: null,
      modalImportOpen: stato,
      trasmessaTS: false,
    })
  }

  statoModalUpdate = (stato) => {
    this.setState({
      ...this.state,
      dataIncasso: null,
      numeroFattura: null,
      importo: null,
      trattenutaEnpam: 0,
      fileFattura: null,
      modalUpdateOpen: stato,
      trasmessaTS: false,
    })
  }

  impostaTipologia = (tipologia) => {
    this.setState({
      ...this.state,
      tipologia,
    })
  }

  impostaAnnoIncasso = (anno) => {
    this.setState({
      ...this.state,
      dataIncasso: anno,
    })
  }

  impostaNumeroFattura = (numero) => {
    this.setState({
      ...this.state,
      numeroFattura: numero,
    })
  }

  impostaImporto = (importo) => {
    this.setState({
      ...this.state,
      importo,
    })
  }

  impostaTrattenutaEnpam = (trattenutaEnpam) => {
    this.setState({
      ...this.state,
      trattenutaEnpam,
    })
  }

  impostaFileFattura = (file) => {
    this.setState({
      ...this.state,
      fileFattura: file,
    })
  }

  impostaTrasmessaTS = (trasmessaTS) => {
    this.setState({
      ...this.state,
      trasmessaTS,
    })
  }

  aggiungiFattura = async () => {
    if (this.state.dataIncasso < 2010 || this.state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (this.state.importo < 0) {
      alert("L'importo della fattura deve essere un numero positivi")
      return
    }
    if (this.state.fileFattura === '' || this.state.fileFattura === null) {
      alert('Seleziona un file fattura')
      return
    }
    if (this.state.fileFattura.size / 1073741824 > 0.05) {
      alert('La dimensione del file non può superare i 20MB')
      return
    }
    const formData = new FormData()
    formData.append('tipologia', this.state.tipologia)
    formData.append('fileFattura', this.state.fileFattura)
    formData.append('token', this.context.user.auth_token)
    formData.append('dataIncasso', this.state.dataIncasso)
    formData.append('numeroFattura', this.state.numeroFattura)
    formData.append('importo', this.state.importo)
    formData.append('trattenutaEnpam', (this.state.trattenutaEnpam * 100).toFixed(0))
    formData.append('anno', this.state.annoDiRiferimento)
    formData.append('trasmessaTS', this.state.trasmessaTS)
    this.setState({ upLoading: true })
    const response = await caricaFattura(formData)
    if (response) {
      this.setState({
        upLoading: false,
        fatture: response.fatture,
        anniConFattureEmesse: response.anniConFattureEmesse,
        modalImportOpen: false,
        dataIncasso: null,
        numeroFattura: null,
        importo: null,
        fileFattura: null,
        tipologia: 'fattura',
        trattenutaEnpam: 0,
        trasmessaTS: false,
      })
    }
    return response
  }

  cancellaFile = async (uuidFile) => {
    this.setState({ upLoading: true })
    const response = await cancellaFattura(
      this.context.user,
      uuidFile,
      this.state.annoDiRiferimento
    )
    if (response) {
      this.setState({
        upLoading: false,
        fatture: response.fatture,
        anniConFattureEmesse: response.anniConFattureEmesse,
      })
    }
    return response
  }

  modificaFattura = async (uuidFile) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.uuid_file === uuidFile
    )
    this.setState({
      ...this.state,
      modalUpdateOpen: true,
      fatturaSelezionata,
      dataIncasso: fatturaSelezionata.data_incasso,
      numeroFattura: fatturaSelezionata.numero,
      importo: fatturaSelezionata.importo / 100,
      trattenutaEnpam: fatturaSelezionata.trattenuta_enpam / 100,
      tipologia: fatturaSelezionata.tipologia,
      trasmessaTS: fatturaSelezionata.inviata_ts,
    })
  }

  inviaModifica = async () => {
    if (this.state.dataIncasso < 2010 || this.state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (this.state.importo < 0) {
      alert("L'importo deve essere un numero positivi")
      return
    }
    if (this.state.trattenutaEnpam < 0 && this.state.tipologia === 'cedolino') {
      alert('La trattenuta enpam deve essere un numero positivo')
      return
    }
    this.setState({ upLoading: true })
    const response = await inviaModificaFattura(
      this.context.user,
      this.state.fatturaSelezionata.uuid_file,
      this.state.dataIncasso,
      this.state.numeroFattura,
      this.state.importo,
      this.state.annoDiRiferimento,
      this.state.tipologia,
      (this.state.trattenutaEnpam * 100).toFixed(0),
      this.state.trasmessaTS
    )
    if (response) {
      this.setState({
        upLoading: false,
        fatture: response.fatture,
        anniConFattureEmesse: response.anniConFattureEmesse,
        modalUpdateOpen: false,
        dataIncasso: null,
        numeroFattura: null,
        importo: null,
        trattenutaEnpam: 0,
        fileFattura: null,
        tipologia: 'fattura',
        trasmessaTS: false,
      })
    }
    return response
  }

  scaricaFile = async (uuidFile, nomeFile) => {
    const response = await scaricaFattura(this.context.user, uuidFile, nomeFile)
    return response
  }

  render() {
    const { children } = this.props

    const context = {
      dati: this.state,
      idrata: this.idrata,
      statoModalImport: this.statoModalImport,
      impostaAnnoEIdrata: this.impostaAnnoEIdrata,
      impostaTipologia: this.impostaTipologia,
      impostaAnnoIncasso: this.impostaAnnoIncasso,
      impostaNumeroFattura: this.impostaNumeroFattura,
      impostaImporto: this.impostaImporto,
      impostaTrattenutaEnpam: this.impostaTrattenutaEnpam,
      impostaFileFattura: this.impostaFileFattura,
      impostaTrasmessaTS: this.impostaTrasmessaTS,
      aggiungiFattura: this.aggiungiFattura,
      cancellaFile: this.cancellaFile,
      modificaFattura: this.modificaFattura,
      statoModalUpdate: this.statoModalUpdate,
      inviaModifica: this.inviaModifica,
      scaricaFile: this.scaricaFile,
    }

    return (
      <ImportaFatturaContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </ImportaFatturaContext.Provider>
    )
  }
}

export { ImportaFatturaProvider as default, ImportaFatturaContext }
