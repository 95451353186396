import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import { Link } from 'react-router-dom'
import { getLast } from '../../../app/utility/array'

const ModalScartoVariazione = () => {
  const riepilogoContext = useContext(RiepilogoContext)
  const lastEsito = getLast(riepilogoContext.dati.fatturaSelezionata?.esito)
  return (
    <Modal
      isOpen={riepilogoContext.dati.modalScartoVariazioneOpen}
      toggle={() => riepilogoContext.chiudiModalScartoVariazione()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalScartoVariazione()}
        className="h4 font-regular font-grigio-scuro"
      >
        Nota di variazione scartata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La nota di variazione è stata scartata perché ci sono degli errori.
          Purtroppo è una cosa talmente rara che non abbiamo previsto un modo per
          risolverla dalla nostra piattaforma 😅
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          <Link to="/chat-commercialista">Contattaci</Link> prima possibile e insieme
          al tuo commercialista invieremo la rettifica di questa nota di variazione.
          Ti promettiamo che se questo caso si verificherà anche ad altri utenti,
          implementeremo un modo veloce per risolverlo dalla piattaforma.
        </h6>
        <br />
        <h6 style={{ lineHeight: '25px' }}>
          Questi sono gli errori trovati dallo SdI che dovrai comunicarci quando ci
          contatterai.
        </h6>
        <br />

        <Alert color="danger">{lastEsito?.suggerimento}</Alert>
      </ModalBody>
    </Modal>
  )
}

export default ModalScartoVariazione
