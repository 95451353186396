import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { Button, Card } from 'reactstrap'
import ModalCliente from '../Componenti/ModalCliente'
import Prestazioni from '../Componenti/Prestazioni'
import Nota from '../Componenti/Nota'
import { Link, useHistory } from 'react-router-dom'
import AddebitoMarcaDaBollo from '../Componenti/AddebitoMarcaDaBollo'
import MarcaDaBollo from '../Componenti/MarcaDaBollo'
import ModalPagamento from '../Componenti/ModalPagamento'

const PannelloCartacea = () => {
  const fiscoContext = useContext(FiscomedContext)
  const history = useHistory()

  const salvaEStampa = () => {
    if (!fiscoContext.dati.loading) {
      fiscoContext.aggiornaLoading(true)
      fiscoContext
        .creaFatturaCartacea()
        .then((idFattura) => {
          fiscoContext.aggiornaLoading(false)
          history.push('/fiscomed/nuova-fattura/esito-fattura-cartacea/' + idFattura)
        })
        .catch(() => {
          fiscoContext.aggiornaLoading(false)
        })
    }
  }

  return (
    <Card body className="mt-2 mb-5">
      <div className="d-flex flex-row">
        <h4 className="font-medium">
          <i className="bi bi-person-video2"></i> Cliente
        </h4>
      </div>
      <ModalCliente />
      <h4 className="font-medium mt-4">
        <i className="bi bi-clipboard2-pulse"></i> Prestazioni
      </h4>{' '}
      <Prestazioni />
      <div className="d-flex flex-row mt-4">
        <h4 className="d-inline align-bottom font-medium ">
          <i className="bi bi-credit-card"></i> Pagamento{' '}
        </h4>
      </div>
      <ModalPagamento />
      <h4 className="mt-4 font-medium">
        <i className="bi bi-sticky" style={{ marginLeft: '-2px' }}></i> Marca da
        bollo
      </h4>
      <AddebitoMarcaDaBollo />
      <MarcaDaBollo />
      <h4 className="d-inline align-bottom font-medium mt-4">
        <i className="bi bi-pencil-square"></i> Nota{' '}
      </h4>
      <Nota />
      <div className="mt-4 mb-1">
        <Button className="bottone-verde float-right" onClick={() => salvaEStampa()}>
          CREA FATTURA
        </Button>
        <Button
          className="bottone-rosso-outline mr-3 float-right"
          tag={Link}
          to="/fiscomed"
        >
          ANNULLA
        </Button>
      </div>
    </Card>
  )
}

export default PannelloCartacea
