import React from 'react'
import { UserContext } from './User'
import {
  finalizzaFatturaCartacea,
  finalizzaProforma,
  idrataFattura,
  inviaFattureElettronica,
  idrataNotaDiVariazionePR,
  inviaNotaDiCreditoPr,
  inviaNotaDiDebitoPr,
  idrataFatturaRettifica,
  inviaFatturaPA,
  idrataNotaDiVariazionePA,
  inviaNotaDiCreditoPa,
  queryAggiornaMetodoDiPagamento,
  inviaAutofattura,
} from '../utility/QueryFattura'
import {
  syncMetodiDiPagamento,
  cancellaMetodoDiPagamento,
} from '../utility/QueryMiscellanea'
import { controlloFattura } from '../utility/controllerApi'
import {
  emptyState,
  idrataFatturaObject,
  idrataNDVariazioneObject,
  idrataFatturaDiRettificaObject,
  aggiornaTipologiaFatturaObject,
  emptyStateIdratato,
  proformaObject,
  fatturaCartaceaObject,
  fatturaElettronicaObject,
  fatturaPAObject,
  rettificaPrObject,
  notaDiCreditoPrObject,
  notaDiCreditoPAObject,
  notaDiDebitoPrObject,
  autofatturaObject,
} from '../utility/FiscomedDataObjects'

const FiscomedContext = React.createContext()

class FiscomedProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  idrata = () =>
    idrataFattura(this.context.user).then((responseBody) => {
      this.setState({ ...this.state, ...idrataFatturaObject(responseBody) })
    })

  idrataNDVariazionePr = (idFattura) =>
    idrataNotaDiVariazionePR(this.context.user, idFattura).then((responseBody) =>
      this.setState({ ...this.state, ...idrataNDVariazioneObject(responseBody) })
    )

  idrataNDVariazionePa = (idFattura) =>
    idrataNotaDiVariazionePA(this.context.user, idFattura).then((responseBody) =>
      this.setState({ ...this.state, ...idrataNDVariazioneObject(responseBody) })
    )

  idrataFatturaDiRettifica = (idFattura) =>
    idrataFatturaRettifica(this.context.user, idFattura).then((responseBody) =>
      this.setState({
        ...this.state,
        ...idrataFatturaDiRettificaObject(responseBody),
      })
    )

  aggiornaLoading = (loading) => this.setState({ ...this.state, loading })

  aggiornaNumeroFattura = (numeroFattura) =>
    this.setState({ ...this.state, numeroFattura })

  aggiornaNumeroAutofattura = (numeroAutofattura) =>
    this.setState({ ...this.state, numeroAutofattura })

  aggiornaDataFattura = (dataFattura) =>
    this.setState({ ...this.state, dataFattura })

  aggiornaMetodoDiPagamento = (
    metodoDiPagamento,
    metodoDiPagamentoAttivo = 'Contante'
  ) => {
    this.setState({ ...this.state, metodoDiPagamento, metodoDiPagamentoAttivo })
  }

  inizializzaMetodoDiPagamento = () =>
    syncMetodiDiPagamento(this.context.user).then((mDiPagamento) =>
      this.setState({ ...this.state, metodoDiPagamento: mDiPagamento })
    )

  rimuoviMetodoDiPagamento = async (idPagamento) => {
    const pagamentiResponse = await cancellaMetodoDiPagamento(
      this.context.user,
      idPagamento
    )
    return pagamentiResponse
  }

  aggiornaClienti = (clienti, idClienteAttivo = false) => {
    if (idClienteAttivo) {
      const clienteAttivo = clienti.find((cliente) => cliente.id === idClienteAttivo)
      this.setState({ ...this.state, clienti, clienteAttivo })
    } else {
      this.setState({ ...this.state, clienti, clienteAttivo: clienti[0] })
    }
  }

  aggiornaClienteAttivo = (posizioneArray) =>
    this.setState({
      ...this.state,
      clienteAttivo: this.state.clienti[posizioneArray],
    })

  aggiornaClienteAttivoDaId = (id) => {
    const clienteAttivo = this.state.clienti.find((cliente) => cliente.id === id)
    this.setState({
      ...this.state,
      clienteAttivo,
    })
  }

  aggiungiPrestazioni = (prestazione) => {
    let somma = 0
    const totalePrestazioni = [...this.state.prestazioni, prestazione]
    totalePrestazioni.forEach((pres) => {
      const sommaProvvisoria =
        somma + Math.round(parseFloat(pres.importo) * pres.quantita * 1e2) / 1e2
      somma = Math.round(sommaProvvisoria * 1e2) / 1e2
    })
    if (this.state.addebitoMarcaDaBollo) somma = Math.round((somma + 2) * 1e2) / 1e2
    this.setState({
      ...this.state,
      totaleImporto: somma,
      prestazioni: totalePrestazioni,
    })
  }

  aggiungiPrestazioneAutofattura = (prestazione) => {
    let somma = 0
    let sommaIva = 0
    const totalePrestazioni = [...this.state.prestazioniAutofattura, prestazione]
    totalePrestazioni.forEach((pres) => {
      const sommaProvvisoria =
        somma + Math.round(parseFloat(pres.importoTotale) * 1e2) / 1e2
      somma = Math.round(sommaProvvisoria * 1e2) / 1e2
      const sommaIvaProvvisoriaIva =
        sommaIva + Math.round(parseFloat(pres.importoIVA) * 1e2) / 1e2
      sommaIva = Math.round(sommaIvaProvvisoriaIva * 1e2) / 1e2
    })
    this.setState({
      ...this.state,
      totaleImportoAutofattura: (somma * 1e2) / 1e2,
      prestazioniAutofattura: totalePrestazioni,
      IVATotale: (sommaIva * 1e2) / 1e2,
    })
  }

  rimuoviPrestazione = (posizioneArray) => {
    let somma = 0
    const prestazioni = this.state.prestazioni
    prestazioni[posizioneArray] = null
    const nuovoArray = prestazioni.filter((prestazione) => prestazione !== null)
    nuovoArray.forEach((pres) => {
      const sommaProvvisoria =
        somma + Math.round(parseFloat(pres.importo) * pres.quantita * 1e2) / 1e2
      somma = Math.round(sommaProvvisoria * 1e2) / 1e2
    })
    if (this.state.addebitoMarcaDaBollo) somma = Math.round((somma + 2) * 1e2) / 1e2
    this.setState({ ...this.state, totaleImporto: somma, prestazioni: nuovoArray })
  }

  rimuoviPrestazioneAutofattura = (posizioneArray) => {
    let somma = 0
    let sommaIva = 0
    const prestazioni = this.state.prestazioniAutofattura
    prestazioni[posizioneArray] = null
    const nuovoArray = prestazioni.filter((prestazione) => prestazione !== null)
    nuovoArray.forEach((pres) => {
      const sommaProvvisoria =
        somma + Math.round(parseFloat(pres.importoTotale) * 1e2) / 1e2
      somma = Math.round(sommaProvvisoria * 1e2) / 1e2
      const sommaIvaProvvisoriaIva =
        sommaIva + Math.round(parseFloat(pres.importoIVA) * 1e2) / 1e2
      sommaIva = Math.round(sommaIvaProvvisoriaIva * 1e2) / 1e2
    })
    this.setState({
      ...this.state,
      totaleImportoAutofattura: (somma * 1e2) / 1e2,
      prestazioniAutofattura: nuovoArray,
      IVATotale: (sommaIva * 1e2) / 1e2,
    })
  }

  aggiornaTipologiaFattura = (tipologiaFattura) => {
    this.setState({
      ...this.state,
      ...aggiornaTipologiaFatturaObject(tipologiaFattura),
    })
  }

  aggiornaMetodoDiPagamentoAttivo = (posizioneArray) =>
    this.setState({
      ...this.state,
      metodoDiPagamentoAttivo: this.state.metodoDiPagamento[posizioneArray],
    })

  aggiornaMetodoDiPagamentoAttivoDaID = (id) => {
    let metodoDiPagamentoAttivo = {}
    if (id === 'Contante') {
      metodoDiPagamentoAttivo = 'Contante'
    } else {
      metodoDiPagamentoAttivo = this.state.metodoDiPagamento.find(
        (metodo) => metodo.id === id
      )
    }
    this.setState({
      ...this.state,
      metodoDiPagamentoAttivo,
    })
  }

  modificaMetodoDiPagamento = async (metodoDiPagamento) => {
    const pagamentiResponse = await queryAggiornaMetodoDiPagamento(
      this.context.user,
      metodoDiPagamento
    )
    this.setState({
      ...this.state,
      metodoDiPagamento: pagamentiResponse.metodidipagamento,
    })
  }

  aggiornaNota = (nota) => {
    this.setState({
      ...this.state,
      nota,
      notaPresente: nota.length > 0,
    })
  }

  aggiornaAddebitoMarcaDaBollo = (addebitoMarcaDaBollo) => {
    let somma = 0
    this.state.prestazioni.forEach(
      (pres) =>
        (somma =
          somma + Math.round(parseFloat(pres.importo) * pres.quantita * 1e2) / 1e2)
    )
    if (addebitoMarcaDaBollo) somma = Math.round((somma + 2) * 1e2) / 1e2
    this.setState({ addebitoMarcaDaBollo, totaleImporto: somma })
  }

  aggiornaIdentificativoMarcaDaBollo = (identificativoMarcaDaBollo) =>
    this.setState({ identificativoMarcaDaBollo })

  aggiornaDatiAggiuntivi = (datiAggiuntivi) =>
    this.setState({ ...this.state, datiAggiuntivi })

  aggiornaDescrzioneNotaDiVariazione = (descrizioneNotaDiVariazione) =>
    this.setState({ ...this.state, descrizioneNotaDiVariazione })

  aggiornaImportoNotaDiVariazione = (importoNotaDiVariazione) =>
    this.setState({ ...this.state, importoNotaDiVariazione })

  aggiornaTipologiaAutofattura = (tipologiaAutofattura) =>
    this.setState({ ...this.state, tipologiaAutofattura })

  aggiornaIdDocumentoAutofattura = (idDocumentoAutofattura) =>
    this.setState({ ...this.state, idDocumentoAutofattura })

  aggiornaDataDocumentoAutofattura = (dataDocumentoAutofattura) =>
    this.setState({ ...this.state, dataDocumentoAutofattura })

  creaProforma = async () => {
    const datiDaTrasmettere = proformaObject(this.state)
    const valore = await finalizzaProforma(this.context.user, datiDaTrasmettere)
    if (valore) {
      this.setState({ ...emptyStateIdratato(this.state) })
    }
    return valore
  }

  creaFatturaCartacea = async () => {
    const datiDaTrasmettere = fatturaCartaceaObject(this.state)
    const tester = await controlloFattura(this.state, 'cartacea')
    if (tester.errorePresente) {
      throw new Error('Errore nella fattura cartacea')
    } else {
      const valore = await finalizzaFatturaCartacea(
        this.context.user,
        datiDaTrasmettere
      )
      if (valore) {
        this.setState({ ...emptyStateIdratato(this.state) })
      }
      return valore
    }
  }

  creaFatturaElettronica = async () => {
    const datiDaTrasmettere = fatturaElettronicaObject(this.state)
    const tester = await controlloFattura(this.state, 'elettronica')
    if (tester.errorePresente) {
      throw new Error('Errore nella fattura elettronica')
    } else {
      const valore = await inviaFattureElettronica(
        this.context.user,
        datiDaTrasmettere
      )
      if (valore) {
        this.setState({ ...emptyStateIdratato(this.state) })
      }
      return valore
    }
  }

  creaFatturaPA = async () => {
    const datiDaTrasmettere = fatturaPAObject(this.state)
    const tester = await controlloFattura(this.state, 'PA')
    if (tester.errorePresente) {
      throw new Error('Errore nella fattura PA')
    } else {
      const valore = await inviaFatturaPA(this.context.user, datiDaTrasmettere)
      if (valore) {
        this.setState({ ...emptyStateIdratato(this.state) })
      }
      return valore
    }
  }

  creaRettificaPr = async () => {
    const datiDaTrasmettere = rettificaPrObject(this.state)
    const tester = await controlloFattura(this.state, 'elettronica')
    if (tester.errorePresente) {
      throw new Error('Errore nella rettifica della fattura privata')
    } else {
      const valore = await inviaFattureElettronica(
        this.context.user,
        datiDaTrasmettere
      )
      if (valore) {
        this.setState({ ...emptyStateIdratato(this.state) })
      }
      return valore
    }
  }

  creaNotaDiCreditoPR = async () => {
    const datiDaTrasmettere = notaDiCreditoPrObject(this.state)
    const valore = await inviaNotaDiCreditoPr(this.context.user, datiDaTrasmettere)
    if (valore) {
      this.setState({ ...emptyStateIdratato(this.state) })
    }
    return valore
  }

  creaNotaDiCreditoPA = async () => {
    const datiDaTrasmettere = notaDiCreditoPAObject(this.state)
    const valore = await inviaNotaDiCreditoPa(this.context.user, datiDaTrasmettere)
    if (valore) {
      this.setState({ ...emptyStateIdratato(this.state) })
    }
    return valore
  }

  creaNotaDiDebitoPR = async () => {
    const datiDaTrasmettere = notaDiDebitoPrObject(this.state)
    const valore = await inviaNotaDiDebitoPr(this.context.user, datiDaTrasmettere)
    if (valore) {
      this.setState({ ...emptyStateIdratato(this.state) })
    }
    return valore
  }

  creaAutofattura = async () => {
    const datiDaTrasmettere = autofatturaObject(this.state)
    const valore = await inviaAutofattura(this.context.user, datiDaTrasmettere)
    if (valore) {
      this.setState({ ...emptyStateIdratato(this.state) })
    }
    return valore
  }

  render() {
    const { children } = this.props

    const context = {
      dati: this.state,
      idrata: this.idrata,
      idrataNDVariazionePr: this.idrataNDVariazionePr,
      idrataNDVariazionePa: this.idrataNDVariazionePa,
      idrataFatturaDiRettifica: this.idrataFatturaDiRettifica,
      aggiornaClienti: this.aggiornaClienti,
      aggiornaClienteAttivo: this.aggiornaClienteAttivo,
      aggiornaClienteAttivoDaId: this.aggiornaClienteAttivoDaId,
      aggiornaMetodoDiPagamento: this.aggiornaMetodoDiPagamento,
      inizializzaMetodoDiPagamento: this.inizializzaMetodoDiPagamento,
      aggiornaMetodoDiPagamentoAttivo: this.aggiornaMetodoDiPagamentoAttivo,
      aggiornaMetodoDiPagamentoAttivoDaID: this.aggiornaMetodoDiPagamentoAttivoDaID,
      aggiungiPrestazioni: this.aggiungiPrestazioni,
      aggiungiPrestazioneAutofattura: this.aggiungiPrestazioneAutofattura,
      aggiornaTipologiaFattura: this.aggiornaTipologiaFattura,
      aggiornaNumeroFattura: this.aggiornaNumeroFattura,
      aggiornaNumeroAutofattura: this.aggiornaNumeroAutofattura,
      aggiornaDataFattura: this.aggiornaDataFattura,
      aggiornaNota: this.aggiornaNota,
      creaProforma: this.creaProforma,
      aggiornaAddebitoMarcaDaBollo: this.aggiornaAddebitoMarcaDaBollo,
      aggiornaIdentificativoMarcaDaBollo: this.aggiornaIdentificativoMarcaDaBollo,
      aggiornaDescrzioneNotaDiVariazione: this.aggiornaDescrzioneNotaDiVariazione,
      aggiornaImportoNotaDiVariazione: this.aggiornaImportoNotaDiVariazione,
      rimuoviPrestazione: this.rimuoviPrestazione,
      rimuoviPrestazioneAutofattura: this.rimuoviPrestazioneAutofattura,
      aggiornaDatiAggiuntivi: this.aggiornaDatiAggiuntivi,
      aggiornaLoading: this.aggiornaLoading,
      aggiornaTipologiaAutofattura: this.aggiornaTipologiaAutofattura,
      aggiornaIdDocumentoAutofattura: this.aggiornaIdDocumentoAutofattura,
      aggiornaDataDocumentoAutofattura: this.aggiornaDataDocumentoAutofattura,
      creaFatturaCartacea: this.creaFatturaCartacea,
      creaFatturaElettronica: this.creaFatturaElettronica,
      creaNotaDiCreditoPA: this.creaNotaDiCreditoPA,
      creaNotaDiCreditoPR: this.creaNotaDiCreditoPR,
      creaNotaDiDebitoPR: this.creaNotaDiDebitoPR,
      creaRettificaPr: this.creaRettificaPr,
      creaAutofattura: this.creaAutofattura,
      rimuoviMetodoDiPagamento: this.rimuoviMetodoDiPagamento,
      creaFatturaPA: this.creaFatturaPA,
      modificaMetodoDiPagamento: this.modificaMetodoDiPagamento,
    }

    return (
      <FiscomedContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </FiscomedContext.Provider>
    )
  }
}

export { FiscomedProvider as default, FiscomedContext }
