import React from 'react'
import { UserContext } from './User'
import moment from 'moment'
import {
  idrataFatture,
  modificaDaIncassare,
  queryIncassaFattura,
  scaricaPDF,
  eliminaFattura,
  scaricaXMLFattura,
  scaricaPDFEle,
  scaricaFatturaDiCortesia,
  scaricaAttestazione,
  queryInviaEmail,
  queryModificaStatoSTS,
} from '../utility/QueryRiepilogoFatture'

const RiepilogoContext = React.createContext()

const emptyState = {
  fatture: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConFattureEmesse: [],
  loading: true,
  modalIncassoOpen: false,
  modalImpossibilitaDiRecapitoOpen: false,
  modalConsegnataOpen: false,
  modalConsegnataPAOpen: false,
  modalScartoOpen: false,
  modalScartoVariazioneOpen: false,
  modalMancataConsegnaPAOpen: false,
  modalMancataConsegnaPROpen: false,
  modalMancataConsegnaAutofatturaOpen: false,
  modalDecorrenzaTerminiPAOpen: false,
  modalEsitoPositivoPAOpen: false,
  modalEsitoNegativoPAOpen: false,
  modalAttestazionePAOpen: false,
  modalScartoPAOpen: false,
  modalEmailOpen: false,
  modalVerificaSdiOpen: false,
  fatturaSelezionata: null,
  dataIncasso: moment().format('YYYY-MM-DD'),
}

class RiepilogoProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  idrata = () =>
    idrataFatture(this.context.user, this.state.annoDiRiferimento).then(
      (responseBody) => {
        this.setState({
          ...this.state,
          loading: false,
          fatture: responseBody.fatture,
          anniConFattureEmesse: responseBody.anniConFattureEmesse,
        })
      }
    )

  impostaAnnoEIdrata = (anno) =>
    this.setState({ loading: true }, () => {
      idrataFatture(this.context.user, anno).then((responseBody) => {
        this.setState({
          ...this.state,
          annoDiRiferimento: anno,
          fatture: responseBody.fatture,
          anniConFatturaEmessa: responseBody.anni_con_fattura_emessa,
          loading: false,
        })
      })
    })

  apriModalIncasso = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalIncassoOpen: true,
      fatturaSelezionata,
    })
  }

  apriModalEmail = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalEmailOpen: true,
      fatturaSelezionata,
    })
  }

  inviaEmailFattura = async (emailCliente) => {
    const esito = await queryInviaEmail(
      this.context.user,
      this.state.fatturaSelezionata.fattura.id,
      emailCliente
    )
    return esito
  }

  chiudiModalEmail = () => this.setState({ modalEmailOpen: false })

  aggiornaDataIncasso = (data) => this.setState({ dataIncasso: data })

  chiudiModalIncasso = () => this.setState({ modalIncassoOpen: false })

  incassaFattura = async () => {
    this.setState({ loading: true, modalIncassoOpen: false })
    queryIncassaFattura(
      this.context.user,
      this.state.fatturaSelezionata.fattura.id,
      this.state.dataIncasso
    ).then(() => this.idrata())
  }

  apriModalImpossibilitaDiRecapito = () =>
    this.setState({ modalImpossibilitaDiRecapitoOpen: true })

  chiudiModalImpossibilitaDiRecapito = () =>
    this.setState({ modalImpossibilitaDiRecapitoOpen: false })

  apriModalConsegnata = () => this.setState({ modalConsegnataOpen: true })

  chiudiModalConsegnata = () => this.setState({ modalConsegnataOpen: false })

  apriModalConsegnataPA = () => this.setState({ modalConsegnataPAOpen: true })

  chiudiModalConsegnataPA = () => this.setState({ modalConsegnataPAOpen: false })

  apriModalMancataConsegnaPA = () =>
    this.setState({ modalMancataConsegnaPAOpen: true })

  chiudiModalMancataConsegnaPA = () =>
    this.setState({ modalMancataConsegnaPAOpen: false })

  apriModalMancataConsegnaPR = () =>
    this.setState({ modalMancataConsegnaPROpen: true })

  chiudiModalMancataConsegnaPR = () =>
    this.setState({ modalMancataConsegnaPROpen: false })

  apriModalMancataConsegnaAutofattura = () => {
    this.setState({ modalMancataConsegnaAutofatturaOpen: true })
  }

  chiudiModalMancataConsegnaAutofattura = () =>
    this.setState({ modalMancataConsegnaAutofatturaOpen: false })

  apriModalDecorrenzaTerminiPA = () =>
    this.setState({ modalDecorrenzaTerminiPAOpen: true })

  chiudiModalDecorrenzaTerminiPA = () =>
    this.setState({ modalDecorrenzaTerminiPAOpen: false })

  apriModalEsitoPositivoPA = () => this.setState({ modalEsitoPositivoPAOpen: true })

  chiudiModalEsitoPositivoPA = () =>
    this.setState({ modalEsitoPositivoPAOpen: false })

  apriModalScarto = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalScartoOpen: true,
      fatturaSelezionata,
    })
  }

  chiudiModalScarto = () => this.setState({ modalScartoOpen: false })

  apriModalScartoPA = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalScartoPAOpen: true,
      fatturaSelezionata,
    })
  }

  chiudiModalScartoPA = () => this.setState({ modalScartoPAOpen: false })

  apriModalEsitoNegativoPA = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalEsitoNegativoPAOpen: true,
      fatturaSelezionata,
    })
  }

  chiudiModalEsitoNegativoPA = () =>
    this.setState({ modalEsitoNegativoPAOpen: false })

  apriModalAttestazionePA = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalAttestazionePAOpen: true,
      fatturaSelezionata,
    })
  }

  chiudiModalAttestazionePA = () => this.setState({ modalAttestazionePAOpen: false })

  apriModalScartoVariazione = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    this.setState({
      ...this.state,
      modalScartoVariazioneOpen: true,
      fatturaSelezionata,
    })
  }

  chiudiModalScartoVariazione = () =>
    this.setState({ modalScartoVariazioneOpen: false })

  apriModalVerificaSdi = () =>
    this.setState({
      ...this.state,
      modalVerificaSdiOpen: true,
    })

  chiudiModalVerificaSdi = () => this.setState({ modalVerificaSdiOpen: false })

  scaricaFatturaCartacea = (idFattura) => scaricaPDF(this.context.user, idFattura)

  modificaInDaIncassare = (idFattura) =>
    modificaDaIncassare(this.context.user, idFattura).then(() => this.idrata())

  eliminazioneFattura = (idFattura) =>
    eliminaFattura(this.context.user, idFattura).then(() => this.idrata())

  scaricaXML = (idFattura) => scaricaXMLFattura(this.context.user, idFattura)

  scaricaPDFElettronica = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    scaricaPDFEle(this.context.user, fatturaSelezionata.fattura)
  }

  scaricaFatturaCortesia = (idFattura) => {
    const fatturaSelezionata = this.state.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    scaricaFatturaDiCortesia(this.context.user, fatturaSelezionata.fattura)
  }

  downloadAttestazione = () => {
    scaricaAttestazione(this.context.user, this.state.fatturaSelezionata.fattura)
  }

  modificaStatoSTS = (idFattura) => {
    this.setState({
      ...this.state,
      fatture: this.state.fatture.map((fattura) => {
        if (fattura.fattura.id === idFattura) {
          fattura.fattura.inviata_ts = !fattura.fattura.inviata_ts
        }
        return fattura
      }),
    })
    queryModificaStatoSTS(this.context.user, idFattura)
  }

  render() {
    const { children } = this.props

    const context = {
      dati: this.state,
      idrata: this.idrata,
      impostaAnnoEIdrata: this.impostaAnnoEIdrata,
      chiudiModalIncasso: this.chiudiModalIncasso,
      apriModalIncasso: this.apriModalIncasso,
      aggiornaDataIncasso: this.aggiornaDataIncasso,
      incassaFattura: this.incassaFattura,
      apriModalImpossibilitaDiRecapito: this.apriModalImpossibilitaDiRecapito,
      chiudiModalImpossibilitaDiRecapito: this.chiudiModalImpossibilitaDiRecapito,
      apriModalConsegnata: this.apriModalConsegnata,
      chiudiModalConsegnata: this.chiudiModalConsegnata,
      apriModalScarto: this.apriModalScarto,
      chiudiModalScarto: this.chiudiModalScarto,
      apriModalScartoVariazione: this.apriModalScartoVariazione,
      chiudiModalScartoVariazione: this.chiudiModalScartoVariazione,
      scaricaFatturaCartacea: this.scaricaFatturaCartacea,
      modificaInDaIncassare: this.modificaInDaIncassare,
      eliminazioneFattura: this.eliminazioneFattura,
      scaricaXML: this.scaricaXML,
      scaricaPDFElettronica: this.scaricaPDFElettronica,
      scaricaFatturaCortesia: this.scaricaFatturaCortesia,
      apriModalConsegnataPA: this.apriModalConsegnataPA,
      chiudiModalConsegnataPA: this.chiudiModalConsegnataPA,
      apriModalMancataConsegnaPA: this.apriModalMancataConsegnaPA,
      chiudiModalMancataConsegnaPA: this.chiudiModalMancataConsegnaPA,
      apriModalMancataConsegnaPR: this.apriModalMancataConsegnaPR,
      chiudiModalMancataConsegnaPR: this.chiudiModalMancataConsegnaPR,
      apriModalMancataConsegnaAutofattura: this.apriModalMancataConsegnaAutofattura,
      chiudiModalMancataConsegnaAutofattura:
        this.chiudiModalMancataConsegnaAutofattura,
      apriModalDecorrenzaTerminiPA: this.apriModalDecorrenzaTerminiPA,
      chiudiModalDecorrenzaTerminiPA: this.chiudiModalDecorrenzaTerminiPA,
      apriModalEsitoPositivoPA: this.apriModalEsitoPositivoPA,
      chiudiModalEsitoPositivoPA: this.chiudiModalEsitoPositivoPA,
      apriModalEsitoNegativoPA: this.apriModalEsitoNegativoPA,
      chiudiModalEsitoNegativoPA: this.chiudiModalEsitoNegativoPA,
      apriModalAttestazionePA: this.apriModalAttestazionePA,
      chiudiModalAttestazionePA: this.chiudiModalAttestazionePA,
      apriModalVerificaSdi: this.apriModalVerificaSdi,
      chiudiModalVerificaSdi: this.chiudiModalVerificaSdi,
      apriModalScartoPA: this.apriModalScartoPA,
      chiudiModalScartoPA: this.chiudiModalScartoPA,
      downloadAttestazione: this.downloadAttestazione,
      apriModalEmail: this.apriModalEmail,
      chiudiModalEmail: this.chiudiModalEmail,
      inviaEmailFattura: this.inviaEmailFattura,
      modificaStatoSTS: this.modificaStatoSTS,
    }

    return (
      <RiepilogoContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </RiepilogoContext.Provider>
    )
  }
}

export { RiepilogoProvider as default, RiepilogoContext }
