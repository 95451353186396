import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { Button, Card } from 'reactstrap'
import Nota from '../Componenti/Nota'
import { Link, useHistory } from 'react-router-dom'
import DescrizioneImportoNotaDiVariazionePR from '../Componenti/DescrizioneImportoNotaDiVariazionePR'
import moment from 'moment'
import ModalCampiAggiuntivi from '../Componenti/ModalCampiAggiuntivi'

const PannelloNDCpr = () => {
  const fiscoContext = useContext(FiscomedContext)
  const history = useHistory()

  const salvaEStampa = () => {
    if (!fiscoContext.dati.loading) {
      fiscoContext.aggiornaLoading(true)
      fiscoContext
        .creaNotaDiCreditoPR()
        .then((idFattura) => {
          fiscoContext.aggiornaLoading(false)
          if (idFattura) {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/trasmessa/' +
                idFattura
            )
          } else {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/non-trasmessa/id'
            )
          }
        })
        .catch(() => {
          fiscoContext.aggiornaLoading(false)
        })
    }
  }

  return (
    <Card body className="mt-2 mb-5">
      <h4 className="font-medium">
        <i className="bi bi-person-video2"></i> Cliente
      </h4>
      <h5 className="font-regular">
        {fiscoContext.dati.clienteDiRiferimento?.ragione_sociale}
      </h5>
      <div className="mt-5" />
      <h4 className="d-inline align-bottom font-regular">
        <i className="bi bi-link"></i> Riferimento fattura
      </h4>
      <h5 className="font-regular interlinea-25">
        Numero: {fiscoContext.dati.fatturaDiRiferimento?.numero}
        <br />
        Data di emissione:{' '}
        {moment(fiscoContext.dati.fatturaDiRiferimento?.data_emissione).format(
          'DD/MM/YYYY'
        )}
      </h5>
      <DescrizioneImportoNotaDiVariazionePR />
      <h4 className="d-inline align-bottom font-medium mt-5">
        <i className="bi bi-pencil-square"></i> Nota{' '}
      </h4>
      <Nota />
      <h6 className="font-medium mt-4">
        Hai bisogno di aggiungere altri dati?
      </h6>{' '}
      <ModalCampiAggiuntivi />
      <div className="mt-4 mb-1">
        <Button className="bottone-blu float-right" onClick={() => salvaEStampa()}>
          INVIA FATTURA
        </Button>
        <Button
          className="bottone-rosso-outline mr-3 float-right"
          tag={Link}
          to="/fiscomed"
        >
          ANNULLA
        </Button>
      </div>
    </Card>
  )
}

export default PannelloNDCpr
