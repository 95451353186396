import React, { useContext } from 'react'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const RicevutaDiConsegna = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <>
      Consegnata{' '}
      <i
        className="bi bi-info-circle-fill"
        onClick={() => riepilogoContext.apriModalConsegnata()}
        style={{ cursor: 'pointer' }}
      ></i>
    </>
  )
}

export default RicevutaDiConsegna
