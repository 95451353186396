import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import { Link } from 'react-router-dom'
import { getLast } from '../../../app/utility/array'

const ModalEsitoNegativoPA = () => {
  const riepilogoContext = useContext(RiepilogoContext)
  const lastEsito = getLast(riepilogoContext.dati.fatturaSelezionata?.esito)
  return (
    <Modal
      isOpen={riepilogoContext.dati.modalEsitoNegativoPAOpen}
      toggle={() => riepilogoContext.chiudiModalEsitoNegativoPA()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalEsitoNegativoPA()}
        className="h4 font-regular font-grigio-scuro"
      >
        Esito negativo
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La pubblica amministrazione ha controllato e rifiutato la tua fattura 😰
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Bisogna correggere la tua fattura e inviarla nuovamente. Si tratta di una
          procedura che dobbiamo fare insieme, per questo motivo ti consigliamo di{' '}
          <Link to="/chat-commercialista">contattarci</Link>
          <br />
          <br /> Ecco gli errori che hanno fatto scartare la fattura:
        </h6>
        <br />
        <Alert color="danger">{lastEsito?.suggerimento}</Alert>
      </ModalBody>
    </Modal>
  )
}

export default ModalEsitoNegativoPA
