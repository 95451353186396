import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import PreviewCartacea from './PreviewCartacea'
import PreviewElettronica from './PreviewElettronica'
import PreviewPA from './PreviewPA'
import PreviewProforma from './PreviewProforma'
import PreviewNotaDiCreditoPa from './PreviewNotaDiCreditoPa'
import PreviewNotaDiCreditoPr from './PreviewNotaDiCreditoPr'
import PreviewNotaDiDebitoPr from './PreviewNotaDiDebitoPr'
import PreviewRettificaPr from './PreviewRettificaPr'
import PreviewAutofattura from './PreviewAutofattura'

const PreviewContainer = () => {
  const fiscoContext = useContext(FiscomedContext)

  return (
    <div>
      {fiscoContext.dati.tipologiaFattura === 'Fattura cartacea' && (
        <PreviewCartacea />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' && (
        <PreviewElettronica />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Proforma' && <PreviewProforma />}
      {fiscoContext.dati.tipologiaFattura === 'Nota di credito pa' && (
        <PreviewNotaDiCreditoPa />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Nota di credito pr' && (
        <PreviewNotaDiCreditoPr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Nota di debito pr' && (
        <PreviewNotaDiDebitoPr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Fattura PA' && <PreviewPA />}
      {fiscoContext.dati.tipologiaFattura === 'Rettifica pr' && (
        <PreviewRettificaPr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Autofattura' && (
        <PreviewAutofattura />
      )}
    </div>
  )
}

export default PreviewContainer
