import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { ImportaFatturaContext } from '../../app/context/ImportaFattura'

const SelettoreAnno = () => {
  const importFatturaContext = useContext(ImportaFatturaContext)

  const cambiaAnno = (anno) => importFatturaContext.impostaAnnoEIdrata(anno)

  return (
    <Row>
      <Col>
        {(Math.min(...importFatturaContext.dati.anniConFattureEmesse) <
          importFatturaContext.dati.annoDiRiferimento ||
          importFatturaContext.dati.annoDiRiferimento >
            importFatturaContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              cambiaAnno(importFatturaContext.dati.annoDiRiferimento - 1)
            }
          >
            <i className="bi bi-arrow-left-square float-right"></i>
          </h3>
        )}
      </Col>
      <Col>
        <center>
          <h3 className="font-regular">
            Anno di incasso {importFatturaContext.dati.annoDiRiferimento}
          </h3>
        </center>
      </Col>
      <Col>
        {(Math.max(...importFatturaContext.dati.anniConFattureEmesse) >
          importFatturaContext.dati.annoDiRiferimento ||
          importFatturaContext.dati.annoDiRiferimento <
            importFatturaContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              cambiaAnno(importFatturaContext.dati.annoDiRiferimento + 1)
            }
          >
            <i className="bi bi-arrow-right-square"></i>
          </h3>
        )}
      </Col>
    </Row>
  )
}

export default SelettoreAnno
