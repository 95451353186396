import React, { useContext, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Tooltip,
} from 'reactstrap'
import { ImportaFatturaContext } from '../../app/context/ImportaFattura'

const ModalImporta = () => {
  const importaFattureContext = useContext(ImportaFatturaContext)
  const [tooltipTrattenuta, setTooltipTrattenuta] = useState(false)
  const [tooltipImporto, setTooltipImporto] = useState(false)

  return (
    <Modal
      isOpen={importaFattureContext.dati.modalImportOpen}
      toggle={() => importaFattureContext.statoModalImport(false)}
    >
      <ModalHeader
        className="h4 font-medium"
        toggle={() => importaFattureContext.statoModalImport(false)}
      >
        Importa {importaFattureContext.dati.tipologia}
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-30">
          Prova a compilare i campi qui sotto. Se dovessi incontrare qualche
          difficoltà, scrivici su WhatsApp: faremo la procedura insieme 😊
        </h6>
        <br />
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Tipologia</Label>
              <Input
                value={
                  importaFattureContext.dati.tipologia.charAt(0).toUpperCase() +
                  importaFattureContext.dati.tipologia.slice(1)
                }
                onChange={(e) =>
                  importaFattureContext.impostaTipologia(
                    e.target.value.toLowerCase()
                  )
                }
                type="select"
              >
                <option>Fattura</option>
                <option>Cedolino</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Data di incasso</Label>
              <Input
                type="date"
                value={
                  importaFattureContext.dati.dataIncasso === null
                    ? ''
                    : importaFattureContext.dati.dataIncasso
                }
                onChange={(e) =>
                  importaFattureContext.impostaAnnoIncasso(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">
                Numero {importaFattureContext.dati.tipologia}
              </Label>
              <Input
                type="text"
                placeholder="1s"
                value={
                  importaFattureContext.dati.numeroFattura === null
                    ? ''
                    : importaFattureContext.dati.numeroFattura
                }
                onChange={(e) =>
                  importaFattureContext.impostaNumeroFattura(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">
                Importo <i className="bi bi-question-circle" id="toolImp"></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipImporto}
                  target="toolImp"
                  toggle={() => setTooltipImporto(!tooltipImporto)}
                >
                  Se stai caricando una fattura, in questo campo devi inserire
                  l&apos;importo della fattura più la marca da bollo. Esempio:
                  l&apos;importo della tua fattura è 1200 € e la marca da bollo che
                  hai applicato è di 2€. In questo campo scriverai 1202 €. Se,
                  invece, vuoi importare un cedolino, sempre in questo campo devi
                  riportare il numero che trovi sotto la dicitura &quot;totale
                  competenze&quot;.
                </Tooltip>
              </Label>
              <Input
                type="number"
                placeholder="120,23"
                value={
                  importaFattureContext.dati.importo === null
                    ? ''
                    : importaFattureContext.dati.importo
                }
                onChange={(e) =>
                  importaFattureContext.impostaImporto(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {importaFattureContext.dati.tipologia === 'cedolino' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">
                  Trattenute ENPAM{' '}
                  <i className="bi bi-question-circle" id="toolTratt"></i>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipTrattenuta}
                    target="toolTratt"
                    toggle={() => setTooltipTrattenuta(!tooltipTrattenuta)}
                  >
                    In questo campo, inserisci il numero che trovi sotto la voce
                    &quot;oneri previdenziali&quot;. È importante per il calcolo
                    delle tasse
                  </Tooltip>
                </Label>
                <Input
                  type="number"
                  placeholder="12,23"
                  value={
                    importaFattureContext.dati.trattenutaEnpam === null
                      ? ''
                      : importaFattureContext.dati.trattenutaEnpam
                  }
                  onChange={(e) =>
                    importaFattureContext.impostaTrattenutaEnpam(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        {importaFattureContext.dati.tipologia === 'fattura' && (
          <FormGroup>
            <Label for="exampleEmail">
              È stata trasmessa a Sistema Tessera Sanitaria
            </Label>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  checked={importaFattureContext?.dati?.trasmessaTS === true}
                  onChange={() => importaFattureContext.impostaTrasmessaTS(true)}
                  value="Si"
                />
                Sì
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  checked={importaFattureContext?.dati?.trasmessaTS === false}
                  onChange={() => importaFattureContext.impostaTrasmessaTS(false)}
                  value="No"
                />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="exampleEmail">
            Foto o pdf{' '}
            {importaFattureContext.dati.tipologia === 'fattura'
              ? 'della fattura'
              : 'cedolino'}{' '}
            originale
          </Label>
          <Input
            type="file"
            onChange={(e) =>
              importaFattureContext.impostaFileFattura(e.target.files[0])
            }
          />
        </FormGroup>
        <br />
        {importaFattureContext.dati.upLoading ? (
          <Button className=" float-right bottone-blu" disabled>
            Caricamento...
          </Button>
        ) : (
          <Button
            className="bottone-blu float-right"
            onClick={() => importaFattureContext.aggiungiFattura()}
          >
            <i className="bi bi-cloud-arrow-up"></i> IMPORTA{' '}
            {importaFattureContext.dati.tipologia.toUpperCase()}
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalImporta
