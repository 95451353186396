import React, { useContext, useEffect, useState } from 'react'
import { FiscomedContext } from '../../app/context/Fiscomed'
import {
  Container,
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap'
import ModalAggiungiPagamento from '../Fattura/Componenti/ModalAggiungiPagamento'
import { Helmet } from 'react-helmet'
import swal from 'sweetalert'

const MetodoDiPagamento = () => {
  const fiscoContext = useContext(FiscomedContext)
  const [pagamentoDaModificare, setPagamentoDaModificare] = useState(null)
  const [modalModificaAperto, setModalModificaAperto] = useState(false)

  useEffect(() => {
    fiscoContext.inizializzaMetodoDiPagamento()
  }, [])

  const modificaPagamento = () => {
    fiscoContext.aggiornaLoading(true)
    fiscoContext.modificaMetodoDiPagamento(pagamentoDaModificare).then(() => {
      fiscoContext.aggiornaLoading(false)
      chiudiModalModifica()
    })
  }

  const rimuoviMetodoDiPagamento = (metodoDiPagamento, iban) => {
    swal({
      title: 'Rimuovere definitivamente ' + iban + '?',
      icon: 'warning',
      buttons: ['ANNULLA', 'SI, RIMUOVI'],
    }).then((isConfirm) => {
      if (isConfirm) {
        if (!fiscoContext.dati.loading) {
          fiscoContext.aggiornaLoading(true)
          fiscoContext
            .rimuoviMetodoDiPagamento(metodoDiPagamento)
            .then((pagamentiResponses) => {
              fiscoContext.aggiornaLoading(false)
              fiscoContext.aggiornaMetodoDiPagamento(pagamentiResponses)
            })
        }
      }
    })
  }

  const apriModalModifica = (pagamento) => {
    setPagamentoDaModificare(pagamento)
    setModalModificaAperto(true)
  }

  const chiudiModalModifica = () => {
    setPagamentoDaModificare(null)
    setModalModificaAperto(false)
  }

  const modalModifica = (
    <Modal isOpen={modalModificaAperto} toggle={() => chiudiModalModifica()}>
      <ModalHeader toggle={() => chiudiModalModifica()}>
        Modifica metodo di pagamento
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Intestatario carta</Label>
          <Input
            type="text"
            value={pagamentoDaModificare?.intestatario || ''}
            onChange={(e) =>
              setPagamentoDaModificare({
                ...pagamentoDaModificare,
                intestatario: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>IBAN</Label>
          <Input
            type="text"
            value={pagamentoDaModificare?.iban || ''}
            onChange={(e) =>
              setPagamentoDaModificare({
                ...pagamentoDaModificare,
                iban: e.target.value.toUpperCase(),
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Etichetta</Label>
          <Input
            type="text"
            value={pagamentoDaModificare?.alias || ''}
            onChange={(e) =>
              setPagamentoDaModificare({
                ...pagamentoDaModificare,
                alias: e.target.value,
              })
            }
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline mr-3"
          onClick={() => chiudiModalModifica()}
        >
          ANNULLA
        </Button>
        {fiscoContext.dati.loading ? (
          <Button className="bottone-blu" disabled>
            <Spinner size="sm" color="light" />
          </Button>
        ) : (
          <Button className="bottone-blu" onClick={() => modificaPagamento()}>
            SALVA
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )

  const componentPagamenti = fiscoContext.dati.metodoDiPagamento.map(
    (pagamento, index) =>
      pagamento === 'Contante' ? null : (
        <Card key={index} className="mt-4">
          <CardHeader
            className="d-flex justify-content-between"
            style={{
              backgroundColor: '#f2b705',
              borderRadius: '10px 10px 0px 0px',
              border: 'none',
            }}
          >
            <h4 className="font-regular text-white mt-2">
              {pagamento.ragione_sociale}
            </h4>
            <div>
              <Button
                className="bottone-bianco-blu mr-2"
                onClick={() => apriModalModifica(pagamento)}
              >
                <i
                  className="bi bi-pencil text-warning"
                  style={{ fontSize: '1.2rem' }}
                ></i>
              </Button>
              <Button
                className="bottone-bianco-blu"
                onClick={() =>
                  rimuoviMetodoDiPagamento(pagamento.id, pagamento.iban)
                }
              >
                <i
                  className="bi bi-trash3 text-danger"
                  style={{ fontSize: '1.2rem' }}
                ></i>
              </Button>
            </div>
          </CardHeader>
          <CardBody
            style={{
              backgroundColor: '#f2b70540',
              borderRadius: '0px 0px 10px 10px',
              color: '#373d3f',
            }}
          >
            {pagamento.alias !== '' && (
              <h5 className="font-regular">
                Etichetta: <span className="text-muted">{pagamento.alias}</span>
              </h5>
            )}
            <h5 className="font-regular">
              Intestatario:{' '}
              <span className="text-muted">{pagamento.intestatario}</span>
            </h5>

            <h5 className="font-regular">
              IBAN: <span className="text-muted">{pagamento.iban}</span>
            </h5>
          </CardBody>
        </Card>
      )
  )

  return (
    <Container className="mb-5 mt-4">
      <Helmet>
        <title>Meotdi di pagamento</title>
        <meta property="title" content="Metodi di pagamento" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {modalModifica}
      <Card body>
        <Card
          body
          className="mt-2"
          style={{
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#0583f2',
          }}
        >
          {' '}
          <Row>
            <Col xs={9}>
              <h2 className="font-regular text-white mt-2">
                <i className="bi bi-wallet2"></i> Metodi di pagamento
              </h2>
              <h6 className="mt-2 interlinea-30 font-regular text-white">
                Qui puoi vedere tutti i metodi di pagamento che hai aggiunto per
                emettere le tue fatture in modo semplice e veloce. Se lo desideri,
                puoi cancellare i metodi di pagamento che non usi più o aggiungerne
                di nuovi
              </h6>
            </Col>
            <Col xs={3}>
              <div color="link" className="float-right">
                <ModalAggiungiPagamento
                  tipologia="bottone"
                  refreshClienti={() => console.log('refresh')}
                />
              </div>
            </Col>
          </Row>
        </Card>
        {fiscoContext.dati.metodoDiPagamento.length === 0 ? (
          <Alert color="info" className="mt-4">
            <i className="bi bi-info-circle"></i> Non hai ancora aggiunto nessun
            metodo di pagamento
          </Alert>
        ) : (
          componentPagamenti
        )}
      </Card>
    </Container>
  )
}

export default MetodoDiPagamento
