import React, { useContext } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'
import NonSaiRispondere from '../NonSaiRispondere'

const UnoSette = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={80} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">TI SPIEGHIAMO COME FARE</h4>

      <h6 className="font-regular mb-3 interlinea-30">
        Come puoi vedere, c&apos;è un riquadro intitolato &quot;
        <b>
          Domanda di ammissione al versamento della contribuzione nella misura
          ridotta
        </b>
        &quot;. Quello che devi fare tu è inserire la <b>data</b> in cui hai iniziato
        a svolgere l&apos;attività per la quale puoi richiedere l&apos;aliquota
        agevolata. Una volta scritta la data, ti compariranno tre alternative:
        seleziona quella che ti riguarda.
      </h6>
      <img
        src="/img/enpam/screen_enpam.webp"
        alt="screen enpam"
        style={{ width: '100%', maxWidth: '900px' }}
      />
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Una volta inviata questa richiesta, puoi compilare e inviare il{' '}
        <b>Modello D</b>.
        <br />
        Nella prossima schermata ti spieghiamo come fare
      </h6>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => context.setScheda('modello_d')}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default UnoSette
