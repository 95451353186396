import React, { useContext } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const UnoDue = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={30} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        ALLORA HAI BISOGNO DI UNO SPIEGONE
      </h4>
      <h5 className="testo-verde font-bold">Seconda parte</h5>

      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Vediamo ora le possibili scelte, per le diverse situazioni:
        <br />
        <br />
        <ul type="round">
          <li>
            <b>Liberi professionisti</b>: versano l&apos;aliquota intera del{' '}
            <b>19,50%</b>
          </li>
          <li>
            <b>Iscritti al fondo della medicina accreditata e convenzionata ENPAM</b>
            : possono scegliere di versare l&apos;aliquota intera (<b>19,50%</b>) o
            la metà dell&apos;aliquota intera (<b>9,75%</b>
            ). In questa categoria rientrano: gli MMG, i medici che svolgono
            continuità assistenziale, i medici dell&apos;assistenza primaria, gli
            specialisti ambulatoriali e esterni.
          </li>
          <li>
            <b>Tirocinanti del corso di formazione in Medicina Generale</b>: possono
            scegliere di versare l&apos;<b>aliquota intera</b> o l&apos;aliquota
            ridotta del <b>2%</b>
          </li>
          <li>
            Ospedalieri, specialisti ambulatoriali, specializzandi e dottorandi che
            hanno svolto attività <b>intramoenia</b>: possono scegliere di versare
            l&apos;<b>aliquota intera</b> o l&apos;aliquota ridotta del <b>2%</b>
          </li>
          <li>
            <b>Iscritti INPS</b> che non hanno svolto attività intramoenia e{' '}
            <b>specializzandi</b>: possono scegliere di versare l&apos;
            <b>aliquota intera</b> o la metà dell&apos;aliquota intera (<b>9,75%</b>
            ).
          </li>
        </ul>
      </h6>
      <br />

      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => context.setScheda('.13')}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
      </div>
    </Card>
  )
}

export default UnoDue
