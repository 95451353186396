import React, { useContext } from 'react'
import { FormGroup, Input, FormFeedback } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'

const Nota = () => {
  const fiscoContext = useContext(FiscomedContext)

  const setNota = (nota) => fiscoContext.aggiornaNota(nota)

  return (
    <FormGroup>
      <Input
        type="textarea"
        name="text"
        id="exampleText"
        value={fiscoContext.dati.nota}
        onChange={(e) => {
          if (e.target.value.length < 199) {
            setNota(e.target.value)
          }
        }}
        valid={/^[\w .,!?()]+$/.test(fiscoContext.dati.nota)}
        invalid={!/^$|^[\w .,!?()]+$/.test(fiscoContext.dati.nota)}
      />
      <FormFeedback valid>Sembra corretto</FormFeedback>
      <FormFeedback>
        Sembra che tu abbia usato dei caratteri non consentiti
      </FormFeedback>
    </FormGroup>
  )
}

export default Nota
