import React, { useContext } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'

const RiferimentoAmministrazione = () => {
  const fiscoContext = useContext(FiscomedContext)
  const setRifAmministrazione = (rifAmministrazione) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      rifAmministrazione,
    })
  }

  return (
    <FormGroup>
      <Label>Riferimento Amministrazione 1.2.6</Label>
      <br />
      <span className="text-muted">Visibile solo nel formato ministeriale</span>
      <Input
        type="text"
        value={fiscoContext.dati.datiAggiuntivi.rifAmministrazione}
        onChange={(e) => setRifAmministrazione(e.target.value)}
      />
    </FormGroup>
  )
}

export default RiferimentoAmministrazione
