import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import {
  Row,
  Col,
  Button,
  Container,
  Input,
  Spinner,
  FormGroup,
  Label,
} from 'reactstrap'
import { leadNewsletter } from '../../../app/utility/QueryLanding'
import swal from 'sweetalert'
import { newsLetterGA } from '../../../app/utility/GA'

const NewsLetterDesktop = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const refIniziaOra = useRef(null)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const iscrizioneNewsletter = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    newsLetterGA()
    await leadNewsletter({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Ottimo 😊',
        text: "L'iscrizione alla newsletter di Fiscomed è andata a buon fine",
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <Helmet>
        <title>Newsletter</title>
        <meta property="title" content="Newsletter" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/newsletter" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Newsletter" />
        <meta
          property="og:description"
          content="Iscriviti gratuitamente alla newsletter fiscale per medici"
        />
        <meta
          property="description"
          content="Iscriviti gratuitamente alla newsletter fiscale per medici"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <div className="immagine-background-landing-fiscomed"></div>
        <Row>
          <Col md={7}>
            <h1 className="titolo-landing-fiscomed font-medium mt-5">
              Tutto quello che devi sapere sulla fiscalità
            </h1>
            <h5 className="testo-landing-fiscomed-margini">
              Ti ricordiamo le scadenze che devi rispettare e ti spieghiamo come fare
              gli adempimenti della{' '}
              <span className="testo-blu">professione medica.</span>
              <br />
              Tutto nel linguaggio più semplice possibile
            </h5>
            <Button
              className="bottone-verde px-4"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              ISCRIVITI - è gratis
            </Button>
          </Col>
          <Col md={5}>
            <img
              src="/img_landing/immagine_newsletter.webp"
              alt="Immagine di una donna con il megafono"
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <br />
        <center>
          <img
            src="/img_landing/freccia.webp"
            alt="fiscomed"
            className="my-5"
            style={{ zIndex: 1000 }}
          />
          <br />
          <br />
          <br />

          <span className="etichetta">Perché una newsletter?</span>

          <br />
          <br />
          <br />
          <div className="titolo-2">
            Avere un&apos;educazione fiscale
            <br />
            <span className="testo-verde">
              è il primo passo per non compiere errori
            </span>
          </div>
          <br />
          <br />

          <h5
            className="testo-landing-fiscomed"
            style={{ paddingRight: '80px', paddingLeft: '80px' }}
          >
            La fiscalità è un argomento che spaventa molto. Quando si parla di
            Partita IVA, tasse e scadenze la faccia che tutti facciamo è più o meno
            questa: 😨
            <br />
            <br />
            Questo accade perché si viene catapultati nel mondo del lavoro senza
            avere la giusta dose di informazioni chiare, specifiche e di qualità.
          </h5>
          <br />
        </center>
        <br />
        <br />
        <div className="div-gradient-newsletter">
          <Row>
            <Col md={6}>
              <center>
                <img
                  src="/img_landing/newspaper.webp"
                  alt="fiscomed"
                  style={{
                    position: 'relative',
                    width: '100%',

                    maxWidth: '450px',
                  }}
                />
              </center>
            </Col>
            <Col md={6}>
              <div className="titolo-3">
                Così abbiamo creato la newsletter di Fiscomed. L&apos;obiettivo?
              </div>
              <br />
              <h5 className="testo-landing-fiscomed">
                Trasmettere le nostre <b>conoscenze</b> a tutti i giovani medici che
                desiderano capire come funziona il mondo fiscale e cosa bisogna fare
                per essere in regola
              </h5>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <br />
        <center>
          <span className="etichetta">Siamo già in tanti 😊</span>

          <br />
          <br />
          <br />
          <div className="titolo-2">
            3150 medici leggono e trovano utili
            <br />
            <span className="testo-verde">le nostre email</span>
            <img
              src="/img_landing/cuore_sx.webp"
              alt="cuore"
              className="cuore-newsletter"
            />
          </div>
          <br />
          <br />
          <br />

          <span className="etichetta">Cosa puoi imparare</span>
          <br />

          <br />
          <br />
          <br />
          <br />
          <img
            src="/img_landing/telefono_con_div.webp"
            alt="Telefono con messaggi"
            style={{ width: '100%', maxWidth: '800px' }}
          />
          <br />
          <br />
          <br />
          <br />
          <br ref={refIniziaOra} />

          <span className="etichetta">Iscriviti alla newsletter</span>
        </center>
        <br />
        <br />
        <br />
        <br />
        <div className="div-verde-guida" style={{ padding: '70px' }}>
          <center>
            <span className="titolo-3">
              Impara a conoscere la fiscalità della <br />
              professione medica
            </span>
            <br />
            <h5 className="testo-landing-fiscomed mt-4">
              Con la nostra newsletter ci impegniamo a darti tutte le informazioni
              necessarie scitte nel modo più semplice possibile. Fiscalese e
              burocratese non fanno per noi.
            </h5>
            <br />
            <h6 className="font-medium mb-3">Come ti chiami?</h6>
            <Input
              type="text"
              placeholder="Ippocrate di Coo"
              style={{ borderColor: '#66d98e', maxWidth: '300px' }}
              autoComplete="name"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <br />
            <h6 className="font-medium mb-3">Qual è l&apos;email che usi di più?</h6>
            <Input
              type="text"
              placeholder="ippocrate@email.com"
              style={{ borderColor: '#66d98e', maxWidth: '300px' }}
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div style={{ height: '36px' }} />
            <FormGroup check style={{ width: '300px' }}>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '2px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            <div style={{ height: '12px' }} />
            <FormGroup check style={{ width: '300px' }}>
              <Input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                style={{ marginTop: '2px' }}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
              <Label for="newsletter" className="consenso">
                Iscrivimi alla newsletter per ricevere tanti contenuti educativi e
                qualche informazione sulle nostre iniziative promozionali e
                commerciali{' '}
                <i>(ma solo quando sappiamo che può essere utile per te)</i>
              </Label>
            </FormGroup>
            {caricamento ? (
              <Button
                className="bottone-verde px-4 mt-4"
                disabled
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
              >
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-verde px-4 mt-4"
                onClick={() => iscrizioneNewsletter()}
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
              >
                ISCRIVITI ALLA NEWSLETTER
              </Button>
            )}
          </center>
        </div>
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md={6}>
            <div
              className="float-right"
              style={{ width: '60%', maxWidth: '400px', marginRight: '120px' }}
            >
              <img
                src="/img_landing/file-lock_verde.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Teniamo alla tua <b>privacy</b>, quindi non
                <br />
                daremo a nessuno il tuo indirizzo email
              </h5>
            </div>
            <br />
            <br />
          </Col>
          <Col md={6}>
            <div
              className="mb-5"
              style={{ width: '60%', maxWidth: '400px', marginLeft: '120px' }}
            >
              <img
                src="/img_landing/stoplights_verde.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Naturalmente, puoi
                <br /> <b>disiscriverti</b> quando vuoi
              </h5>
            </div>
            <br />
            <br />
          </Col>
          <Col md={6}>
            <div
              className="float-right mt-5"
              style={{ width: '60%', maxWidth: '400px', marginRight: '120px' }}
            >
              <img
                src="/img_landing/chat-left-dot_sverde.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Riceverai <b>al massimo</b> 3 email al mese per ricordarti le{' '}
                <b>scadenze</b> fiscali di quel periodo e spiegarti
                <br /> tutto ciò che devi sapere riguardo gli <b>adempimenti</b> più
                importanti
              </h5>
            </div>
          </Col>
          <Col md={6}>
            <div
              className="mt-5"
              style={{ width: '60%', maxWidth: '400px', marginLeft: '120px' }}
            >
              <img
                src="/img_landing/shield-x.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Non ti bombarderemo mai di inutili email promozionali.
              </h5>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  )
}

export default NewsLetterDesktop
