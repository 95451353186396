import React, { useState, useContext } from 'react'
import { Input, Container, Row, Col, Button, Spinner, Alert, Form } from 'reactstrap'
import { UserContext } from '../../app/context/User'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user, login, isLoading } = useContext(UserContext)
  const history = useHistory()
  const [typePassword, setTypePassword] = useState('password')

  const userLogin = (e) => {
    e.preventDefault()
    login(email, password).then((err) => {
      if (err === false) {
        history.push('/fiscomed')
      }
    })
  }

  const hideViewPassord = () => {
    if (typePassword === 'password') {
      setTypePassword('text')
    } else {
      setTypePassword('password')
    }
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta property="title" content="Login" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/accesso" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Login" />
        <meta property="og:description" content="Accedi a fiscomed" />
        <meta property="description" content="Accedi a fiscomed" />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
      </Helmet>
      <div
        style={{
          position: 'absolute',
          minWidth: '100%',
          minHeight: '100%',
          zIndex: '100000',
          background: 'white',
        }}
      >
        <div
          style={{
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100%',
            zIndex: '1000',
            background:
              'linear-gradient(to right,white 0%,white 50%,#ffedb7 50%,#ffedb7 100%)',
          }}
        >
          <Container style={{ marginTop: '56px' }} fluid>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={3}></Col>
                  <Col md={9}>
                    <div style={{ marginTop: '8vh' }} />
                    <img
                      src="/img_landing/logo.webp"
                      alt="Logo"
                      style={{ width: '130px' }}
                    />
                    <br />
                    <br />
                    <span className="titolo-3 mb-5">È bello rivederti Doc</span>
                    <br />
                    <br />
                    <h6 className="font-medium mb-3">Indirizzo email</h6>
                    <Form onSubmit={userLogin}>
                      <Input
                        type="email"
                        placeholder="ippocrate@email.it"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        style={{ maxWidth: '300px' }}
                      />
                      <br />
                      <h6
                        className="font-medium mb-3"
                        onClick={() => hideViewPassord()}
                      >
                        Password{' '}
                        {typePassword === 'password' ? (
                          <i className="bi bi-eye-fill"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </h6>
                      <Input
                        type={typePassword}
                        placeholder="èunsegretodiippocrate"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        style={{ maxWidth: '300px' }}
                      />

                      <div className="d-flex mt-5">
                        <Button
                          className="bottone-blu"
                          style={{
                            paddingTop: '14px',
                            paddingBottom: '14px',
                            width: '150px',
                          }}
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <Spinner size="sm" /> : 'ACCEDI'}
                        </Button>
                        <Link to="/cambio-password" className="text-muted mt-3 ml-3">
                          <u>Hai dimenticato la tua password?</u>
                        </Link>
                      </div>
                    </Form>
                    <br />
                    <br />

                    {user.error && (
                      <Alert color="danger" style={{ maxWidth: '300px' }}>
                        {user.message_error}
                      </Alert>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div style={{ marginTop: '25vh' }} />
                <center>
                  <img
                    src="/img_landing/mano.webp"
                    alt="Mano che saluta"
                    style={{ width: '100px', marginTop: '15vh' }}
                  />
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Login
