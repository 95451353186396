import request from 'superagent'
import { saveAs } from 'file-saver'

export const idrataBorse = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/borsa/list`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const caricaBorsa = (formData) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/borsa/upload`)
    .send(formData)
    .then((response) => response.body)
    .catch((e) => {
      console.error(e.message)
    })

export const cancellaBorsa = (user, uuidFile, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/borsa/delete`)
    .send({ token: user.auth_token, uuidFile, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const inviaModificaBorsa = (
  user,
  uuidFile,
  dataIncasso,
  importo,
  anno,
  tipologia
) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/borsa/update`)
    .send({
      token: user.auth_token,
      uuidFile,
      dataIncasso,
      importo,
      anno,
      tipologia,
    })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const scaricaBorsa = (user, uuidFile, nomeOriginale) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/borsa/download`)
    .send({ token: user.auth_token, uuidFile })
    .then((response) => {
      fetch(response.body.url)
        .then((r) => r.blob())
        .then((blobFile) => {
          const file = new File([blobFile], nomeOriginale)
          saveAs(file)
        })
    })
    .catch((e) => console.error(e.message))
