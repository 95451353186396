import React, { useContext } from 'react'
import { Row, Col, Card, Progress, Button } from 'reactstrap'
import { EnpamContext } from '../../../app/context/Enpam'

const Fine = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={100} className="progress-gialla" />
      <Row>
        <Col xs={5}>
          <center>
            <img
              src="/img/enpam/non_devi_inviare.webp"
              className="mt-5"
              style={{ width: '90%' }}
            />
          </center>
        </Col>
        <Col xs={7}>
          <h1 className="font-bold mt-5">Evvai, anche questa è fatta 🎉</h1>
          <h6 className="font-regular mb-3 mt-4 interlinea-30">
            Dopo che hai inviato il Modello D, se la procedura è andata a buon fine,
            ti dovrebbe essere comparsa una pagina con il{' '}
            <b>prospetto dei contributi</b> che dovrai versare per la Quota B. Non ti
            sorprendere se, a conti fatti, esce fuori che non devi versare nessun
            importo.
            <br />
            <br />
            Nel caso in cui tu la debba pagare, ricorda che puoi farlo:
            <ul type="round">
              <li>
                in un&apos;<b>unica soluzione</b> (entro il 31 ottobre)
              </li>
              <li>
                in <b>2 rate</b> (31 ottobre e 31 dicembre)
              </li>
              <li>
                in <b>5 rate</b> (31 ottobre, 31 dicembre, 28 febbraio, 30 aprile e
                30 giugno).
              </li>
              <li>
                in <b>9 rate</b> (31 ottobre, 30 novembre, 31 dicembre, 31 gennaio,
                28 febbraio, 31 marzo, 30 aprile, 31 maggio e 30 giugno).
              </li>
            </ul>
            Le rate che scadono entro l&apos;anno sono senza interessi, mentre quelle
            che scadono l&apos;anno successivo sono <b>maggiorate del 2,5%</b> annuo.
            <br />
            <br />
            Attenzione: tutti possono pagare la Quota B in un&apos;unica soluzione,
            ma solo chi ha attivato la <b>domiciliazione bancaria</b> o la carta di
            credito dell&apos;ENPAM convenzionata con la Banca popolare di Sondrio
            può usufruire del pagamento a rate.
            <br />
            <br />
            Ora, però, non ci pensare. Quando sarà il momento, ti manderemo una email
            con tutte le istruzioni del caso.
          </h6>
        </Col>
      </Row>
      <br />
      <br />
      <Button
        className="bottone-blu"
        style={{ width: '150px' }}
        onClick={() => context.setScheda('modello_d')}
      >
        <i className="bi bi-arrow-left-short"></i> INDIETRO
      </Button>
    </Card>
  )
}

export default Fine
