import classNames from 'classnames'
import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Tooltip,
} from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { latin1Invalid } from '../../../app/utility/ValidatoreRegex'

const Prestazioni = (props) => {
  const [modal, setModal] = useState(false)
  const [descrizione, setDescrizione] = useState('')
  const [importo, setImporto] = useState('')
  const fiscoContext = useContext(FiscomedContext)
  const [altriCampiVisibili, setAltriCampiVisibili] = useState(false)
  const [codiceValore, setCodiceValore] = useState('')
  const [rifAmministrazione, setRifAmministrazione] = useState('')
  const [quantita, setQuantita] = useState(1)
  const [altriDatiGestionali, setAltriDatiGestionali] = useState({
    tipoDato: '',
    riferimentoTesto: '',
  })
  const [natura, setNatura] = useState('N2.2')
  const [tooltipNatura, setTooltipNatura] = useState(false)

  const aggiungi = () => {
    if (descrizione !== '') {
      fiscoContext.aggiungiPrestazioni({
        descrizione,
        importo: Math.round(Number(importo.replace(',', '.')) * 1e2) / 1e2,
        quantita,
        codiceValore,
        rifAmministrazione,
        altriDatiGestionali,
        natura,
      })
      setDescrizione('')
      setQuantita(1)
      setImporto('')
      setCodiceValore('')
      setModal(false)
      setRifAmministrazione('')
      setAltriDatiGestionali({ tipoDato: '', riferimentoTesto: '' })
      setNatura('N2.2')
    }
  }

  const rimuoviPrestazione = (posizioneArray) =>
    fiscoContext.rimuoviPrestazione(posizioneArray)

  const modalPrestazione = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-regular" toggle={() => setModal(false)}>
        Aggiungi prestazione
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          In questa sezione puoi descrivere la prestazione che hai effettuato per il
          cliente e il relativo importo
        </h6>
        <br />
        <Form>
          {!['Fattura cartacea', 'Proforma'].includes(
            fiscoContext.dati.tipologiaFattura
          ) && (
            <small
              className="testo-blu float-right"
              onClick={() => setAltriCampiVisibili(!altriCampiVisibili)}
              style={{ cursor: 'pointer' }}
            >
              {altriCampiVisibili ? (
                <i className="bi bi-dash"></i>
              ) : (
                <i className="bi bi-plus"></i>
              )}{' '}
              Altri campi
            </small>
          )}
          {altriCampiVisibili && (
            <>
              <br />
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label>Quantità</Label>
                    <br />
                    <Input
                      type="number"
                      placeholder="1"
                      min={'1'}
                      value={quantita}
                      onChange={(e) => setQuantita(Number(e.target.value))}
                    />
                  </FormGroup>
                </Col>
                <Col xs={9}>
                  <FormGroup>
                    <Label>
                      Codice articolo &gt;{' '}
                      <small className="text-muted">Codice valore</small>
                    </Label>
                    <Input
                      type="text"
                      placeholder="S00074"
                      bsSize="sm"
                      value={codiceValore}
                      onChange={(e) => setCodiceValore(e.target.value.trim())}
                      invalid={codiceValore.length > 35 && codiceValore.length !== 0}
                      valid={codiceValore.length > 0 && codiceValore.length <= 35}
                    />
                    <FormFeedback valid>Sembra corretto</FormFeedback>
                    <FormFeedback>
                      Sembra che tu abbia usato dei caratteri non consentiti
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col
                  xs={
                    ['Fattura elettronica', 'Rettifica pr'].includes(
                      fiscoContext.dati.tipologiaFattura
                    )
                      ? 9
                      : 12
                  }
                >
                  <FormGroup>
                    <Label>Riferimento Amministrazione</Label>
                    <Input
                      type="text"
                      placeholder="0007"
                      value={rifAmministrazione}
                      onChange={(e) => setRifAmministrazione(e.target.value.trim())}
                      invalid={
                        rifAmministrazione.length > 20 &&
                        rifAmministrazione.length !== 0
                      }
                      valid={
                        rifAmministrazione.length > 0 &&
                        rifAmministrazione.length <= 20
                      }
                    />
                    <FormFeedback valid>Sembra corretto</FormFeedback>
                    <FormFeedback>
                      Sembra che tu abbia usato dei caratteri non consentiti
                    </FormFeedback>
                  </FormGroup>
                </Col>
                {['Fattura elettronica', 'Rettifica pr'].includes(
                  fiscoContext.dati.tipologiaFattura
                ) && (
                  <Col xs={3}>
                    <FormGroup>
                      <Label>
                        Natura{' '}
                        <i className="bi bi-question-circle" id="tooltipNatura"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipNatura}
                          target="tooltipNatura"
                          toggle={() => setTooltipNatura(!tooltipNatura)}
                        >
                          Il cliente ti ha chiesto di inserire una natura diversa da
                          N2.2? Scrivici su WhatsApp prima di farlo. È importante
                          valutare la tua situazione
                        </Tooltip>
                      </Label>
                      <Input
                        type="select"
                        value={natura}
                        onChange={(e) => setNatura(e.target.value)}
                      >
                        <option value="N2.2">N2.2</option>
                        <option value="N1">N1</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col xs={12}>Altri dati gestionali &gt;</Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>
                      <small className="text-muted">Tipo dato</small>
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={altriDatiGestionali.tipoDato}
                      onChange={(e) => {
                        if (e.target.value.length < 10) {
                          setAltriDatiGestionali({
                            ...altriDatiGestionali,
                            tipoDato: e.target.value.trim(),
                          })
                        }
                      }}
                      invalid={
                        altriDatiGestionali.tipoDato.length > 10 &&
                        altriDatiGestionali.tipoDato.length !== 0
                      }
                      valid={
                        altriDatiGestionali.tipoDato.length > 0 &&
                        altriDatiGestionali.tipoDato.length <= 10
                      }
                    />
                    <FormFeedback valid>Sembra corretto</FormFeedback>
                    <FormFeedback>
                      Sembra che tu abbia superato il limite di caratteri
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>
                      <small className="text-muted">Riferimento testo</small>
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={altriDatiGestionali.riferimentoTesto}
                      onChange={(e) => {
                        if (e.target.value.length < 60) {
                          setAltriDatiGestionali({
                            ...altriDatiGestionali,
                            riferimentoTesto: e.target.value.trim(),
                          })
                        }
                      }}
                      invalid={
                        altriDatiGestionali.riferimentoTesto.length > 60 &&
                        altriDatiGestionali.riferimentoTesto.length !== 0
                      }
                      valid={
                        altriDatiGestionali.riferimentoTesto.length > 0 &&
                        altriDatiGestionali.riferimentoTesto.length <= 60
                      }
                    />
                    <FormFeedback valid>Sembra corretto</FormFeedback>
                    <FormFeedback>
                      Sembra che tu abbia superato il limite di caratteri
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <FormGroup>
            <Label>Descrizione</Label>
            <Input
              type="text"
              placeholder="Prestazione medico di guardia mese di novembre"
              value={descrizione}
              onChange={(e) => {
                if (e.target.value.length < 995) {
                  setDescrizione(e.target.value)
                }
              }}
              invalid={latin1Invalid(descrizione)}
            />
            <FormFeedback>
              Sembra che tu abbia usato dei caratteri non consentiti
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Importo in euro</Label>
            <Input
              type="text"
              value={importo}
              placeholder="3.45"
              onChange={(e) => setImporto(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline mr-3"
          onClick={() => setModal(false)}
        >
          ANNULLA
        </Button>
        <Button
          className={classNames({
            'bottone-blu':
              fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' ||
              fiscoContext.dati.tipologiaFattura === 'Rettifica pr',
            'bottone-verde':
              fiscoContext.dati.tipologiaFattura === 'Fattura cartacea',
            'bottone-giallo': fiscoContext.dati.tipologiaFattura === 'Fattura PA',
            'bottone-bianco-blu-outline':
              fiscoContext.dati.tipologiaFattura === 'Proforma',
          })}
          onClick={() => aggiungi()}
        >
          AGGIUNGI PRESTAZIONE
        </Button>
      </ModalFooter>
    </Modal>
  )

  const componentPrestazioni = fiscoContext.dati.prestazioni.map(
    (prestazione, i) => (
      <div key={i} className="mb-3">
        <Row>
          <Col xs="8">
            <h6 className="text-muted">
              <u
                className="text-danger"
                onClick={() => rimuoviPrestazione(i)}
                style={{ cursor: 'pointer' }}
              >
                <i className="bi bi-trash3"></i>
              </u>
              &nbsp;
              {prestazione.descrizione}{' '}
              {prestazione.quantita > 1 && `x${prestazione.quantita}`}
            </h6>
          </Col>
          <Col xs="4">
            <h6 className="float-right font-regular">{prestazione.importo}€</h6>
          </Col>
        </Row>
      </div>
    )
  )

  return (
    <div className="mb-3">
      {componentPrestazioni}
      <Button
        onClick={() => setModal(true)}
        className={classNames('mt-1', {
          'bottone-blu':
            fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' ||
            fiscoContext.dati.tipologiaFattura === 'Rettifica pr',
          'bottone-verde': fiscoContext.dati.tipologiaFattura === 'Fattura cartacea',
          'bottone-giallo': fiscoContext.dati.tipologiaFattura === 'Fattura PA',
          'bottone-bianco-blu-outline':
            fiscoContext.dati.tipologiaFattura === 'Proforma',
        })}
      >
        AGGIUNGI PRESTAZIONE
      </Button>
      {modalPrestazione}
    </div>
  )
}

export default Prestazioni
