import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { Card, Row, Col } from 'reactstrap'
import moment from 'moment/moment'
import { UserContext } from '../../../app/context/User'

const PreviewAutofattura = () => {
  const fiscoContext = useContext(FiscomedContext)
  const { user } = useContext(UserContext)

  const tabellaImporti = () => {
    const tabella = fiscoContext.dati.prestazioniAutofattura.map(
      (prestazione, i) => (
        <tr key={i}>
          <td className="text-muted h6">{prestazione.descrizione}</td>
          <td className="text-muted h6">{prestazione.importoNetto}€</td>
          <td className="text-muted h6">{prestazione.iva}%</td>
          <td className="text-muted h6">{prestazione.importoIVA}€</td>
          <td className="h6 font-bold">{prestazione.importoTotale}€</td>
        </tr>
      )
    )
    return tabella
  }

  return (
    <Card body className="mt-2 mb-3" style={{ maxWidth: '900px' }}>
      <div className="d-flex justify-content-between">
        <small className="text-muted">
          Autofattura numero {fiscoContext.dati.numeroFattura}
        </small>
        <small className="text-muted">
          Data autofattura{' '}
          {moment(fiscoContext.dati.dataFattura).format('DD/MM/YYYY')}
        </small>
      </div>
      <br />
      <br />
      <Row>
        <Col xs="6">
          <span className="text-muted">Fornitore</span>
          <h5 className="font-medium">
            {fiscoContext.dati.clienteAttivo?.ragione_sociale}
          </h5>
          <h6 className="text-muted">
            {fiscoContext.dati.clienteAttivo?.indirizzo}
          </h6>
          <h6 className="text-muted">
            {fiscoContext.dati.clienteAttivo?.comune},{' '}
            {fiscoContext.dati.clienteAttivo?.cap}{' '}
            {fiscoContext.dati.clienteAttivo?.provincia &&
              `(${fiscoContext.dati.clienteAttivo?.provincia})`}
          </h6>
          <h6 className="text-muted">
            CF: {fiscoContext.dati.clienteAttivo?.codice_fiscale}
          </h6>
          {fiscoContext.dati.clienteAttivo?.partita_iva !== null && (
            <h6 className="text-muted">
              P.IVA: {fiscoContext.dati.clienteAttivo?.partita_iva}
            </h6>
          )}
        </Col>
        <Col xs="6">
          <span className="text-muted">Committente</span>
          <h5 className="font-medium">
            {user.fisco_nome} {user.fisco_cognome}
          </h5>
          <h6 className="text-muted">{user.fisco_indirizzo}</h6>
          <h6 className="text-muted">
            {user.fisco_comune}, {user.fisco_cap} ({user.fisco_provincia})
          </h6>
          <h6 className="text-muted">CF: {user.fisco_cf}</h6>
          <h6 className="text-muted">P.IVA: {user.fisco_partita_iva}</h6>
        </Col>
      </Row>
      <div className="my-4">
        <br />
        <small className="font-regular">
          Tipologia documento: {fiscoContext.dati.tipologiaAutofattura}, Fattura
          collegata {fiscoContext.dati.idDocumentoAutofattura} del{' '}
          {fiscoContext.dati.dataDocumentoAutofattura}
        </small>
        <br />
        <table className="table table-striped table-sm mt-2">
          <thead>
            <tr>
              <th scope="col" className="h6 font-medium">
                Descrizione
              </th>
              <th scope="col" className="h6 font-medium">
                Netto
              </th>
              <th scope="col" className="h6 font-medium">
                Aliquota
              </th>
              <th scope="col" className="h6 font-medium">
                IVA
              </th>
              <th scope="col" className="h6 font-medium">
                Totale
              </th>
            </tr>
          </thead>
          <tbody>{tabellaImporti()}</tbody>
        </table>
        <br />
        <div className="d-flex justify-content-end" style={{ minHeight: '240px' }}>
          <h4>Importo totale: {fiscoContext.dati.totaleImportoAutofattura} €</h4>
        </div>
      </div>
      <Row>
        <Col xs="6">
          <h5 className="font-medium">Pagamento</h5>
        </Col>
        <Col xs="6" />
      </Row>

      <hr />
      <Row>
        <Col xs="6">
          {fiscoContext.dati.metodoDiPagamentoAttivo === 'Contante' ? (
            <h5>Contante</h5>
          ) : (
            <>
              Intestatario: {fiscoContext.dati.metodoDiPagamentoAttivo.intestatario}
              <br />
              IBAN: {fiscoContext.dati.metodoDiPagamentoAttivo.iban}
            </>
          )}
        </Col>
        <Col xs="6" />
      </Row>
    </Card>
  )
}

export default PreviewAutofattura
