import React, { useContext } from 'react'
import { Button } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ScartoFatturaPA = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <Button
        className="bottone-rosso"
        size="sm"
        onClick={() => riepilogoContext.apriModalScartoPA(props.dati.fattura.id)}
      >
        SCARTATA
      </Button>
    </center>
  )
}

export default ScartoFatturaPA
