import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalDecorrenzaTerminiPA = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalDecorrenzaTerminiPAOpen}
      toggle={() => riepilogoContext.chiudiModalDecorrenzaTerminiPA()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalDecorrenzaTerminiPA()}
        className="h4 font-regular font-grigio-scuro"
      >
        Decorrenza termini
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Sono passati più di 15 giorni dalla data di invio della fattura elettronica
          e la pubblica amministrazione non ha inviato allo SdI né un esito positivo
          né un esito negativo 😪
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          La comunicazione fra te e la PA non è più di competenza dello Sdi. Tu non
          devi fare nulla. Puoi solo aspettare che la pubblica amministrazione faccia
          il prossimo passo. Potrebbero accadere due cose: che la pubblica
          amministrazione ti comunichi l&apos;esito attraverso altri canali oppure
          che ti paghi direttamente
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalDecorrenzaTerminiPA
