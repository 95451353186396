import React, { useContext, useState } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const DueDueRequisitiNonMantenuti = () => {
  const context = useContext(EnpamContext)
  const [borsaMMg, setBorsaMMg] = useState(false)
  const [intramoenia, setIntramoenia] = useState(false)
  const [altriFondi, setAltriFondi] = useState(false)
  const [nessunaSituazione, setNessunaSituazione] = useState(false)

  const avanti = () => {
    if (nessunaSituazione) {
      context.setScheda('perdita_requisiti')
    } else {
      context.setScheda('variazione_1')
    }
  }

  const requisito = () => {
    if (context.dati.scheda.includes('_mmg')) {
      return 'TIROCINANTE NEL CORSO DI FORMAZIONE DI MEDICINA GENERALE'
    }
    if (context.dati.scheda.includes('_dipendente')) {
      return "LAVORATORE DIPENDENTE E CHE PERCEPIVA COMPENSI PER L'ATTIVITÀ INTRAMOENIA"
    }
    if (context.dati.scheda.includes('_altrifondi')) {
      return "SOGGETTO A UN'ALTRA FORMA DI PREVIDENZA OBBLIGATORIA (INPS, FONDO DELLA MEDICINA CONVENZIONATA E ACCREDITATA ENPAM)"
    }
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={40} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        VA BENE, NON SEI PIÙ {requisito()}
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Per caso, attualmente, ti trovi in una di queste due situazioni?
      </h6>
      <br />

      {context.dati.scheda.includes('_mmg_') === false && (
        <Button
          className={classNames(
            { 'bottone-verde': borsaMMg },
            { 'bottone-verde-outline': !borsaMMg },
            'mb-4 py-3'
          )}
          style={{ width: '800px' }}
          onClick={() => {
            setBorsaMMg(true)
            setIntramoenia(false)
            setAltriFondi(false)
            setNessunaSituazione(false)
          }}
        >
          Sono tirocinante nel corso di formazione di medicina generale
        </Button>
      )}
      {context.dati.scheda.includes('_dipendente_') === false && (
        <Button
          className={classNames(
            { 'bottone-verde': intramoenia },
            { 'bottone-verde-outline': !intramoenia },
            'mb-4 py-3'
          )}
          style={{ width: '800px' }}
          onClick={() => {
            setBorsaMMg(false)
            setIntramoenia(true)
            setAltriFondi(false)
            setNessunaSituazione(false)
          }}
        >
          Sono un lavoratore dipendente e ho percepito compensi per l&apos;attività
          intramoenia
        </Button>
      )}
      {context.dati.scheda.includes('_altrifondi_') === false && (
        <Button
          className={classNames(
            { 'bottone-verde': altriFondi },
            { 'bottone-verde-outline': !altriFondi },
            'mb-4 py-3'
          )}
          style={{ width: '800px' }}
          onClick={() => {
            setBorsaMMg(false)
            setIntramoenia(false)
            setAltriFondi(true)
            setNessunaSituazione(false)
          }}
        >
          Sono soggetto a un&apos;altra forma di previdenza obbligatoria (INPS, Fondo
          della medicina convenzionata e accreditata ENPAM)
        </Button>
      )}
      <Button
        className={classNames(
          { 'bottone-verde': nessunaSituazione },
          { 'bottone-verde-outline': !nessunaSituazione },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(false)
          setAltriFondi(false)
          setNessunaSituazione(true)
        }}
      >
        Non mi trovo in nessuna di queste situazioni
      </Button>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default DueDueRequisitiNonMantenuti
