import React, { useContext } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const UnoUno = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={20} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        ALLORA HAI BISOGNO DI UNO SPIEGONE
      </h4>
      <h5 className="testo-verde font-bold">Prima parte</h5>

      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        La prima cosa che devi imparare è che cos&apos;è un&apos;aliquota
        contributiva.
        <br />
        <br />
        L&apos;<b>aliquota contributiva</b> è la percentuale da applicare per
        calcolare i contributi da versare all&apos;ENPAM attraverso la Quota B.
        <br />
        <br />
        Come medico hai la possibilità di versare <b>3 differenti aliquote</b>:
        <br />
        <ul type="round">
          <li>
            aliquota intera del <b>19,50%</b>
          </li>
          <li>
            metà dell&apos;aliquota intera, pari al <b>9,75%</b>
          </li>
          <li>
            aliquota ridotta del <b>2%</b>.
          </li>
        </ul>
        <br />A seconda del tipo di attività che svolgi puoi{' '}
        <b>scegliere l&apos;aliquota</b> con la quale versare i contributi relativi
        all&apos;anno precedente, ma devi farlo prima della compilazione del Modello
        D. La scelta va fatta compilando un modulo che si chiama &quot;
        <b>Contribuzione Ridotta</b>&quot;
      </h6>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => context.setScheda('.12')}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
      </div>
    </Card>
  )
}

export default UnoUno
