import React, { useContext, useState } from 'react'
import { UserContext } from '../../app/context/User'
import {
  Input,
  FormGroup,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import request from 'superagent'
import swal from '@sweetalert/with-react'

const Account = (props) => {
  const { user } = useContext(UserContext)
  const [password, setPassword] = useState(props.credenziali.password)
  const [pincode, setPincode] = useState(props.credenziali.pincode)
  const [modalPasswordOpen, setModalPasswordOpen] = useState(false)
  const [modalPincodeOpen, setModalPincodeOpen] = useState(false)

  const modalPassword = (
    <Modal isOpen={modalPasswordOpen} toggle={() => setModalPasswordOpen(false)}>
      <ModalHeader toggle={() => setModalPasswordOpen(false)}>
        Inserisci la nuova password
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-30">
          Ogni <b>60 giorni</b> il Sistema Tessera Sanitaria ti chiederà di cambiare
          la tua password. Per farlo, dovrai andare sul <b>sito</b> del Sistema
          Tessera Sanitaria e sceglierne una nuova. Una volta fatto, torna su
          Fiscomed e <b>aggiorna la tua password</b> da questa schermata. Mi
          raccomando: <i>la password non è il tuo codice fiscale</i>
        </h6>
        <br />
        <FormGroup>
          <Input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        <br />
        <Button onClick={() => salvaPassword()} className="float-right bottone-blu">
          AGGIORNA
        </Button>
      </ModalBody>
    </Modal>
  )

  const modalPincode = (
    <Modal isOpen={modalPincodeOpen} toggle={() => setModalPincodeOpen(false)}>
      <ModalHeader toggle={() => setModalPincodeOpen(false)}>
        Inserisci il nuovo pincode
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-30">
          Il pincode è un codice di <b>10 cifre</b>, che puoi trovare sul sito del
          Sistema Tessera Sanitaria. Dopo aver fatto l&apos;accesso, clicca su
          &quot;Profilo utente&quot; e poi su &quot;Stampa pincode&quot;. Ti verrà
          generato un <b>file PDF</b> con il tuo pincode.
        </h6>
        <br />
        <FormGroup>
          <Input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
        </FormGroup>
        <br />
        <Button onClick={() => salvaPincode()} className="float-right bottone-blu">
          AGGIORNA
        </Button>
      </ModalBody>
    </Modal>
  )

  const salvaPassword = () => {
    setModalPasswordOpen(false)
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/aggiorna-password`)
      .send({ token: user.auth_token, password })
      .then((response) => {
        setPassword(response.body.credenziali.password)
        props.impostaCredenziali({
          ...props.credenziali,
          password: response.body.credenziali.password,
        })
        swal({
          icon: 'success',
          title: 'Evviva 🥳',
          content: (
            <div>
              Abbiamo aggiornato la tua password. Ora puoi inviare le fatture al
              Sistema TS
            </div>
          ),
          button: 'OK',
        })
      })
      .catch((e) => console.error(e.message))
  }

  const salvaPincode = () => {
    const isnum = /^\d+$/.test(pincode)
    if (!isnum || pincode.length !== 10) {
      swal({
        title: "C'è qualcosa che non va 🙁",
        text: 'Non siamo riusciti ad aggiornare il tuo pincode. Ricorda che si tratta di un codice di 10 cifre (ad esempio: 1234567890)',
        icon: 'error',
        button: 'Ok',
      })
      return
    }
    setModalPincodeOpen(false)
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/aggiorna-pincode`)
      .send({ token: user.auth_token, pincode })
      .then((response) => {
        setPincode(response.body.credenziali.pincode)
        props.impostaCredenziali({
          ...props.credenziali,
          pincode: response.body.credenziali.pincode,
        })
        swal({
          icon: 'success',
          title: 'Evviva 🥳',
          content: (
            <div>
              Abbiamo aggiornato il tuo pincode. Ora puoi inviare le fatture al
              Sistema TS
            </div>
          ),
          button: 'OK',
        })
      })
      .catch((e) => console.error(e.message))
  }

  return (
    <div>
      <br />
      <h6 className="font-regular interlinea-30">
        Periodicamente, il Sistema Tessera Sanitaria ti richiederà ti cambiare le tue
        credenziali. Te ne accorgerai perché, quando proverai a inviare dei dati, ti
        segnalerà questo errore. A quel punto dovrai andare{' '}
        <a
          href="https://sistemats1.sanita.finanze.it/portale/area-riservata-operatore"
          target="_blank"
          rel="noreferrer"
        >
          <u>qui</u>
        </a>{' '}
        e scegliere delle nuove credenziali.
        <br />
        Una volta fatta questa operazione, torna su Fiscomed e aggiorna le tue
        credenziali da questa schermata.
      </h6>
      <br />
      <br />
      <Row>
        <Col md="2" />
        <Col md="4">
          <FormGroup>
            <Input type="text" value={props.credenziali.password} disabled={true} />
          </FormGroup>
        </Col>
        <Col md="4">
          <Button
            className="bottone-blu"
            onClick={() => setModalPasswordOpen(true)}
            block
          >
            AGGIORNA LA PASSWORD
          </Button>
        </Col>
        <Col md="2" />
        <Col md="2" />
        <Col md="4">
          <FormGroup>
            <Input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <Button
            className="bottone-blu"
            onClick={() => setModalPincodeOpen(true)}
            block
          >
            AGGIORNA IL PINCODE
          </Button>
        </Col>
        <Col md="2" />
      </Row>
      {modalPincode}
      {modalPassword}
    </div>
  )
}

export default Account
