import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../app/context/User'
import { useHistory } from 'react-router-dom'

const ControllerStatusFisco = ({ children }) => {
  const { isLoggedIn, isLoading } = useContext(UserContext)
  const history = useHistory()
  const [attivo, setAttivo] = useState(false)
  const smallScreen = window.innerWidth <= 1023

  useEffect(() => {
    setAttivo(false)
    if (isLoading === false) {
      if (isLoggedIn() === false) {
        history.push('/')
      } else {
        if (smallScreen) {
          history.push('/disponibile-sul-desktop')
        } else {
          setAttivo(true)
        }
      }
    }
  }, [isLoading, isLoggedIn])

  return <>{attivo && children}</>
}

export default ControllerStatusFisco
