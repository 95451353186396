import React, { useState, useContext, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import moment from 'moment'

const ModaleNumeroFattura = () => {
  const [modal, setModal] = useState(false)
  const fiscoContext = useContext(FiscomedContext)
  const [colore, setColore] = useState('#0583f2')

  useEffect(() => {
    if (fiscoContext.dati.tipologiaFattura === 'Fattura cartacea') {
      setColore('#66d98e')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Fattura elettronica') {
      setColore('#0583f2')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Fattura PA') {
      setColore('#f2b705')
    }
  }, [fiscoContext.dati.tipologiaFattura])

  const componentModal = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Cambia numero fattura e data fattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Ricordati che le tue fatture devono seguire un ordine di numerazione
          progressivo. Fiscomed genera il numero di fattura automaticamente. Cambialo
          solo se pensi che non sia corretto.
          <br />
          <br />
          La data indica quando è stato ceduto il bene o il servizio. Ricordati di
          non retrodatare la fattura elettronica o PA per più di 12 giorni, se hai
          questa esigenza contattaci.
        </h6>
        <br />
        <Form>
          <FormGroup>
            <Label>Numero fattura</Label>
            <Input
              type="number"
              value={fiscoContext.dati.numeroFattura}
              onChange={(e) => fiscoContext.aggiornaNumeroFattura(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Data fattura</Label>
            <Input
              type="date"
              value={fiscoContext.dati.dataFattura}
              onChange={(e) => fiscoContext.aggiornaDataFattura(e.target.value)}
            />
          </FormGroup>
        </Form>
        <Button
          className="bottone-blu float-right mt-3"
          style={{ backgroundColor: colore }}
          onClick={() => setModal(false)}
        >
          FATTO
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <span onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>
      <i className="bi bi-pencil"></i> Fattura n° {fiscoContext.dati.numeroFattura}
      <br /> Data {moment(fiscoContext.dati.dataFattura).format('DD/MM/YYYY')}{' '}
      {componentModal}
    </span>
  )
}

export default ModaleNumeroFattura
