import React, { useState, useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import swal from 'sweetalert'

const Fatture = () => {
  const context = useContext(DichiarazioneContext)
  const [caricati, setCaricati] = useState(false)
  const [incassati, setIncassati] = useState(false)
  const [nonHoFatture, setNonHoFatture] = useState(false)

  const controllaEProsegui = () => {
    if ((caricati && incassati) || nonHoFatture) {
      let caricatiEIncassati = true
      if (nonHoFatture) {
        caricatiEIncassati = false
      }
      context.completaStepFatture(caricatiEIncassati)
    } else {
      swal({
        title: 'Manca qualcosa',
        text: 'Se hai delle fatture e dei cedolini, spunta le prime due caselle prima di andare avanti. Se non hai alcun documento, spunta la casella in basso',
        icon: 'warning',
        button: 'CHIUDI',
      })
    }
  }

  const dichiaroDiAverCaricatoIDocumenti = (rispostaAffermativa) => {
    setCaricati(rispostaAffermativa)
    if (!rispostaAffermativa) setIncassati(false)
    if (rispostaAffermativa) setNonHoFatture(false)
  }

  const dichiaroDiAverIncassatoIDocumenti = (rispostaAffermativa) => {
    setIncassati(rispostaAffermativa)
    if (rispostaAffermativa) setNonHoFatture(false)
  }

  const dichiaroDiNonAvereFattureOCedolini = (rispostaAffermativa) => {
    setNonHoFatture(rispostaAffermativa)
    if (rispostaAffermativa) setCaricati(false)
    if (rispostaAffermativa) setIncassati(false)
  }

  return (
    <div>
      <h4 className="testo-verde font-bold mb-4">
        PRIMA TAPPA: FATTURE, CEDOLINI E BORSE DI STUDIO
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Controlla se su Fiscomed ci sono{' '}
        <span className="font-bold">tutte le fatture</span> (cartacee e elettroniche)
        che hai emesso e tutti i <span className="font-bold">cedolini</span> che hai
        ricevuto nel 2023. Se mancano una o più fatture o qualche cedolino, importali
        cliccando su &quot;Strumenti&quot; &gt; &quot;Importa fatture e
        cedolini&quot;.
      </h6>
      <h6 className="font-regular mb-3 interlinea-30">
        A questo punto, verifica che tutte le fatture e i cedolini siano stati
        correttamente <span className="font-bold">incassati</span>. Solitamente, le
        fatture e i cedolini si incassano non appena si riceve il pagamento, ma se
        non lo hai fatto puoi tranquillamente farlo ora inserendo la data in cui ti
        hanno pagato la fattura o il cedolino.
      </h6>
      <h6 className="font-regular mb-3 interlinea-30">
        Se frequenti la <span className="font-bold">scuola di specializzaione</span>{' '}
        o il <span className="font-bold">corso di medicina generale</span>, carica i
        cedolini delle borse di studio. Puoi farlo da &quot;Strumenti&quot; &gt;
        &quot;Importa borsa di studio&quot;. Questi cedolini non vanno incassati.
      </h6>
      <FormGroup check className="mt-4">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiAverCaricatoIDocumenti(e.target.checked)}
          valid={caricati}
          checked={caricati}
        />{' '}
        <Label check>Ci sono tutte le fatture e i cedolini</Label>
      </FormGroup>
      <FormGroup check className="mb-4  mt-2">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiAverIncassatoIDocumenti(e.target.checked)}
          valid={incassati}
          checked={incassati}
        />{' '}
        <Label check>
          Tutte le fatture e i cedolini sono incassati correttamente
        </Label>
      </FormGroup>
      <h6 className="font-regular mb-4 interlinea-30">
        Devi sapere che la dichiarazione dei redditi va fatta anche se si fattura 0.
        <br />
        <i>Se non hai alcun documento di questo tipo</i>, spunta la casella qui sotto
        prima di andare avanti.
      </h6>
      <FormGroup check className="mb-4">
        <Input
          type="checkbox"
          onChange={(e) => dichiaroDiNonAvereFattureOCedolini(e.target.checked)}
          valid={nonHoFatture}
          checked={nonHoFatture}
        />{' '}
        <Label check>Non ho fatture o cedolini relativi al 2023</Label>
      </FormGroup>
      <br />
      <Button
        className="bottone-blu float-right mb-4"
        onClick={() => controllaEProsegui()}
      >
        PROSSIMA TAPPA
      </Button>
    </div>
  )
}

export default Fatture
