import React, { useContext, useEffect } from 'react'
import { Alert, Card, Container, Spinner } from 'reactstrap'
import { RiepilogoContext } from '../../app/context/RiepilogoFatture'
import ModalImpossibilitaDiRecapito from './Esiti/ModalImpossibilitaDiRecapito'
import ModalRicevutaDiConsegna from './Esiti/ModalRicevutaDiConsegna'
import ModalScarto from './Esiti/ModalScarto'
import ModalScartoVariazione from './Esiti/ModalScartoVariazione'
import ModalIncassoRiepilogo from './Incasso/ModalIncasso'
import ModalRicevutaDiConsegnaPA from './Esiti/ModalRicevutaDiConsegnaPA'
import ModalMancataConsegnaPA from './Esiti/ModalMancataConsegnaPA'
import ModalDecorrenzaTerminiPA from './Esiti/ModalDecorrenzaTerminiPA'
import SelettoreAnno from './SlettoreAnno'
import Tabella from './Tabella'
import { Helmet } from 'react-helmet'
import ModalEsitoPositivoPA from './Esiti/ModalEsitoPositivoPA'
import ModalEsitoNegativoPA from './Esiti/ModalEsitoNegativoPA'
import ModalAttestazionePA from './Esiti/ModalAttestazionePA'
import ModalScartoPa from './Esiti/ModalScartoPa'
import ModalMancataConsegnaPR from './Esiti/ModalMancataConsegnaPR'
import ModalEmail from './Opzioni/ModalEmail'
import ModalMancataConsegnaAutofattura from './Esiti/ModalMancataConsegnaAutofattura'
import ModalVerificaSdI from './Esiti/ModalVerificaSdI'
const RiepilogoContainer = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  useEffect(() => {
    riepilogoContext.idrata()
  }, [])

  const caricamento = (
    <center>
      <Spinner size="lg" className="testo-blu" />
    </center>
  )

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Riepilogo fatture</title>
        <meta property="title" content="Riepilogo fatture" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ModalIncassoRiepilogo />
      <ModalImpossibilitaDiRecapito />
      <ModalRicevutaDiConsegna />
      <ModalScarto />
      <ModalScartoVariazione />
      <ModalRicevutaDiConsegnaPA />
      <ModalMancataConsegnaPA />
      <ModalMancataConsegnaPR />
      <ModalMancataConsegnaAutofattura />
      <ModalDecorrenzaTerminiPA />
      <ModalEsitoPositivoPA />
      <ModalEsitoNegativoPA />
      <ModalAttestazionePA />
      <ModalVerificaSdI />
      <ModalScartoPa />
      <ModalEmail />
      <Card body>
        <h4 className="font-medium">
          <i className="bi bi-journals"></i> Riepilogo delle fatture emesse
        </h4>
        <h6 className="mt-3 font-regular interlinea-30">
          In questa pagina sono elencate tutte le fatture che hai emesso, divise per
          anno. Ogni fattura è indicata dal proprio numero univoco.
          <br />
          Da questa schermata puoi incassare le fatture, creare una nota di credito o
          una nota di debito. Inoltre, puoi scaricare la tua fattura in vari formati
        </h6>
        <br />
        <span className="placeholder col-6"></span>
        {riepilogoContext.dati.loading ? (
          caricamento
        ) : riepilogoContext.dati.anniConFattureEmesse.length === 0 ? (
          <Alert color="info">
            <i className="bi bi-info-circle"></i> Non hai ancora emesso fatture
          </Alert>
        ) : (
          <>
            <SelettoreAnno />
            <br />
            <Tabella />
          </>
        )}
      </Card>
    </Container>
  )
}

export default RiepilogoContainer
