import React from 'react'
import { UserContext } from './User'
import { idrataEnpam, salvaSchedaSulServer } from '../utility/QueryEnpam'

const EnpamContext = React.createContext()

const genereMaschile = {
  il: 'il',
  ilTuo: 'il tuo',
  lui: 'lui',
  ilDottore: 'il Dottore',
  dalTuo: 'dal tuo',
  luiStesso: 'lui stesso',
}

const genereFemminile = {
  il: 'la',
  ilTuo: 'la tua',
  lui: 'lei',
  ilDottore: 'la Dottoressa',
  dalTuo: 'dalla tua',
  luiStesso: 'lei stessa',
}

const emptyState = {
  caricamento: true,
  scheda: 'chiuso',
  genere: genereMaschile,
  importo_modello_d: 0,
  quota_a: 0,
}

class EnpamProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  componentDidMount() {
    this.idrata()
    if (this.context.user.commercilista_uomo === false) {
      this.setState({
        ...this.state,
        genere: genereFemminile,
      })
    }
  }

  idrata = () =>
    idrataEnpam(this.context.user).then((responseBody) => {
      this.setState({
        scheda: responseBody.scheda,
        caricamento: false,
        importo_modello_d: responseBody.importo_modello_d,
        quota_a: responseBody.quota_a,
      })
    })

  setScheda = (scheda) => {
    this.setState({
      ...this.state,
      scheda,
    })
    salvaSchedaSulServer(this.context.user, scheda)
  }

  render() {
    const { children } = this.props

    const context = {
      dati: this.state,
      setScheda: this.setScheda,
    }

    return (
      <EnpamContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </EnpamContext.Provider>
    )
  }
}

export { EnpamProvider as default, EnpamContext }
