import React, { useContext } from 'react'
import { Card } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import moment from 'moment'

const HeaderNDCpr = () => {
  const fiscoContext = useContext(FiscomedContext)

  return (
    <Card
      className="mt-4"
      body
      style={{
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#0583f2',
      }}
    >
      <div className="d-flex justify-content-between">
        <h3 className="font-regular text-white">Nota di credito</h3>

        <h3 className="font-regular text-white">
          Numero {fiscoContext.dati.numeroFattura}/{moment().year()}
        </h3>
      </div>
    </Card>
  )
}

export default HeaderNDCpr
