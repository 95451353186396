import React, { useContext, useEffect, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  FormGroup,
  Spinner,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import swal from 'sweetalert'

const ModalEmail = () => {
  const riepilogoContext = useContext(RiepilogoContext)
  const [emailCliente, setEmailCliente] = useState(
    riepilogoContext.dati.fatturaSelezionata?.email_cliente
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEmailCliente(riepilogoContext.dati.fatturaSelezionata?.email_cliente)
  }, [riepilogoContext.dati.fatturaSelezionata])

  const inviaEmail = async () => {
    if (emailCliente === null || emailCliente === '') {
      swal({
        title: 'Attenzione',
        text: "Non hai inserito l'email del cliente. Compila il campo e riprova",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setLoading(true)
    const esito = await riepilogoContext.inviaEmailFattura(emailCliente)
    if (esito.email_inviata) {
      swal({
        title: 'Fattura inviata',
        text: 'Il tuo cliente riceverà la fattura a breve 😊',
        icon: 'success',
        button: 'Ok',
      })
      riepilogoContext.chiudiModalEmail()
      setLoading(false)
    } else {
      swal({
        title: 'Errore',
        text: "Non siamo riusciti ad inviare la tua fattura al cliente. Riprova tra un'ora oppure scrivici su WhatsApp",
        icon: 'error',
        button: 'Ok',
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalEmailOpen}
      toggle={() => riepilogoContext.chiudiModalEmail()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalEmail()}
        className="h4 font-regular font-grigio-scuro"
      >
        Invia la fattura per email
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Scrivi l&apos;indirizzo email del cliente e clicca il tasto &quot;Invia
          email&quot; per inviare la fattura 😊
        </h6>
        <br />
        <FormGroup>
          <Input
            type="text"
            placeholder="Inserisci l'email del cliente"
            value={emailCliente === null ? '' : emailCliente}
            onChange={(e) => setEmailCliente(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => inviaEmail()} className="bottone-blu">
          {loading ? <Spinner size="sm" /> : 'Invia email'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEmail
