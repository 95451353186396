import React, { useContext } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'

const AddebitoMarcaDaBollo = () => {
  const fiscoContext = useContext(FiscomedContext)

  const setMarcaDaBollo = (valore) =>
    fiscoContext.aggiornaAddebitoMarcaDaBollo(valore)

  return (
    <FormGroup check className="mb-2">
      <Label check>
        <Input
          type="checkbox"
          defaultChecked={fiscoContext.dati.addebitoMarcaDaBollo}
          onChange={() => setMarcaDaBollo(!fiscoContext.dati.addebitoMarcaDaBollo)}
        />
        <span className="text-muted">
          Addebita il costo della marca da bollo al cliente
        </span>
      </Label>
    </FormGroup>
  )
}

export default AddebitoMarcaDaBollo
