import React, { useState, useContext, useEffect } from 'react'
import { Card, Button, Progress } from 'reactstrap'
import { UserContext } from '../../../../app/context/User'
import { datiFattura } from '../../../../app/utility/QueryFattura'
import InvioPrecompilato from '../../../sistemaTS/InvioPrecompilato'
import { Link } from 'react-router-dom'

const InviaTS = (props) => {
  const idFattura = props.idFattura
  const { user } = useContext(UserContext)
  const [data, setData] = useState({})
  const [valoreProgress, setValoreProgress] = useState(40)

  useEffect(() => {
    datiFattura(user, idFattura).then((res) => {
      setData(res)
    })
    setValoreProgress(70)
  }, [])

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={valoreProgress} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            Che ne dici di inviare subito i dati della fattura al Sistema Tessera
            Sanitaria? 😊
          </h3>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Devi sapere che come medico hai l&apos;obbligo di inviare al Sistema
            Tessera Sanitaria i dati delle <span className="font-bold">fatture</span>{' '}
            emesse nei confronti di <span className="font-bold">pazienti</span> che
            hanno pagato per una tua prestazione sanitaria.
            <br />
            <br />
            Fino ad ora la scadenza per l&apos;invio dei dati è sempre stata
            semestrale, ma dal {new Date().getFullYear() + 1} potrebbe diventare
            mensile.
            <br />
            <br />
            Per questo motivo, ti consigliamo di trasmettere la fattura{' '}
            <span className="font-bold">subito dopo</span> la sua creazione, così non
            rischi di dimenticarti di farlo 😎
          </h6>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          {data?.sistema_ts?.sistemaAttivo ? (
            <InvioPrecompilato data={data} nextAction={() => props.TSInviato()} />
          ) : (
            <>
              <h4 className="font-medium mt-4">Manca ancora qualcosa 🙁</h4>

              <h6 className="font-regular interlinea-25 mt-3 mb-3">
                Per poter fare la trasmissione dei dati, devi prima{' '}
                <span className="font-bold">
                  registrarti al Sistema Tessera Sanitaria
                </span>{' '}
                e inserire su Fiscomed le tue{' '}
                <span className="font-bold">credenziali</span>.
                <br />
                <br />
                La procedura è un po&apos; seccante, ma non preoccuparti: abbiamo
                creato un percorso a step che ti permetterà di imparare tutto quello
                che devi sapere. <Link to="/tessera-sanitaria">
                  Clicca qui
                </Link> e <span className="font-bold">segui le istruzioni</span> che
                ti darà Giulia nei video. Una volta inserite le credenziali, potrai
                fare l&apos;invio direttamente dalla schermata in cui ti troverai.
                <br />
                <br />
                Dalla prossima fattura, invece, avrai la possibilità di trasmettere i
                dati subito dopo la sua creazione
              </h6>
            </>
          )}
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            <span className="font-bold">Non vuoi farlo ora?</span> Nessun problema.
            Puoi sempre inviare i dati di questa fattura al STS in un secondo
            momento, dalla sezione{' '}
            <Link to="/tessera-sanitaria">Sistema Tessera Sanitaria</Link>
          </h6>
          <div className="float-right">
            <Button
              className="bottone-blu-outline mt-3 mb-3"
              onClick={() => props.TSInviato()}
            >
              SALTA E VAI AVANTI
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default InviaTS
