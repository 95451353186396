import React, { useContext } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const OpzioniAutofattura = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => riepilogoContext.scaricaXML(props.dati.fattura.id)}
          >
            Scarica xml
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              riepilogoContext.scaricaPDFElettronica(props.dati.fattura.id)
            }
          >
            Scarica formato ministeriale
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniAutofattura
