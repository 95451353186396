import React, { useContext } from 'react'
import { EnpamContext } from '../../app/context/Enpam'
import { Container, Spinner, Row, Col, Card, CardTitle } from 'reactstrap'
import { Helmet } from 'react-helmet'
import Chiuso from './Schede/Chiuso'
import DueUno from './Schede/DueUno'
import Fine from './Schede/Fine'
import Start from './Schede/Start'
import PrimaDomanda from './Schede/PrimaDomanda'
import DueDue from './Schede/DueDue'
import DueDueRequisitiMantenuti from './Schede/DueDueRequisitiMantenuti'
import RinunciaAliquota from './Schede/RinunciaAliquota'
import ModelloD from './Schede/ModelloD'
import DueDueRequisitiNonMantenuti from './Schede/DueDueRequisitiNonMantenuti'
import PerditaRequisiti from './Schede/PerditaRequisiti'
import VariazioneUno from './Schede/VariazioneUno'
import VariazioneQuattro from './Schede/VariazioneQuattro'
import VariazioneDue from './Schede/VariazioneDue'
import VariazioneTre from './Schede/VariazioneTre'
import UnoUno from './Schede/UnoUno'
import UnoDue from './Schede/UnoDue'
import UnoTre from './Schede/UnoTre'
import UnoQuattro from './Schede/UnoQuattro'
import UnoCinque from './Schede/UnoCinque'
import UnoSei from './Schede/UnoSei'
import UnoSette from './Schede/UnoSette'
import NonInviare from './Schede/NonInviare'
import PreModelloD from './Schede/PreModelloD'

const EnpamContainer = () => {
  const context = useContext(EnpamContext)

  const caricamento = (
    <center>
      <Spinner size="lg" className="testo-blu" />
    </center>
  )

  return (
    <Container className="mt-4 mb-5" fluid>
      <Helmet>
        <title>Enpam</title>
        <meta property="title" content="Enpam" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {context.dati.caricamento ? (
        caricamento
      ) : (
        <Row>
          <Col xs={8} className="mb-3">
            <Card body style={{ height: '100%' }}>
              <CardTitle className="h3 font-grigio-scuro">
                <i className="bi bi-hospital"></i> L&apos;angolo dell&apos;ENPAM
              </CardTitle>
              <h6 className="font-regular mt-3 interlinea-30">
                Questa è la parte di Fiscomed dedicata all&apos;ENPAM. La cosa più
                importante che puoi fare da qui è seguire una procedura guidata che
                ti porterà fino alla compilazione e all&apos;invio del Modello D.
              </h6>
            </Card>
          </Col>
          <Col xs={4} className="mb-3">
            <Card body style={{ height: '100%', backgroundColor: '#f2b705' }}>
              <CardTitle className="h3 text-white">La tua Quota A</CardTitle>
              <h2 className="text-white mt-3" style={{ fontWeight: 400 }}>
                {context.dati.quota_a} €
              </h2>
              <h6 className="text-white mt-3">È quello che devi pagare</h6>
            </Card>
          </Col>
          <Col xs={8}>
            {context.dati.scheda === 'chiuso' && <Chiuso />}
            {context.dati.scheda === 'start' && <Start />}
            {context.dati.scheda === 'non_inviare' && <NonInviare />}
            {context.dati.scheda === 'primadomanda' && <PrimaDomanda />}
            {context.dati.scheda === '21' && <DueUno />}
            {context.dati.scheda === 'fine' && <Fine />}
            {context.dati.scheda.includes('.22_') &&
              !context.dati.scheda.includes('_requisiti') && <DueDue />}
            {context.dati.scheda.includes('requisitiMantenuti') && (
              <DueDueRequisitiMantenuti />
            )}
            {context.dati.scheda === 'rinuncia_aliquota_ridotta' && (
              <RinunciaAliquota />
            )}
            {context.dati.scheda === 'modello_d' && <ModelloD />}
            {context.dati.scheda.includes('_requisitiNonMantenuti') && (
              <DueDueRequisitiNonMantenuti />
            )}
            {context.dati.scheda === 'perdita_requisiti' && <PerditaRequisiti />}
            {context.dati.scheda === 'variazione_1' && <VariazioneUno />}
            {context.dati.scheda === 'variazione_4' && <VariazioneQuattro />}
            {context.dati.scheda === 'variazione_2' && <VariazioneDue />}
            {context.dati.scheda === 'variazione_3' && <VariazioneTre />}
            {context.dati.scheda === '.11' && <UnoUno />}
            {context.dati.scheda === '.12' && <UnoDue />}
            {context.dati.scheda === '.13' && <UnoTre />}
            {context.dati.scheda === '.14' && <UnoQuattro />}
            {context.dati.scheda.includes('.15_') && <UnoCinque />}
            {context.dati.scheda === '.16' && <UnoSei />}
            {context.dati.scheda === '.17' && <UnoSette />}
            {context.dati.scheda === '.pre_modello_d' && <PreModelloD />}
          </Col>
          <Col xs={4}>
            <Card body style={{ border: '2px solid #66d98e' }}>
              <h4 className="testo-verde font-bold mb-4">
                Importo coperto dalla Quota A
              </h4>
              <h6 className="font-regular mb-3 interlinea-30">
                La Quota A è l&apos;importo previdenziale minimo e obbligatorio,
                dovuto da tutte le persone iscritte all&apos;ENPAM. Questa quota
                copre una parte di quello che devi versare sul reddito che ottieni
                dalla libera professione.
                <br />
                <br />
                Se i tuoi guadagni coperti dalla quota A non superano certi importi,
                significa che hai già pagato i contributi obbligatori e non devi
                versare ulteriori soldi per la Quota B.
                <br />
                <br />
                Questi importi sono:
                <ul>
                  <li>4.484,51 € se hai meno di 40 anni</li>
                  <li>8.282,15 € se hai più di 40 anni</li>
                </ul>
                <br />
                <b>
                  A meno che il tuo reddito libero professionale non sia uguale a 0,
                  l&apos;ENPAM consiglia comunque di compilare il Modello D.
                </b>{' '}
                In questo modo può verificare la tua situazione e tu eviti di fare
                errori.
              </h6>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default EnpamContainer
