import React, { useEffect, useState, useContext } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { setUserId, trackPage } from './GA'
import { UserContext } from '../../app/context/User'

const AnalyticsPageSentinel = withRouter((props) => <Analytics {...props} />)

const Analytics = (props) => {
  const history = useHistory()
  const { user, isLoggedIn, isLoading } = useContext(UserContext)
  const [idSetted, setIdSetted] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad) {
      trackPage(props.location.pathname)
      setFirstLoad(false)
    }
    history.listen((location) => {
      trackPage(location.pathname)
    })
  }, [history])

  // set userId
  useEffect(() => {
    if (!isLoading && isLoggedIn() && !idSetted) {
      setUserId(user)
      setIdSetted(true)
    }
  }, [isLoading])

  return <></>
}
export default AnalyticsPageSentinel
