import React, { useState } from 'react'
import { Container, Input, Button, Spinner, FormGroup, Label } from 'reactstrap'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import { leadChiamataGA } from '../../../app/utility/GA'
import swal from '@sweetalert/with-react'
import { lead } from '../../../app/utility/SwalContent'

const ChiamataMobile = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if (nome === '' || email === '' || telefono === '' || consenso === false) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }).then((res) => {
      setCaricamento(false)
      swal(lead)
    })
  }

  return (
    <>
      <div style={{ backgroundColor: '#0583f226' }} className="pt-5">
        <Container className="mobile-container">
          <span className="titolo3-mobile">
            Parla con noi e scopri perché Fiscomed è la scelta giusta per un dottore
            o una dottoressa come te
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Scrivi qui i tuoi contatti e il momento in cui preferisci ricevere la
            chiamata. Non vediamo l&apos;ora di rispondere alle tue domande.
            Naturalmente non cederemo a nessuno i tuoi dati.
          </h6>
          <br />

          <h6 className="font-medium mb-3">Come ti chiami?</h6>
          <Input
            type="text"
            placeholder="Ippocrate di Coo"
            style={{ border: 'none' }}
            autoComplete="name"
            onChange={(event) => setNome(event.target.value)}
            valuer={nome}
          />
          <br />
          <br />
          <h6 className="font-medium mb-3">Qual è la tua email?</h6>
          <Input
            type="text"
            placeholder="ippocrate@email.com"
            style={{ border: 'none' }}
            autoComplete="email"
            onChange={(event) => setEmail(event.target.value)}
            valuer={email}
          />
          <br />
          <br />
          <h6 className="font-medium mb-3">A quale numero possiamo chiamarti?</h6>
          <Input
            type="text"
            placeholder="3399957060"
            style={{ border: 'none' }}
            autoComplete="tel"
            onChange={(event) => setTelefono(event.target.value)}
            valuer={telefono}
          />
          <br />
          <br />
          <h6 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h6>
          <Input
            type="textarea"
            placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
            rows="6"
            style={{ border: 'none' }}
            onChange={(event) => setMessage(event.target.value)}
            valuer={message}
          />
          <br />
          <FormGroup check>
            <Input
              type="checkbox"
              name="privacy"
              id="privacy"
              style={{ marginTop: '2px' }}
              checked={consenso}
              onChange={(e) => setConsenso(e.target.checked)}
            />
            <Label for="privacy" className="consenso">
              Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
              <a
                href="/documenti/termini_e_condizioni.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>privacy policy</u>
              </a>
            </Label>
          </FormGroup>
          <br />
          {caricamento ? (
            <Button block className="bottone-verde px-5 mt-4 float-right" disabled>
              <Spinner color="light" size="sm" />
            </Button>
          ) : (
            <Button
              block
              className="bottone-verde px-5 mt-4 float-right"
              onClick={() => prenotaChiamata()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              INIZIA ORA
            </Button>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
    </>
  )
}

export default ChiamataMobile
