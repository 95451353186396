import React, { useContext } from 'react'
import { Button } from 'reactstrap'
import swal from 'sweetalert'
import { EnpamContext } from '../../app/context/Enpam'

const Ricomincia = () => {
  const context = useContext(EnpamContext)

  const handleClick = () => {
    swal({
      text: "Se decidi di ricominciare, tornerai all'inizio del percorso e si cancelleranno tutte le risposte date fino ad ora. \nSe questa è la tua intenzione, clicca su 'Confermo'",
      icon: 'warning',
      buttons: ['ANNULLA', 'CONFERMO'],
    }).then((isConfirm) => {
      if (isConfirm) {
        context.setScheda('start')
      }
    })
  }
  return (
    <>
      <Button className="bottone-blu" onClick={() => handleClick()}>
        <i className="bi bi-arrow-clockwise"></i> RICOMINCIA
      </Button>
    </>
  )
}

export default Ricomincia
