import React, { useContext } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import ItemFatturaDiCortesia from './ItemFatturaDiCortesia'

const OpzioniVerificaSdi = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => riepilogoContext.apriModalEmail(props?.dati?.fattura?.id)}
          >
            Invia per email
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => riepilogoContext.scaricaXML(props?.dati?.fattura?.id)}
          >
            Scarica xml
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              riepilogoContext.scaricaPDFElettronica(props?.dati?.fattura?.id)
            }
          >
            Scarica formato ministeriale
          </DropdownItem>
          <ItemFatturaDiCortesia dati={props.dati} />
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniVerificaSdi
