import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { ImportaBorsaContext } from '../../app/context/ImportaBorsa'

const SelettoreAnno = () => {
  const importaBorsaContext = useContext(ImportaBorsaContext)

  const cambiaAnno = (anno) => importaBorsaContext.impostaAnnoEIdrata(anno)

  return (
    <Row>
      <Col>
        {(Math.min(...importaBorsaContext.dati.anniConBorseEmesse) <
          importaBorsaContext.dati.annoDiRiferimento ||
          importaBorsaContext.dati.annoDiRiferimento >
            importaBorsaContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              cambiaAnno(importaBorsaContext.dati.annoDiRiferimento - 1)
            }
          >
            <i className="bi bi-arrow-left-square float-right"></i>
          </h3>
        )}
      </Col>
      <Col>
        <center>
          <h3 className="font-regular">
            Anno {importaBorsaContext.dati.annoDiRiferimento}
          </h3>
        </center>
      </Col>
      <Col>
        {(Math.max(...importaBorsaContext.dati.anniConBorseEmesse) >
          importaBorsaContext.dati.annoDiRiferimento ||
          importaBorsaContext.dati.annoDiRiferimento <
            importaBorsaContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              cambiaAnno(importaBorsaContext.dati.annoDiRiferimento + 1)
            }
          >
            <i className="bi bi-arrow-right-square"></i>
          </h3>
        )}
      </Col>
    </Row>
  )
}

export default SelettoreAnno
