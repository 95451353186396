import React from 'react'
import request from 'superagent'
import { loginGA } from '../utility/GA'
import * as Sentry from '@sentry/react'

const emptyErrorState = {
  error: undefined,
  message_error: undefined,
}

const emptyUserObject = {
  auth_token: undefined,
  biography: undefined,
  first_name: undefined,
  last_name: undefined,
  nickname: undefined,
  profile_image_url: undefined,
  public_uuid: undefined,
  role: undefined,
  username: undefined,
  role_in_app: undefined,
  ...emptyErrorState,
}

const UserContext = React.createContext({
  user: { ...emptyUserObject },
  isLoading: true,
  login: () => {},
  loginCommercialista: () => {},
  isLoggedIn: () => {},
  logout: () => {},
  confirmEmail: () => {},
  updateUserInfo: () => {},
  fetchUserInfoByUuid: () => {},
  canChangeBio: () => {},
  canChangeName: () => {},
})

class UserProvider extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      user: { ...emptyUserObject },
    }
  }

  componentDidMount() {
    if (this.state.user.auth_token === undefined) {
      this.restoreStateFromLocalStorage()
    }
  }

  restoreStateFromLocalStorage = () => {
    const localState = JSON.parse(localStorage.getItem('userContext'))
    if (localState) {
      this.setState(
        {
          ...localState,
          isLoading: true,
        },
        () => {
          this.updateUserInfo()
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  saveStateToLocalStorage = () => {
    localStorage.setItem(
      'userContext',
      JSON.stringify({
        ...this.state,
        emailError: false,
        error: false,
        isLoading: false,
      })
    )
  }

  resetStateInLocalStorage = () => {
    localStorage.removeItem('userContext')
    this.setState({ isLoading: false })
  }

  login = (email, password) => {
    this.setState({ isLoading: true })
    return request
      .post(`${process.env.REACT_APP_API_URL}/signin`)
      .set({
        'Content-Type': 'application/json',
      })
      .send({ email, password })
      .then((resp) => JSON.parse(resp.text))
      .then((response) => {
        if (response.error === false) {
          this.setState({ isLoading: false })
          loginGA(response)
          this.saveUserInfo(response)
          return false
        } else {
          this.setState({
            user: {
              ...this.state.user,
              error: true,
              message_error: response.message_error,
            },
            isLoading: false,
          })
          return true
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          user: {
            ...this.state.user,
            error: true,
            message_error: 'Errore di connessione, riprova più tardi',
          },
        })
        return true
      })
  }

  loginCommercialista = (uuidUser, token) => {
    this.setState({ isLoading: true })
    return request
      .post(`${process.env.REACT_APP_API_URL}/signinCommercialista`)
      .set({
        'Content-Type': 'application/json',
      })
      .send({ uuidUser, token })
      .then((resp) => JSON.parse(resp.text))
      .then((response) => {
        if (response.error === false) {
          this.setState({ isLoading: false })
          this.saveUserInfo(response)
          return false
        } else {
          this.setState({
            user: {
              ...this.state.user,
              error: true,
              message_error: response.message_error,
            },
            isLoading: false,
          })
          return true
        }
      })
  }

  confirmEmail = (token) =>
    request
      .post(`${process.env.REACT_APP_API_URL}/email-confirmation`)
      .send({ tokenConfermaEmail: token })
      .end((err, res) => {
        if (err) {
          console.log(err)
        } else {
          if (res.body.error) {
            console.error(res.body.message_error)
          } else {
            if (res.body.auth_token && res.body.auth_token.length > 0) {
              this.updateUserInfo(res.body.auth_token)
            } else {
              console.error('auth token was not received')
            }
          }
        }
      })

  updateUserInfo = (token) =>
    request
      .get(`${process.env.REACT_APP_API_URL}/user-info`)
      .set({
        token: token ?? this.state.user.auth_token,
      })
      .then((resp) => JSON.parse(resp.text))
      .then((response) => {
        if (response.error === false) {
          this.saveUserInfo(response)
        } else {
          this.setState({
            user: {
              ...this.state.user,
              error: true,
              message_error: response.message_error,
            },
            isLoading: false,
          })
        }
      })
      .catch(() => {
        console.log('viene eseguito il logout')
        this.logout()
      })

  fetchUserInfoByUuid = (uuid) => {
    if (this.isLoggedIn()) {
      return request
        .post(`${process.env.REACT_APP_API_URL}/auth-public-user-info`)
        .set({
          token: this.state.user.auth_token,
        })
        .send({
          public_uuid: uuid,
        })
        .then((resp) => JSON.parse(resp.text))
        .then((response) => {
          if (response.error === false) {
            return response.user
          } else {
            console.error(response.error)
          }
        })
    } else {
      return request
        .post(`${process.env.REACT_APP_API_URL}/public-user-info`)
        .send({
          public_uuid: uuid,
        })
        .then((resp) => JSON.parse(resp.text))
        .then((response) => {
          if (response.error === false) {
            return response.user
          } else {
            console.error(response.error)
          }
        })
    }
  }

  saveUserInfo = (res) => {
    const userResponse = { ...res }
    this.setState({ user: userResponse, isLoading: false })
    this.saveStateToLocalStorage()
    Sentry.setUser({ email: userResponse.email })
  }

  logout = () => {
    this.setState({ user: { ...emptyUserObject } })
    this.resetStateInLocalStorage()
  }

  isLoggedIn = () => {
    if (this.state.user.auth_token !== undefined && this.state.isLoading === false) {
      return true
    }
    return false
  }

  render() {
    const { children } = this.props
    const context = {
      isLoading: this.state.isLoading,
      user: this.state.user,
      login: this.login,
      loginCommercialista: this.loginCommercialista,
      isLoggedIn: this.isLoggedIn,
      confirmEmail: this.confirmEmail,
      updateUserInfo: this.updateUserInfo,
      fetchUserInfoByUuid: this.fetchUserInfoByUuid,
      logout: this.logout,
    }

    return (
      <UserContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </UserContext.Provider>
    )
  }
}

export { UserProvider as default, UserContext }
