import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
  Tooltip,
} from 'reactstrap'
import request from 'superagent'
import { UserContext } from '../../../app/context/User'
import swal from 'sweetalert'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { latin1Invalid } from '../../../app/utility/ValidatoreRegex'

const ModalAggiungiCliente = (props) => {
  const [modal, setModal] = useState(false)
  const [schermata, setSchermata] = useState('sceltaTipologiaCliente')
  const [nome, setNome] = useState('')
  const [partitaIva, setPartitaIva] = useState('')
  const [codFiscale, setCodFiscale] = useState('')
  const [cap, setCap] = useState('')
  const [comune, setComune] = useState('')
  const [indirizzo, setIndirizzo] = useState('')
  const { user } = useContext(UserContext)
  const [codiceDestinatario, setCodiceDestinatario] = useState('')
  const [pec, setPec] = useState('')
  const [erroreCF, setErroreCf] = useState(false)
  const [errorePIva, setErrorePIva] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nazione, setNazione] = useState('IT')
  const [provincia, setProvincia] = useState('')
  const [checkboxPIva, setCheckboxPIva] = useState(false)
  const fiscoContext = useContext(FiscomedContext)
  const [conCf, setConCf] = useState(true)
  const [tooltip, setTooltip] = useState({
    ragione: false,
    partitaIva: false,
    CF: false,
    noPIVA: false,
    comune: false,
    PA: false,
    CFPA: false,
    conCf: false,
    cap: false,
    provincia: false,
  })

  const salvaClientePersona = () => {
    if (
      nome.length === 0 ||
      codFiscale.length === 0 ||
      cap.length === 0 ||
      comune.length === 0 ||
      indirizzo.length === 0 ||
      provincia.length !== 2
    ) {
      alert('Devi compilare tutti i campi prima di andare avanti')
      return
    }
    setLoading(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/add-persona`)
      .send({
        token: user.auth_token,
        nome,
        codFiscale,
        cap,
        comune,
        indirizzo,
        provincia,
      })
      .then((response) => {
        fiscoContext.aggiornaClienti(
          response.body.clienti,
          response.body.idClienteCreato
        )
        annullaSalvaCliente()
        if (props.refreshClienti) props.refreshClienti()
        setLoading(false)
        swal({
          title: 'Cliente salvato',
          text: 'Ora puoi emettere le fatture nei suoi confronti',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          if (props.funzioneDiChiusura) props.funzioneDiChiusura()
        })
      })
      .catch((e) => console.error(e.message))
  }

  const salvaClienteAzienda = () => {
    if (
      nome.length === 0 ||
      (partitaIva.length === 0 && checkboxPIva === false) ||
      cap.length === 0 ||
      comune.length === 0 ||
      indirizzo.length === 0 ||
      nazione.length !== 2
    ) {
      alert(
        'Ci sono alcuni campi obbligatori che devono essere compilati, controlla se uno di questi campi è vuoto: \n\nRagione sociale \nPartita IVA \nCodice fiscale \nCAP \nComune \nIndirizzo \nNazione'
      )
      return
    }
    setLoading(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/add-azienda`)
      .send({
        token: user.auth_token,
        nome,
        partitaIva,
        codFiscale,
        cap,
        comune,
        indirizzo,
        codiceDestinatario,
        pec,
        nazione,
        provincia,
      })
      .then((response) => {
        fiscoContext.aggiornaClienti(
          response.body.clienti,
          response.body.idClienteCreato
        )
        annullaSalvaCliente()
        if (props.refreshClienti) props.refreshClienti()
        setLoading(false)
        swal({
          title: 'Cliente salvato',
          text: 'Ora puoi emettere le fatture nei suoi confronti',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          if (props.funzioneDiChiusura) props.funzioneDiChiusura()
        })
      })
      .catch((e) => console.error(e.message))
  }

  const salvaClientePA = () => {
    if (
      nome.length === 0 ||
      cap.length === 0 ||
      comune.length === 0 ||
      indirizzo.length === 0
    ) {
      alert(
        'Ci sono alcuni campi obbligatori che devono essere compilati, controlla se uno di questi campi è vuoto: \n\nRagione sociale \nCAP \nComune \nIndirizzo \nCodice PA'
      )
      return
    }
    setLoading(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/add-pa`)
      .send({
        token: user.auth_token,
        nome,
        codFiscale,
        partitaIva,
        cap,
        comune,
        indirizzo,
        codiceDestinatario,
        provincia,
      })
      .then((response) => {
        fiscoContext.aggiornaClienti(
          response.body.clienti,
          response.body.idClienteCreato
        )
        annullaSalvaCliente()
        if (props.refreshClienti) props.refreshClienti()
        setLoading(false)
        swal({
          title: 'Cliente salvato',
          text: 'Ora puoi emettere le fatture nei suoi confronti',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          if (props.funzioneDiChiusura) props.funzioneDiChiusura()
        })
      })
      .catch(() =>
        swal({
          title: 'Errore',
          text: 'Si è verificato un errore, riprova più tardi',
          icon: 'error',
          button: 'OK',
        })
      )
  }

  const annullaSalvaCliente = () => {
    setModal(false)
    setNome('')
    setPartitaIva('')
    setCodFiscale('')
    setCap('')
    setComune('')
    setIndirizzo('')
    setSchermata('sceltaTipologiaCliente')
  }

  const controlloCFPersona = (value) => {
    if (value.length > 16) {
      return
    }
    setCodFiscale(value)
    const regex =
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    if (value.length === 16 && value.match(regex)) {
      setErroreCf(false)
      return
    }
    setErroreCf(true)
  }

  const controlloPIVA = (value) => {
    setPartitaIva(value)
    if (value === partitaIva && !errorePIva) {
      setErrorePIva(false)
      return
    }
    if (value.length !== 11) {
      setErrorePIva(true)
      return
    }
    if (/^\d+$/.test(value)) {
      setErrorePIva(false)
    } else {
      setErrorePIva(true)
    }
  }

  const controlloCFAzienda = (value) => {
    if (value.length > 16) {
      return
    }
    setCodFiscale(value)
    if (value.length === 11 && /^\d+$/.test(value)) {
      setErroreCf(false)
      return
    }
    const regex =
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    if (value.length === 16 && value.match(regex)) {
      setErroreCf(false)
      return
    }
    setErroreCf(true)
  }

  const bodyPrivato = (
    <>
      <Form>
        <FormGroup>
          <Label className="font-grigio-scuro">Nome e cognome</Label>
          <Input
            type="text"
            value={nome}
            onChange={(e) => {
              if (e.target.value.length <= 80) {
                setNome(e.target.value)
              }
            }}
            placeholder="Mario Rossi"
          />
        </FormGroup>
        {conCf && (
          <FormGroup className="font-grigio-scuro">
            <Label>Codice fiscale</Label>
            <Input
              type="text"
              value={codFiscale}
              onChange={(e) =>
                controlloCFPersona(e.target.value.toUpperCase().trim())
              }
              valid={codFiscale.length > 0 && !erroreCF}
              placeholder="RSSMRA30A01H501I"
            />
            <FormFeedback valid>Sembra corretto</FormFeedback>
          </FormGroup>
        )}
        <FormGroup check className="mb-3">
          <Label check className="text-muted">
            <Input
              type="checkbox"
              id="checkboxCF"
              checked={!conCf}
              onChange={() => {
                setConCf(!conCf)
                setCodFiscale('000000000000000')
              }}
            />{' '}
            Il paziente non ha un codice fiscale{' '}
            <i className="bi bi-question-circle" id="tooltipConCf"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.conCf}
              target="tooltipConCf"
              toggle={() => setTooltip({ ...tooltip, conCf: !tooltip.conCf })}
            >
              Alcuni pazienti potrebbero non avere il codice fiscale italiano. In
              questo caso sarebbe opportuno inserire il codice fiscale del paese di
              provenienza. Se il cliente non ha nessun dato da fornire, seleziona
              questo campo e il codice fiscale verrà impostato a zero.
            </Tooltip>
          </Label>
        </FormGroup>
      </Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className="font-grigio-scuro">CAP</Label>
            <Input
              type="text"
              value={cap}
              placeholder="00184"
              onChange={(e) => {
                if (e.target.value.trim().length <= 5) {
                  setCap(e.target.value.trim())
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="font-grigio-scuro">Comune</Label>
            <Input
              type="text"
              value={comune}
              placeholder="Roma"
              onChange={(e) => {
                if (e.target.value.length < 60) {
                  setComune(e.target.value)
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Form>
        <FormGroup>
          <Label className="font-grigio-scuro">Indirizzo</Label>
          <Input
            placeholder="Piazza Venezia"
            type="text"
            value={indirizzo}
            onChange={(e) => {
              if (e.target.value.length < 60) {
                setIndirizzo(e.target.value)
              }
            }}
            invalid={latin1Invalid(indirizzo)}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            *Provincia <small className="text-muted">(due lettere)</small>
          </Label>
          <Input
            type="text"
            value={provincia}
            placeholder="RM"
            onChange={(e) => {
              if (e.target.value.length <= 2) {
                setProvincia(e.target.value.toUpperCase())
              }
            }}
          />
        </FormGroup>
      </Form>
      <small className="text-muted">Tutti i campi sono obbligatori</small>
    </>
  )

  const bodyAzienda = (
    <>
      <Form>
        <FormGroup>
          <Label>
            *Ragione sociale{' '}
            <i className="bi bi-question-circle" id="tooltipRagione-id"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.ragione}
              target="tooltipRagione-id"
              toggle={() => setTooltip({ ...tooltip, ragione: !tooltip.ragione })}
            >
              La ragione sociale è il nome dell&apos;azienda. Un esempio di ragione
              sociale potrebbe essere Google LLC oppure COCA COLA HBC ITALIA S.R.L.
              Ricorda di non utilizzare caratteri speciali.
            </Tooltip>
          </Label>
          <Input
            placeholder="Google LLC"
            type="text"
            value={nome}
            onChange={(e) => {
              if (e.target.value.length <= 80) {
                setNome(e.target.value)
              }
            }}
            invalid={latin1Invalid(nome)}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        {!checkboxPIva && (
          <FormGroup>
            <Label>
              Partita IVA{' '}
              <i className="bi bi-question-circle" id="tooltipPartitaIva-id"></i>
              <Tooltip
                placement="top"
                isOpen={tooltip.partitaIva}
                target="tooltipPartitaIva-id"
                toggle={() =>
                  setTooltip({ ...tooltip, partitaIva: !tooltip.partitaIva })
                }
              >
                La Partita IVA è, solitamente, un numero di 11 cifre che ti dovrebbe
                fornire il cliente o il fornitore verso cui emetti la fattura
              </Tooltip>
            </Label>
            <Input
              type="text"
              value={partitaIva}
              placeholder="16612071007"
              onChange={(e) => controlloPIVA(e.target.value)}
              valid={partitaIva.length > 0 && !errorePIva}
              invalid={partitaIva.length > 0 && errorePIva}
            />
            <FormFeedback valid>Sembra corretto</FormFeedback>
            <FormFeedback style={{ color: '#eda515' }}>
              Dovrebbe essere di 11 cifre, ma per soggetti esteri potrebbe essere
              differente. Se stai aggiungendo il fornitore per un&apos;autofattura
              vai avanti anche se la P. IVA ha meno di 11 cifre
            </FormFeedback>
          </FormGroup>
        )}

        <FormGroup check className="mb-3">
          <Label check className="text-muted">
            <Input
              type="checkbox"
              id="checkboxPIva"
              checked={checkboxPIva}
              onChange={() => {
                setCheckboxPIva(!checkboxPIva)
                setPartitaIva('')
              }}
            />{' '}
            L&apos;azienda non ha una partita IVA{' '}
            <i className="bi bi-question-circle" id="tooltipNoPIva"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.noPIVA}
              target="tooltipNoPIva"
              toggle={() => setTooltip({ ...tooltip, noPIVA: !tooltip.noPIVA })}
            >
              Alcuni volte le associazioni non hanno una partita IVA, ma hanno solo
              il codice fiscale. Spesso il loro codice fiscale inizia con il numero
              9. In questo caso seleziona questa casella e lascia il campo partita
              IVA vuoto. Compila solo il campo codice fiscale
            </Tooltip>
          </Label>
        </FormGroup>

        <FormGroup>
          <Label>
            Codice fiscale <i className="bi bi-question-circle" id="tooltipCF"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.CF}
              target="tooltipCF"
              toggle={() => setTooltip({ ...tooltip, CF: !tooltip.CF })}
            >
              Nelle aziende spesso coincide con la partita IVA
            </Tooltip>
          </Label>
          <Input
            type="text"
            value={codFiscale}
            placeholder="16612071007"
            onChange={(e) => controlloCFAzienda(e.target.value.toUpperCase().trim())}
            valid={codFiscale.length > 0 && !erroreCF}
            invalid={codFiscale.length > 0 && erroreCF}
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Non sembra corretto. Se hai ricontrollato e sei sicuro di quello che hai
            scritto continua
          </FormFeedback>
        </FormGroup>
      </Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              *CAP <i className="bi bi-question-circle" id="tooltipCap"></i>
            </Label>
            <Tooltip
              placement="top"
              isOpen={tooltip.cap}
              target="tooltipCap"
              toggle={() => setTooltip({ ...tooltip, cap: !tooltip.cap })}
            >
              Se stai aggiungendo un fornitore per un&apos;autofattura, inserisci
              cinque volte il numero 0 (00000)
            </Tooltip>
            <Input
              type="text"
              placeholder="00184"
              value={cap}
              onChange={(e) => {
                if (e.target.value.trim().length <= 5) {
                  setCap(e.target.value.trim())
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>*Comune</Label>
            <Input
              type="text"
              placeholder="Roma"
              value={comune}
              onChange={(e) => {
                if (e.target.value.length < 60) {
                  setComune(e.target.value)
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Form>
        <FormGroup>
          <Label>
            *Nazione{' '}
            <small>
              <u>
                <a
                  href="https://it.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lista abbreviazioni
                </a>
              </u>
            </small>
          </Label>
          <Input
            type="text"
            value={nazione}
            onChange={(e) => {
              if (e.target.value.length < 3) {
                setNazione(e.target.value.toUpperCase())
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>*Indirizzo</Label>
          <Input
            type="text"
            value={indirizzo}
            placeholder="Piazza Venezia, 1"
            onChange={(e) => {
              if (e.target.value.length < 60) {
                setIndirizzo(e.target.value)
              }
            }}
            invalid={latin1Invalid(indirizzo)}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            *Provincia <small className="text-muted">(due lettere) </small>
            <i className="bi bi-question-circle" id="tooltipProvincia"></i>
          </Label>
          <Tooltip
            placement="top"
            isOpen={tooltip.provincia}
            target="tooltipProvincia"
            toggle={() => setTooltip({ ...tooltip, provincia: !tooltip.provincia })}
          >
            Se stai aggiungendo un fornitore per un&apos;autofattura, scrivi EE
          </Tooltip>
          <Input
            type="text"
            value={provincia}
            placeholder="RM"
            onChange={(e) => {
              if (e.target.value.length <= 2) {
                setProvincia(e.target.value.toUpperCase())
              }
            }}
          />
        </FormGroup>
      </Form>
      <FormGroup>
        <Label>
          Codice destinatario o codice SdI <br />
          <small className="text-muted interlinea-20">
            Compila questo campo solo se te lo chiede il tuo cliente. Se lasci il
            campo vuoto, verrà automaticamente impostato a 0000000
          </small>
        </Label>

        <Input
          type="text"
          value={codiceDestinatario}
          onChange={(e) => {
            if (e.target.value.length < 8) {
              setCodiceDestinatario(e.target.value.toUpperCase())
            }
          }}
          valid={codiceDestinatario.length > 0 && codiceDestinatario.length === 7}
          invalid={codiceDestinatario.length > 0 && codiceDestinatario.length !== 7}
          placeholder={'0000000'}
        />
        <FormFeedback valid>Sembra corretto</FormFeedback>
        <FormFeedback>
          Il codice destinatario, anche chiamato codice sdi è composto da 7 caratteri
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>
          Pec <br />
          <small className="text-muted">
            Compila questo campo solo se te lo chiede il tuo cliente
          </small>
        </Label>
        <Input
          type="text"
          value={pec}
          onChange={(e) => setPec(e.target.value)}
          placeholder={'ragionesociale@mailpec.it'}
        />
      </FormGroup>
    </>
  )

  const bodyPA = (
    <>
      <Form>
        <FormGroup>
          <Label>
            *Ragione sociale{' '}
            <i className="bi bi-info-circle" id="tooltipRagione-id"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.ragione}
              target="tooltipRagione-id"
              toggle={() => setTooltip({ ...tooltip, ragione: !tooltip.ragione })}
            >
              Il nome dell&apos;istituto al quale si emetteranno le fatture. Ricorda
              di non utilizzare caratteri speciali.
            </Tooltip>
          </Label>
          <Input
            type="text"
            value={nome}
            onChange={(e) => {
              if (e.target.value.length <= 80) {
                setNome(e.target.value)
              }
            }}
            invalid={latin1Invalid(nome)}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        {!checkboxPIva && (
          <FormGroup>
            <Label>
              Partita IVA{' '}
              <i className="bi bi-question-circle" id="tooltipPartitaIva-id"></i>
              <Tooltip
                placement="top"
                isOpen={tooltip.partitaIva}
                target="tooltipPartitaIva-id"
                toggle={() =>
                  setTooltip({ ...tooltip, partitaIva: !tooltip.partitaIva })
                }
              >
                La partita IVA è un numero di 11 cifre che ti dovrebbe fornire
                l&apos;istituto al quale emetti la fattura. Per la pubblica
                amministrazione non è obbligatorio quindi non inserirla se non ti è
                stata fornita.
              </Tooltip>
            </Label>
            <Input
              type="text"
              value={partitaIva}
              placeholder="16612071007"
              onChange={(e) => controlloPIVA(e.target.value)}
              valid={partitaIva.length > 0 && !errorePIva}
              invalid={partitaIva.length > 0 && errorePIva}
            />
            <FormFeedback valid>Sembra corretto</FormFeedback>
            <FormFeedback>Dovrebbe essere di 11 numeri</FormFeedback>
          </FormGroup>
        )}
        <FormGroup check className="mb-3">
          <Label check className="text-muted">
            <Input
              type="checkbox"
              id="checkboxPIva"
              checked={checkboxPIva}
              onChange={() => {
                setCheckboxPIva(!checkboxPIva)
                setPartitaIva('')
              }}
            />{' '}
            L&apos;azienda non ha una partita IVA{' '}
            <i className="bi bi-question-circle" id="tooltipNoPIva"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.noPIVA}
              target="tooltipNoPIva"
              toggle={() => setTooltip({ ...tooltip, noPIVA: !tooltip.noPIVA })}
            >
              Alcuni volte le associazioni non hanno una partita IVA, ma hanno solo
              il codice fiscale. Spesso il loro codice fiscale inizia con il numero
              9. In questo caso seleziona questa casella e lascia il campo partita
              IVA vuoto. Compila solo il campo codice fiscale
            </Tooltip>
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>
            Codice fiscale <i className="bi bi-info-circle" id="tooltipCF-id"></i>
            <Tooltip
              placement="top"
              isOpen={tooltip.CFPA}
              target="tooltipCF-id"
              toggle={() => setTooltip({ ...tooltip, CFPA: !tooltip.CFPA })}
            >
              Il codice fiscale dell&apos;istituto al quale si emetteranno le fatture
            </Tooltip>
          </Label>
          <Input
            type="text"
            value={codFiscale}
            onChange={(e) => controlloCFAzienda(e.target.value.toUpperCase().trim())}
            valid={codFiscale.length > 0 && !erroreCF}
            invalid={codFiscale.length > 0 && erroreCF}
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Non sembra corretto. Se hai ricontrollato e sei sicuro di quello che hai
            scritto continua controlli
          </FormFeedback>
        </FormGroup>
      </Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>*CAP</Label>
            <Input
              type="text"
              value={cap}
              onChange={(e) => {
                if (e.target.value.trim().length <= 5) {
                  setCap(e.target.value.trim())
                }
              }}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>*Comune</Label>
            <Input
              type="text"
              value={comune}
              onChange={(e) => {
                if (e.target.value.length < 60) {
                  setComune(e.target.value)
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Form>
        <FormGroup>
          <Label>*Indirizzo</Label>
          <Input
            type="text"
            value={indirizzo}
            onChange={(e) => {
              if (e.target.value.length < 60) {
                setIndirizzo(e.target.value)
              }
            }}
            invalid={latin1Invalid(indirizzo)}
          />
          <FormFeedback>
            Sembra che tu abbia usato dei caratteri non consentiti
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            *Provincia <small className="text-muted">(due lettere)</small>
          </Label>
          <Input
            type="text"
            value={provincia}
            placeholder="RM"
            onChange={(e) => {
              if (e.target.value.length <= 2) {
                setProvincia(e.target.value.toUpperCase())
              }
            }}
          />
        </FormGroup>
      </Form>
      <FormGroup>
        <Label>
          *Codice PA <i className="bi bi-question-circle" id="tooltipCodicePa"></i>
          <Tooltip
            placement="top"
            isOpen={tooltip.PA}
            target="tooltipCodicePa"
            toggle={() => setTooltip({ ...tooltip, PA: !tooltip.PA })}
          >
            Anche chiamato IPA o codice univoco ufficio
          </Tooltip>
        </Label>
        <Input
          type="text"
          value={codiceDestinatario}
          onChange={(e) => {
            if (e.target.value.length < 7) {
              setCodiceDestinatario(e.target.value.toUpperCase())
            }
          }}
          valid={codiceDestinatario.length > 0 && codiceDestinatario.length === 6}
          invalid={codiceDestinatario.length > 0 && codiceDestinatario.length !== 6}
          placeholder={'123456'}
        />
        <FormFeedback valid>Sembra corretto</FormFeedback>
        <FormFeedback>Il codice PA, è composto da 6 caratteri</FormFeedback>
      </FormGroup>
    </>
  )

  const footerSalvaCliente = (
    <>
      {' '}
      {loading ? (
        <>Caricamento</>
      ) : (
        <>
          <Button
            className="bottone-rosso-outline mr-3"
            onClick={() => annullaSalvaCliente()}
          >
            ANNULLA
          </Button>
          <Button
            className="bottone-blu"
            onClick={() => {
              if (schermata === 'personaFisica') {
                salvaClientePersona()
                return
              }
              if (schermata === 'aziendaPrivata') {
                salvaClienteAzienda()
                return
              }
              if (schermata === 'aziendaPubblica') {
                salvaClientePA()
              }
            }}
          >
            SALVA
          </Button>
        </>
      )}
    </>
  )

  const bodyTipologia = (
    <>
      <h6 className="font-regular font-grigio-scuro interlinea-25">
        Aggiungere un cliente o un fornitore alla tua rubrica ti permette di creare
        qualsiasi tipologia di fattura in maniera più veloce. Infatti, una volta
        inserito un cliente in rubrica, ti basterà selezionare il suo nome per
        inserire i suoi dati nella fattura in maniera automatica.
      </h6>
      <br />
      <h5 className="font-medium font-grigio-scuro">
        Chi è il tuo cliente o fornitore?
      </h5>
      <h6 className="font-regular font-grigio-scuro interlinea-25">
        Capire la tipologia di cliente o fornitore è importante per emettere le
        fatture.
        <br /> Se devi emettere una fattura nei confronti di un{' '}
        <b className="font-bold">paziente</b>, devi fare per forza una{' '}
        <b className="font-bold">fattura cartacea</b>.
        <br />
        <b className="font-bold">In tutti gli altri casi</b> (un’azienda, un MMG,
        un’associazione...), il formato che dovrai usare è quello{' '}
        <b className="font-bold">elettronico</b>.
      </h6>
      <br />
      <h6 className="font-regular font-grigio-scuro interlinea-25">
        Infine, se hai bisogno di emettere un&apos;
        <b className="font-bold">autofattura</b>, il tuo fornitore sarà
        l&apos;azienda o il privato estero da cui hai comprato un bene o servizio.
      </h6>
      <center>
        <Button
          className="bottone-blu  mt-3"
          onClick={() => setSchermata('personaFisica')}
          style={{ width: '247px' }}
        >
          <i className="bi bi-person-check"></i> PAZIENTE
        </Button>
        <br />
        <Button
          className="bottone-blu mt-3"
          style={{ width: '247px' }}
          onClick={() => setSchermata('aziendaPrivata')}
        >
          <i className="bi bi-hospital"></i> AZIENDA PRIVATA O LIBERO PROFESSIONISTA
        </Button>
        <br />
        <Button
          className="bottone-blu mt-3"
          style={{ width: '247px' }}
          onClick={() => setSchermata('aziendaPubblica')}
        >
          <i className="bi bi-bank2"></i> PUBBLICA AMMINISTRAZIONE
        </Button>
        <br />
        <br />
      </center>
      <br />
    </>
  )

  const modalCliente = (
    <Modal
      isOpen={modal}
      toggle={() => {
        setModal(false)
        setSchermata('sceltaTipologiaCliente')
      }}
      fade={modal}
    >
      <ModalHeader
        toggle={() => {
          setModal(false)
          setSchermata('sceltaTipologiaCliente')
        }}
      >
        {schermata === 'sceltaTipologiaCliente' && (
          <div className="font-medium font-grigio-scuro h4">
            Aggiungi cliente o fornitore
          </div>
        )}
        {schermata === 'personaFisica' && (
          <div className="font-medium font-grigio-scuro h4">Paziente</div>
        )}
        {schermata === 'aziendaPrivata' && (
          <div className="font-medium font-grigio-scuro h4">Azienda privata</div>
        )}
        {schermata === 'aziendaPubblica' && (
          <div className="font-medium font-grigio-scuro h4">
            Pubblica amministrazione
          </div>
        )}
      </ModalHeader>
      <ModalBody>
        {schermata === 'sceltaTipologiaCliente' && bodyTipologia}
        {schermata === 'personaFisica' && bodyPrivato}
        {schermata === 'aziendaPrivata' && bodyAzienda}
        {schermata === 'aziendaPubblica' && bodyPA}
      </ModalBody>
      {schermata !== 'sceltaTipologiaCliente' && (
        <ModalFooter>{footerSalvaCliente}</ModalFooter>
      )}
    </Modal>
  )

  return (
    <>
      {props.tipologia === 'bottone' ? (
        <Button className="bottone-bianco-blu" onClick={() => setModal(true)}>
          <i className="bi bi-plus-lg"></i> AGGIUNGI CLIENTE
        </Button>
      ) : (
        <small
          className="testo-blu pt-2"
          style={{ cursor: 'pointer' }}
          onClick={() => setModal(true)}
        >
          AGGIUNGI <i className="bi bi-plus-lg"></i>
        </small>
      )}

      {modalCliente}
    </>
  )
}

export default ModalAggiungiCliente
