import React, { useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import { Link } from 'react-router-dom'
import { getLast } from '../../../app/utility/array'

const ModalScarto = () => {
  const riepilogoContext = useContext(RiepilogoContext)
  const lastEsito = getLast(riepilogoContext.dati.fatturaSelezionata?.esito)
  return (
    <Modal
      isOpen={riepilogoContext.dati.modalScartoOpen}
      toggle={() => riepilogoContext.chiudiModalScarto()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalScarto()}
        className="h4 font-regular font-grigio-scuro"
      >
        Fattura scartata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata è stata scartata perché lo SdI ha trovato degli errori
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Entro 5 giorni dovrai inviare una fattura di rettifica, con lo stesso
          numero univoco e la stessa data di emissione di quella scartata.
          <br /> Non preoccuparti: cliccando sul tasto &ldquo;Rettifica la
          fattura&ldquo;, andrai nella schermata di creazione della fattura dove
          troverai il numero e la data giusti già impostati.
          <br />
          <br /> Ecco gli errori che hanno fatto scartare la fattura:
        </h6>
        <br />
        <Alert color="danger">{lastEsito?.suggerimento}</Alert>
        <br />
        <h6 style={{ lineHeight: '25px' }}>
          Vuoi una mano nel rettificare la fattura?{' '}
          <Link to="/chat-commercialista">Contattaci</Link> e ti aiuteremo a farlo
        </h6>
      </ModalBody>
      <ModalFooter>
        <Button
          tag={Link}
          to={
            '/fiscomed/nuova-fattura/rettifica-pr/' +
            riepilogoContext.dati.fatturaSelezionata?.fattura?.id
          }
          className="bottone-blu"
        >
          Rettifica la fattura
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalScarto
