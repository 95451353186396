import React, { useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalIncassoRiepilogo = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalIncassoOpen}
      toggle={() => riepilogoContext.chiudiModalIncasso(false)}
    >
      <ModalHeader
        className="h4 font-medium"
        toggle={() => riepilogoContext.chiudiModalIncasso(false)}
      >
        Incassa fattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Hai ricevuto il pagamento di questa fattura? Se sì, incassala per
          aggiornare la previsione delle tasse da pagare, per condividere le
          informazioni con il tuo commercialista e per tenere il conto dei compensi
          ricevuti.
        </h6>
        <br />
        <center>
          <h4>
            {riepilogoContext.dati.fatturaSelezionata?.cliente}{' '}
            {riepilogoContext.dati.fatturaSelezionata?.fattura?.totale} €
          </h4>
        </center>
        <br />
        <Form>
          <FormGroup>
            <Label>Data incasso</Label>
            <Input
              type="date"
              value={riepilogoContext.dati.fatturaSelezionata?.dataIncasso}
              onChange={(e) => riepilogoContext.aggiornaDataIncasso(e.target.value)}
            />
          </FormGroup>
        </Form>
        <br />
        <Button
          className="float-right bottone-blu"
          onClick={() => riepilogoContext.incassaFattura()}
        >
          INCASSA
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalIncassoRiepilogo
