import React, { useState, useContext, useEffect } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Button, FormGroup, Input, Col, Row } from 'reactstrap'
import swal from 'sweetalert'
import classNames from 'classnames'

const Quiz = () => {
  const context = useContext(DichiarazioneContext)
  const [domande, setDomande] = useState([])
  const [rispTestuali, setRispTestuali] = useState({
    duePerMille: '',
    cinquePerMille: '',
    ottoPerMille: '',
    coniugi: '',
  })

  const controllaEProsegui = () => {
    swal({
      title: "L'accendiamo? 📺",
      text: 'Proprio come in un quiz televisivo, è meglio avere la certezza delle risposte date prima di andare avanti. Se hai risposto con attenzione, clicca su "Vai avanti". Altrimenti, rivedi le tue risposte',
      icon: 'warning',
      buttons: ['VOGLIO RIVEDERE LE RISPOSTE', 'VAI AVANTI'],
    }).then((isConfirm) => {
      if (isConfirm) {
        context.completaStepQuiz(rispTestuali)
      }
    })
  }

  const bottoniSiNo = (numeroDomanda) => (
    <Col xs={5}>
      <div className="mb-5">
        <Button
          className={classNames(
            {
              'bottone-verde': domande.find((d) => d.numero === numeroDomanda)
                ?.risposta,
            },
            {
              'bottone-verde-outline': !domande.find(
                (d) => d.numero === numeroDomanda
              )?.risposta,
            },
            'mr-5'
          )}
          style={{ marginTop: '-8px' }}
          onClick={() => context.salvaRisposta('quiz', numeroDomanda, true)}
        >
          &nbsp;Sì&nbsp;
        </Button>
        <Button
          className={classNames(
            {
              'bottone-verde':
                !domande.find((d) => d.numero === numeroDomanda)?.risposta &&
                domande.find((d) => d.numero === numeroDomanda)?.risposta != null,
            },
            {
              'bottone-verde-outline':
                domande.find((d) => d.numero === numeroDomanda)?.risposta == null ||
                domande.find((d) => d.numero === numeroDomanda)?.risposta,
            },
            'mr-5'
          )}
          style={{ marginTop: '-8px' }}
          onClick={() => context.salvaRisposta('quiz', numeroDomanda, false)}
        >
          No
        </Button>
      </div>
    </Col>
  )

  useEffect(() => {
    const tappa = context.dati?.tappe.find((t) => t.nome === 'quiz')
    setDomande(tappa?.domande)
  }, [context.dati])

  const listaFiles = (numeroDomanda) => {
    const domanda = domande.find((d) => d.numero === numeroDomanda)
    return domanda?.files?.map((f, index) => (
      <div key={index} className="mt-2">
        <i
          className="bi bi-trash3 testo-rosso"
          style={{ cursor: 'pointer' }}
          onClick={() => context.cancellaFile('quiz', numeroDomanda, f.uuid)}
        ></i>{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => context.visualizzaFile(f.uuid)}
        >
          {f.nome}
        </span>
      </div>
    ))
  }

  return (
    <div>
      <h4 className="testo-verde font-bold mb-4">
        TERZA TAPPA: RISPONDI ALLE DOMANDE
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        In vista della dichiarazione, abbiamo bisogno di raccogliere alcune
        informazioni che saranno utilizzate {context.genere?.dalTuo} commercialista
        nella compilazione del documento. Cominciamo 🏁
      </h6>
      <Row>
        <Col xs={12}>
          <h5 className="font-bold mt-4 mb-4">Prima parte: redditi esteri</h5>
        </Col>
        <Col xs={12} className="mb-5">
          <div
            style={{
              border: '1px solid #f2421b',
              borderRadius: '15px',
              backgroundColor: '#f2431b34',
            }}
          >
            <h6
              className="font-regular interlinea-20 mx-4 my-3"
              style={{ fontSize: '12px' }}
            >
              Quando si hanno dei <span className="font-bold">redditi esteri</span>{' '}
              bisogna compilare dei quadri della dichiarazione molto complicati. Come
              specificato nella{' '}
              <a
                href="https://fiscomed.it/documenti/lettera_incarico_professionale.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>lettera di incarico</u>
              </a>
              , se ci dai i quadri compilati entro il 30 maggio li inseriamo
              gratuitamente in dichiarazione. Se preferisci che li prepariamo noi per
              te potrebbe esserci un costo extra, ma sapremo dirti di più solo dopo
              che {context.genere.ilTuo} commercialista avrà analizzato con cura le
              tue esigenze. Il tuo compito ora? Rispondere al questionario nel modo
              più preciso possibile. Se ci saranno delle procedure speciali da
              seguire, ti contatteremo noi e ti spiegheremo tutto 😊
            </h6>
          </div>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular interlinea-30">
            Hai delle <span className="font-bold">crypto</span>?
          </h6>
        </Col>
        {bottoniSiNo(0)}
        {domande.find((d) => d.numero === 0)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Se gestisci le tue crypto con delle applicazioni che ti forniscono il{' '}
                <i>quadro RW compilato</i>, ricordati di caricarlo nella sezione{' '}
                <span className="font-bold">&quot;File condivisi&quot;</span> non
                appena lo avrai (<i>solitamente è disponibile verso fine maggio</i>).
                Se, invece, sai già che non riceverai alcun quadro RW,{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 0)}
                >
                  <u>clicca qui</u>
                </span>{' '}
                per caricare un <i>prospetto</i> o un foglio con il{' '}
                <span className="font-bold">valore delle crypto</span> al giorno
                01/01/2023 fino al 31/12/2023
                <br />
                {listaFiles(0)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai uno o più <b>immobili esteri</b>?
          </h6>
        </Col>
        {bottoniSiNo(1)}
        {domande.find((d) => d.numero === 1)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai uno o più immobili esteri, abbiamo bisogno dell&apos;
                <i>atto di acquisto</i> o della <i>visura catastale</i> di ognuno.{' '}
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 1)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(1)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai <span className="font-bold">altri beni</span> registrati
            all&apos;estero? <i>(auto, imbarcazioni...)</i>
          </h6>
        </Col>
        {bottoniSiNo(2)}
        {domande.find((d) => d.numero === 2)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai uno o più beni esteri, abbiamo bisogno dell&apos;
                <i>atto di acquisto</i> o della <i>visura catastale</i> di ognuno.{' '}
                <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 2)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(2)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai <span className="font-bold">partecipazioni societarie</span> (quote)
            in società estere?
          </h6>
        </Col>
        {bottoniSiNo(3)}
        {domande.find((d) => d.numero === 3)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai delle quote in società estere, abbiamo bisogno della{' '}
                <i>visura della società</i>. <br />
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 3)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricarla
                <br />
                {listaFiles(3)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai <span className="font-bold">conti esteri</span>?
          </h6>
        </Col>
        {bottoniSiNo(4)}
        {domande.find((d) => d.numero === 4)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Nel 2023 hai avuto la giacenza media superiore a 5.000 € o hai avuto
                più di 15.000 € sul conto? <br />
                Se sì,{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 4)}
                >
                  <u>clicca qui</u>
                </span>{' '}
                per caricare l&apos;<i>estratto conto del 2023</i>
                <br />
                {listaFiles(4)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai un <span className="font-bold">deposito titoli esteri</span>?{' '}
            <i>
              (Si tratta di un conto che permette di possedere e gestire investimenti
              in titoli di società estere)
            </i>
          </h6>
        </Col>
        {bottoniSiNo(5)}
        {domande.find((d) => d.numero === 5)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai un deposito titoli esteri,{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 5)}
                >
                  <u>clicca qui</u>
                </span>{' '}
                per caricare il{' '}
                <i>
                  report dell&apos;anno 2023 rilasciato dal tuo intermediario
                  finanziario
                </i>
                <br />
                {listaFiles(5)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={12}>
          <h5 className="font-bold mt-3 mb-4">Seconda parte: immobili</h5>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai uno o più <span className="font-bold">immobili</span> intestati a te?
          </h6>
        </Col>
        {bottoniSiNo(6)}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Nel 2023 hai <span className="font-bold">affittato</span> uno o più
            immobili intestati a te?
          </h6>
        </Col>
        {bottoniSiNo(7)}
        {domande.find((d) => d.numero === 7)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai percepito dei redditi di affitto, abbiamo bisogno del{' '}
                <i>
                  contratto di affitto e della registrazione all&apos;Agenzia delle
                  Entrate
                </i>
                .{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 7)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questi documenti
                <br />
                {listaFiles(7)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai <span className="font-bold">terreni</span> o{' '}
            <span className="font-bold">fabbricati</span>?
          </h6>
        </Col>
        {bottoniSiNo(8)}
        {domande.find((d) => d.numero === 8)?.risposta && (
          <Col xs={12} className="mb-5">
            <div
              style={{
                border: '1px solid #0583f2',
                borderRadius: '15px',
              }}
            >
              <h6 className="font-regular interlinea-30 mx-4 my-3">
                Poiché hai dei terreni o fabbricati, abbiamo bisogno dell&apos;
                <i>atto di acquisto di ognuno</i>.{' '}
                <span
                  className="testo-blu"
                  style={{ cursor: 'pointer' }}
                  onClick={() => context.apriFileUpload('quiz', 5)}
                >
                  <u>Clicca qui</u>
                </span>{' '}
                per caricare questo documento
                <br />
                {listaFiles(8)}
              </h6>
            </div>
          </Col>
        )}
        <Col xs={12}>
          <h5 className="font-bold mt-3 mb-4">Terza parte: di tutto e di più</h5>
        </Col>
        <Col xs={7}>
          <h6 className="font-regular interlinea-30 mb-5">
            Hai <span className="font-bold">altri redditi</span> oltre a quelli
            derivanti dalla libera professione? Attenzione: tra questi{' '}
            <span className="font-bold">NON</span> rientra la borsa di
            specializzazione. Se hai la borsa MMG, invece, devi rispondere &quot;
            <span className="font-bold">Sì</span>&quot;.{' '}
            <i>
              (Esempio: nel 2023 hai fatto prestazioni occasionali, hai redditi da
              lavoro dipendente italiani o esteri, hai diritti d&apos;autore...)
            </i>
          </h6>
        </Col>
        {bottoniSiNo(9)}
        {domande.find((d) => d.numero === 9)?.risposta && (
          <>
            <Col xs={12} className="mb-5">
              <div
                style={{
                  border: '1px solid #0583f2',
                  borderRadius: '15px',
                }}
              >
                <h6 className="font-regular interlinea-30 mx-4 my-3">
                  Se hai altri redditi oltre alla Partita IVA e quindi, hai spese da
                  scaricare,{' '}
                  <span
                    className="testo-blu"
                    style={{ cursor: 'pointer' }}
                    onClick={() => context.apriFileUpload('quiz', 9)}
                  >
                    <u>clicca qui</u>
                  </span>{' '}
                  per caricare i documenti delle spese scaricabili. Per avere
                  un&apos;idea di ciò che puoi scaricare,{' '}
                  <a
                    href="https://www.cafacli.it/it/servizi-fiscali/modello-730/spese-detraibili-deducibili-730/"
                    target="blank"
                  >
                    <u>ecco una lista per te</u>
                  </a>
                  <br />
                  {listaFiles(9)}
                </h6>
              </div>
            </Col>
            <Col xs={12}>
              <h6 className="font-regular mb-3 interlinea-30 mb-5">
                Ora scegli a chi destinare il tuo <b>2,5 e 8x1000</b>.<br />
                Fare una scelta non è obbligatorio. Infatti, se non si specifica a
                chi destinare le proprie imposte, queste vanno nelle casse dello
                Stato comunque. Semplicemente sarà lo Stato stesso a decidere come
                investire le tue quote.
              </h6>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Il <b>2x1000</b> è il finanziamento ai partiti politici. A chi vuoi
                destinare il tuo 2x1000?
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="duePerMille"
                  placeholder="Il mio 2x1000 va al partito 'X'"
                  value={rispTestuali.duePerMille}
                  onChange={(e) =>
                    setRispTestuali({ ...rispTestuali, duePerMille: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Il <b>5x1000</b> riguarda gli enti che svolgono la loro attività in
                determinati ambiti sociali. come la ricerca scientifica o la cultura.
                A chi vuoi destinare il tuo 5x1000?
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="cinquePerMille"
                  placeholder="Il mio 5x1000 va all'ente di ricerca"
                  value={rispTestuali.cinquePerMille}
                  onChange={(e) =>
                    setRispTestuali({
                      ...rispTestuali,
                      cinquePerMille: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Infine, l&apos; <b>8x1000</b> può essere destinato a una confessione
                religiosa (
                <a
                  href="https://www.agenziaentrate.gov.it/portale/web/guest/scelte-8-5-e-2-per-mille-irpef-2023/8-per-mille"
                  target="blank"
                >
                  <u>qui</u>
                </a>{' '}
                trovi la lista completa) oppure allo Stato. In quest&apos;ultimo
                caso, puoi anche indicare uno specifico scopo di utilità sociale tra
                quelli previsti
              </h6>
            </Col>
            <Col xs={5}>
              <FormGroup className="mb-5">
                <Input
                  type="text"
                  name="ottoPerMille"
                  placeholder="Il mio 8x1000 va allo Stato"
                  value={rispTestuali.ottoPerMille}
                  onChange={(e) =>
                    setRispTestuali({
                      ...rispTestuali,
                      ottoPerMille: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          </>
        )}
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Hai un/a <span className="font-bold">coniuge</span> o dei{' '}
            <span className="font-bold">figli</span>? Se sì, scrivi i loro nomi,
            cognomi, codici fiscali e indica il grado di parentela e la percentuale
            di carico
          </h6>
        </Col>
        <Col xs={5}>
          <Input
            className="mb-5"
            type="textarea"
            rows="5"
            name="coniugi"
            value={rispTestuali.coniugi}
            onChange={(e) =>
              setRispTestuali({ ...rispTestuali, coniugi: e.target.value })
            }
          />
        </Col>
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Fra il primo gennaio 2023 e oggi hai cambiato <b>residenza</b>?
          </h6>
        </Col>
        {bottoniSiNo(14)}

        {context.tasse.anno_precedente.imposta_unica.saldo +
          context.tasse.anno_precedente.imposta_unica.acconto >
          200 && (
          <>
            <Col xs={7}>
              <h6 className="font-regular mb-5 interlinea-30">
                Sembra che tu debba pagare tra i{' '}
                {parseInt(
                  (context.tasse.anno_precedente.imposta_unica.saldo +
                    context.tasse.anno_precedente.imposta_unica.acconto) *
                    1.1
                )}
                € e i{' '}
                {parseInt(
                  (context.tasse.anno_precedente.imposta_unica.saldo +
                    context.tasse.anno_precedente.imposta_unica.acconto) *
                    0.9
                )}
                € di imposte. Vuoi pagarle in 5 rate o in un’unica soluzione? Te lo
                chiediamo perché questo influisce sulla preparazione dei tuoi F24
              </h6>
            </Col>
            <Col xs={5}>
              <div className="mb-5">
                <Button
                  className={classNames(
                    {
                      'bottone-verde': domande.find((d) => d.numero === 15)
                        ?.risposta,
                    },
                    {
                      'bottone-verde-outline': !domande.find((d) => d.numero === 15)
                        ?.risposta,
                    },
                    'mr-5'
                  )}
                  block
                  onClick={() => context.salvaRisposta('quiz', 15, true)}
                >
                  Voglio pagare in 5 rate
                </Button>
                <Button
                  className={classNames(
                    {
                      'bottone-verde': !domande.find((d) => d.numero === 15)
                        ?.risposta,
                    },
                    {
                      'bottone-verde-outline':
                        domande.find((d) => d.numero === 15)?.risposta == null ||
                        domande.find((d) => d.numero === 15)?.risposta,
                    },
                    'mr-5'
                  )}
                  block
                  onClick={() => context.salvaRisposta('quiz', 15, false)}
                >
                  Voglio pagare in un&apos;unica soluzione
                </Button>
              </div>
            </Col>
            {domande.find((d) => d.numero === 15)?.risposta && (
              <Col xs={12} className="mb-5">
                <div
                  style={{
                    border: '1px solid #0583f2',
                    borderRadius: '15px',
                  }}
                >
                  <h6 className="font-regular interlinea-30 mx-4 my-3">
                    Dato che hai scelto di pagare a rate, è giusto che tu conosca i
                    tassi di interesse.
                    <br />
                    Il tasso di interesse della seconda rata è dello 0,18%, quello
                    della terza è 0,51%, quello della quarta è 0,85% e quello della
                    quinta è 1,17%.
                  </h6>
                </div>
              </Col>
            )}
          </>
        )}
      </Row>

      <br />
      <div>
        <Button
          className="bottone-blu float-right mb-4"
          onClick={() => controllaEProsegui()}
        >
          INVIA IL QUESTIONARIO 🥳
        </Button>
      </div>
    </div>
  )
}

export default Quiz
