import React, { useState, useContext, useEffect } from 'react'
import { Card, Button, Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../../app/context/User'
import { presignedUrlPdfFatturaCartacea } from '../../../../app/utility/QueryFattura'

const FineCartacea = (props) => {
  const idFattura = props.idFattura
  const [urlFattura, setUrlFattura] = useState('')
  const { user, isLoading } = useContext(UserContext)
  const [infoFetched, setInfoFetched] = useState(false)
  const [valoreProgress, setValoreProgress] = useState(70)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        presignedUrlPdfFatturaCartacea(user, idFattura).then((urlFatturaResponse) =>
          setUrlFattura(urlFatturaResponse)
        )
      }
    }
    setValoreProgress(100)
  }, [isLoading])

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={valoreProgress} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            Se vuoi, puoi scaricare il PDF della tua fattura 📃
          </h3>
          <div className="float-right">
            <a
              className="bottone-blu text-white btn btn-secondary my-3 mr-3"
              role="button"
              rel="noreferrer"
              href={urlFattura}
              target="_blank"
            >
              <i className="bi bi-file-earmark-pdf"></i> SCARICA PDF FATTURA
            </a>
          </div>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Se adesso non hai bisogno di scaricarla, non preoccuparti. Potrai farlo
            in <span className="font-bold">qualsiasi momento</span> dalla sezione{' '}
            <Link to="/riepilogo-fatture">
              <i className="bi bi-journals"></i> Riepilogo fatture
            </Link>
            .
          </h6>

          <br />

          <Button
            className="bottone-giallo float-right"
            tag={Link}
            to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
          >
            INVIA UN&apos;ALTRA FATTURA
          </Button>
        </div>
      </Card>
    </>
  )
}

export default FineCartacea
