import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap'
import request from 'superagent'
import { UserContext } from '../../../app/context/User'
import { FiscomedContext } from '../../../app/context/Fiscomed'

const ModalAggiungiPagamento = (props) => {
  const [modal, setModal] = useState(false)
  const [datiCarta, setDatiCarta] = useState({
    nome: '',
    iban: '',
    alias: '',
  })
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const fiscoContext = useContext(FiscomedContext)

  const salvaPagamento = () => {
    if (datiCarta.nome === '' || datiCarta.iban === '') {
      alert('I campi Intestatario e Iban non possono essere vuoti')
    } else {
      setLoading(true)
      request
        .post(`${process.env.REACT_APP_API_URL}/metodo-di-pagamento/add`)
        .send({
          token: user.auth_token,
          nome: datiCarta.nome,
          iban: datiCarta.iban,
          alias: datiCarta.alias,
        })
        .then((response) => {
          fiscoContext.aggiornaMetodoDiPagamento(
            response.body.metodidipagamento,
            response.body.metodo_aggiunto
          )
          annullaSalvaPagamento()
          setLoading(false)
          if (props.funzioneDiChiusura) props.funzioneDiChiusura()
        })
        .catch((e) => console.error(e.message))
    }
  }

  const annullaSalvaPagamento = () => {
    setDatiCarta({
      nome: '',
      iban: '',
      alias: '',
    })
    setModal(false)
  }

  const modalPagamento = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-regular" toggle={() => setModal(false)}>
        Aggiungi metodo di pagamento
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Dove vuoi ricevere il pagamento? Indica il tuo nome, cognome e l&apos;IBAN
          sul quale vuoi ricevere il compenso. Dopo aver aggiunto un metodo di
          pagamento potrai utilizzarlo nella{' '}
          <span className="font-bold">compilazione delle fatture successive</span>.
          <br />
          <br />
          Per ricordare quale conto o carta stai salvando, aggiungi un&apos;etichetta
          a questo metodo di pagamento. Lo vedrai solo tu 😊
        </h6>
        <br />
        <Form>
          <FormGroup>
            <Label>Intestatario carta</Label>
            <Input
              type="text"
              value={datiCarta.nome}
              onChange={(e) => setDatiCarta({ ...datiCarta, nome: e.target.value })}
              placeholder="Il tuo nome e cognome"
            />
          </FormGroup>
          <FormGroup>
            <Label>Iban</Label>
            <Input
              type="text"
              value={datiCarta.iban}
              onChange={(e) =>
                setDatiCarta({ ...datiCarta, iban: e.target.value.toUpperCase() })
              }
              placeholder="Il tuo IBAN"
            />
          </FormGroup>
          <FormGroup>
            <Label>Etichetta</Label>
            <Input
              type="text"
              value={datiCarta.alias}
              onChange={(e) => setDatiCarta({ ...datiCarta, alias: e.target.value })}
              placeholder="Un nome che ti aiuta a riconoscere la carta"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <Button className="bottone-blu" disabled>
            <Spinner size="sm" color="light" />
          </Button>
        ) : (
          <>
            <Button
              className="bottone-rosso-outline mr-3"
              onClick={() => annullaSalvaPagamento()}
            >
              ANNULLA
            </Button>
            <Button className="bottone-blu" onClick={() => salvaPagamento()}>
              SALVA
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  )

  return (
    <>
      {props.tipologia === 'bottone' ? (
        <Button className="bottone-bianco-blu" onClick={() => setModal(true)}>
          <i className="bi bi-plus-lg"></i> AGGIUNGI PAGAMENTO
        </Button>
      ) : (
        <small
          className="testo-blu pt-2"
          style={{ cursor: 'pointer' }}
          onClick={() => setModal(true)}
        >
          AGGIUNGI <i className="bi bi-plus-lg"></i>
        </small>
      )}
      {modalPagamento}
    </>
  )
}

export default ModalAggiungiPagamento
