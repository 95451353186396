import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalEsitoPositivoPA = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalEsitoPositivoPAOpen}
      toggle={() => riepilogoContext.chiudiModalEsitoPositivoPA()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalEsitoPositivoPA()}
        className="h4 font-regular font-grigio-scuro"
      >
        Esito positivo
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata accettata. La pubblica amministrazione ha verificato la
          fattura elettronica e ha stabilito la sua correttezza 🥳
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Ora possono succedere due cose. La pubblica amministrazione può pagarti
          oppure, se nota qualcosa che non va, inviarti delle comunicazioni tramite
          email
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalEsitoPositivoPA
