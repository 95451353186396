import React, { useState, useEffect, useContext } from 'react'
import {
  Container,
  Button,
  Card,
  CardTitle,
  Progress,
  Row,
  Col,
  Tooltip,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { UserContext } from '../../../app/context/User'
import request from 'superagent'
import GraficoFatturatoMensile from '../GraficoFatturatoMensile'
import GraficoIncassatoIncassabile from '../GraficoIncassatoIncassabile'
import GraficoLimiteRicavi from '../GraficoLimiteRicavi'
import { Helmet } from 'react-helmet'

const Satistiche = () => {
  const { user, isLoading } = useContext(UserContext)
  const [infoFetched, setInfoFetched] = useState(false)
  const [tooltipTasseDaPagare, setTooltipTasseDaPagare] = useState(false)
  const [report, setReport] = useState({})
  const [annoCorrente, setAnnoCorrente] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/statistiche/report`)
      .send({ token: user.auth_token })
      .then((response) => {
        setReport(response.body.report)
      })
      .catch((e) => console.error(e.message))
  }

  const modalAliquote = () => (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
      <ModalHeader className="font-regular" toggle={() => setModalOpen(false)}>
        Perché?
      </ModalHeader>
      <ModalBody>
        L&apos;importo dei contributi versati negli anni precedenti influisce su
        tutti i calcoli dell&apos;anno corrente e anno successivo.
        <br />
        <br />
        <span className="font-bold">Per farti capire</span>: versare il 19,5% o il 2%
        va a modificare il reddito imponibile, che è la base per calcolare tasse e
        contributi. Siccome ogni anno potresti versare la Quota B con
        un&apos;aliquota diversa, diventerebbe{' '}
        <span className="font-bold">troppo complicato</span> fare una stima della
        Quota B con un&apos;aliquota diversa da quella &quot;standard&quot; (19,5%)
        <br />
        <br />
        Quindi:
        <br />
        <ul>
          <li>
            se paghi la Quota B con l&apos;aliquota del 19,5%, ti indichiamo una
            buona approssimazione degli importi
          </li>
          <li>
            se versi la Quota B con un&apos;aliquota inferiore, invece, aspettati di
            dover pagare più tasse di quelle previste, ma meno contributi di Quota B.
          </li>
        </ul>
        <br />
        <br />
        <Button
          className="bottone-blu float-right"
          style={{ backgroundColor: '#66d98e' }}
        >
          OKAY
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <Container fluid className="my-4">
      <Helmet>
        <title>Statistiche</title>
        <meta property="title" content="Statistiche" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {modalAliquote()}
      <Card>
        <CardBody>
          <div>
            <div className="float-right">
              <Button
                size={annoCorrente ? 'lg' : 'sm'}
                className={
                  annoCorrente ? 'bottone-blu mr-3' : 'bottone-blu-outline mr-3'
                }
                onClick={() => setAnnoCorrente(true)}
              >
                {new Date().getFullYear()}
              </Button>{' '}
              <Button
                size={annoCorrente ? 'sm' : 'lg'}
                className={annoCorrente ? 'bottone-blu-outline' : 'bottone-blu'}
                onClick={() => setAnnoCorrente(false)}
              >
                {new Date().getFullYear() + 1}
              </Button>
            </div>
          </div>
          <h4 className="font-medium">Statistiche e previsioni</h4>
          <h6 className="mt-2 interlinea-30 font-regular">
            In questa pagina puoi vedere nel dettaglio le statistiche e i calcoli
            previsionali più importanti. Cliccando sui bottoni in alto a destra puoi
            scegliere l&apos;anno per il quale vuoi vedere questi dati
            <br />
            Per quanto riguarda il prossimo anno, possiamo calcolarti solo le tasse
            che dovrai pagare
          </h6>
        </CardBody>
      </Card>
      <Row className="mt-4">
        <Col xs={8}>
          {annoCorrente ? (
            <Card body className="mb-4">
              <CardTitle className="h4 font-medium">
                <i className="bi bi-cash-coin"></i> Tasse e contributi da pagare nel{' '}
                {report?.anno_corrente?.anno}
              </CardTitle>
              <div>
                <Progress
                  value={
                    report?.anno_precedente?.imposta_unica?.saldo +
                    report?.anno_precedente?.imposta_unica?.acconto +
                    report?.anno_precedente?.contributi?.quota_b +
                    report?.anno_corrente?.contributi?.quota_a
                  }
                  max={25000}
                  barClassName="barra-verde"
                  className="mt-3"
                />
                <br />
                <h6 className="font-regular interlinea-30">
                  <span className="font-bold">
                    {parseInt(
                      report?.anno_precedente?.imposta_unica?.saldo +
                        report?.anno_precedente?.imposta_unica?.acconto +
                        report?.anno_precedente?.contributi?.quota_b +
                        report?.anno_corrente?.contributi?.quota_a
                    )}
                    €
                  </span>{' '}
                  è una stima approssimativa della somma che dovrai pagare. Ricorda
                  che il saldo, l&apos;acconto e la Quota B che paghi nel{' '}
                  {report?.anno_corrente?.anno} fanno riferimento ai redditi del{' '}
                  {report?.anno_corrente?.anno - 1}
                </h6>
                <br />
                <table className="table table-gialla">
                  <thead>
                    <tr>
                      <th className="h5 font-medium" style={{ width: '10%' }}></th>
                      <th className="h5 font-medium" style={{ width: '60%' }}>
                        Tasse
                      </th>
                      <th className="h5 font-medium" style={{ width: '30%' }}>
                        Importo{' '}
                        <i className="bi-info-circle-fill" id="tooltipTDP"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipTasseDaPagare}
                          target="tooltipTDP"
                          toggle={() =>
                            setTooltipTasseDaPagare(!tooltipTasseDaPagare)
                          }
                        >
                          L&apos;importo è variabile: fa riferimento all&apos;anno
                          corrente, ma l&apos;anno successivo potrebbe cambiare
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        1
                      </th>
                      <td className="h6 font-regular">Saldo imposta sostitutiva</td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_precedente?.imposta_unica?.saldo)}€
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        2
                      </th>
                      <td className="h6 font-regular">
                        Acconto imposta sostitutiva
                      </td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_precedente?.imposta_unica?.acconto)}€
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-gialla">
                  <thead>
                    <tr>
                      <th className="h5 font-medium" style={{ width: '10%' }}></th>
                      <th className="h5 font-medium" style={{ width: '60%' }}>
                        Contributi
                      </th>
                      <th className="h5 font-medium" style={{ width: '30%' }}>
                        Importo{' '}
                        <i className="bi-info-circle-fill" id="tooltipTDP"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipTasseDaPagare}
                          target="tooltipTDP"
                          toggle={() =>
                            setTooltipTasseDaPagare(!tooltipTasseDaPagare)
                          }
                        >
                          L&apos;importo è variabile: fa riferimento all&apos;anno
                          corrente, ma l&apos;anno successivo potrebbe cambiare
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        1
                      </th>
                      <td className="h6 font-regular">Quota A ENPAM</td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_corrente?.contributi?.quota_a)}€
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        2
                      </th>
                      <td className="h6 font-regular">
                        Quota B ENPAM (calcolata sul{' '}
                        <span
                          className="testo-blu"
                          onClick={() => setModalOpen(true)}
                          style={{ cursor: 'pointer' }}
                        >
                          19,5%
                        </span>
                        )
                      </td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_precedente?.contributi?.quota_b)}€
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          ) : (
            <Card body className="mb-4">
              <CardTitle className="h4 font-grigio-scuro">
                <i className="bi bi-cash-coin"></i> Tasse e contributi da pagare nel{' '}
                {report?.anno_successivo?.anno}
              </CardTitle>
              <div>
                <Progress
                  value={
                    report?.anno_corrente?.imposta_unica?.saldo +
                    report?.anno_corrente?.imposta_unica?.acconto +
                    report?.anno_corrente?.contributi?.quota_b +
                    report?.anno_successivo?.contributi?.quota_a
                  }
                  max={25000}
                  barClassName="barra-verde"
                  className="mt-3"
                />
                <br />
                <h6 className="font-regular interlinea-30">
                  <span className="font-bold">
                    {parseInt(
                      report?.anno_corrente?.imposta_unica?.saldo +
                        report?.anno_corrente?.imposta_unica?.acconto +
                        report?.anno_corrente?.contributi?.quota_b +
                        report?.anno_successivo?.contributi?.quota_a
                    )}
                    €
                  </span>{' '}
                  è una stima approssimativa della somma che dovrai pagare. Ricorda
                  che il saldo, l&apos;acconto e la Quota B che paghi nel{' '}
                  {report?.anno_successivo?.anno} fanno riferimento ai redditi del{' '}
                  {report?.anno_corrente?.anno}
                </h6>
                <br />
                <table className="table table-gialla">
                  <thead>
                    <tr>
                      <th className="h5 font-medium" style={{ width: '10%' }}></th>
                      <th className="h5 font-medium" style={{ width: '60%' }}>
                        Tasse
                      </th>
                      <th className="h5 font-medium" style={{ width: '30%' }}>
                        Importo{' '}
                        <i className="bi-info-circle-fill" id="tooltipTDP"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipTasseDaPagare}
                          target="tooltipTDP"
                          toggle={() =>
                            setTooltipTasseDaPagare(!tooltipTasseDaPagare)
                          }
                        >
                          L&apos;importo è variabile: fa riferimento all&apos;anno
                          corrente, ma l&apos;anno successivo potrebbe cambiare
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        1
                      </th>
                      <td className="h6 font-regular">Saldo imposta sostitutiva</td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_corrente?.imposta_unica?.saldo)}€
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        2
                      </th>
                      <td className="h6 font-regular">
                        Acconto imposta sostitutiva
                      </td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_corrente?.imposta_unica?.acconto)}€
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-gialla">
                  <thead>
                    <tr>
                      <th className="h5 font-medium" style={{ width: '10%' }}></th>
                      <th className="h5 font-medium" style={{ width: '60%' }}>
                        Contributi
                      </th>
                      <th className="h5 font-medium" style={{ width: '30%' }}>
                        Importo{' '}
                        <i className="bi-info-circle-fill" id="tooltipTDP"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipTasseDaPagare}
                          target="tooltipTDP"
                          toggle={() =>
                            setTooltipTasseDaPagare(!tooltipTasseDaPagare)
                          }
                        >
                          L&apos;importo è variabile: fa riferimento all&apos;anno
                          corrente, ma l&apos;anno successivo potrebbe cambiare
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        1
                      </th>
                      <td className="h6 font-regular">Quota A ENPAM</td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_successivo?.contributi?.quota_a)}€
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="h6 font-regular">
                        2
                      </th>
                      <td className="h6 font-regular">
                        Quota B ENPAM (calcolata sul{' '}
                        <span
                          className="testo-blu"
                          onClick={() => setModalOpen(true)}
                          style={{ cursor: 'pointer' }}
                        >
                          19,5%
                        </span>
                        )
                      </td>
                      <td className="h6 font-regular">
                        {parseInt(report?.anno_corrente?.contributi?.quota_b)}€
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          )}
          {annoCorrente && (
            <Card body className="mb-4">
              <CardTitle className="h4 font-medium">
                <i className="bi bi-wallet2"></i> Fatturato mensile
              </CardTitle>
              <h6 className="font-regular mb-3">
                Con questo grafico puoi vedere quanto hai fatturato ogni mese e fare
                un confronto con l&apos;anno precedente.
              </h6>
              <div style={{ height: '500px' }}>
                <GraficoFatturatoMensile
                  fatturatoAnnoCorrente={
                    report?.anno_corrente?.incasso_mensile === undefined
                      ? []
                      : Object.values(report.anno_corrente.incasso_mensile)
                  }
                  fatturatoAnnoPrecedente={
                    report?.anno_precedente?.incasso_mensile === undefined
                      ? []
                      : Object.values(report.anno_precedente.incasso_mensile)
                  }
                />
              </div>
            </Card>
          )}
        </Col>
        <Col xs={4}>
          <Card body className="mb-4">
            <CardTitle className="h4 font-medium">
              <i className="bi bi-clipboard-data"></i> Info utili per tasse e
              contributi
            </CardTitle>
            <br />
            <div>
              <h6 className="interlinea-30 font-regular">
                <span className="text-muted">Tassa annuale albo</span> 100 - 300€
              </h6>
              <h6 className="interlinea-30 font-regular">
                <span className="text-muted">Coefficiente redditività</span> 78%
              </h6>
            </div>
          </Card>
          {annoCorrente && (
            <>
              <Card body className="mb-4">
                <CardTitle className="h4 font-medium">
                  <i className="bi bi-piggy-bank"></i> Incasso{' '}
                  {new Date().getFullYear()}
                </CardTitle>
                <h6 className="font-regular interlinea-30">
                  In questo grafico puoi vedere la somma delle fatture incassate e la
                  somma delle fatture che devi ancora incassare
                </h6>
                <div style={{ maxWidth: '400px' }}>
                  <GraficoIncassatoIncassabile
                    incassato={
                      report?.anno_corrente?.fatturato
                        ?.fatturato_libera_professione +
                      report?.anno_corrente?.fatturato?.lordo_ca
                    }
                    incassabile={report?.fatture_non_incassate}
                  />
                </div>
                <h6 className="font-regular interlinea-30">
                  Hai incassato{' '}
                  {(
                    report?.anno_corrente?.fatturato?.fatturato_libera_professione +
                    report?.anno_corrente?.fatturato?.lordo_ca
                  )?.toFixed(2)}{' '}
                  € e devi ancora incassare{' '}
                  {report?.fatture_non_incassate?.toFixed(2)} €
                </h6>
              </Card>
              <GraficoLimiteRicavi report={report} />
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Satistiche
