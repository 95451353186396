import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { install } from 'resize-observer'

if (!window.ResizeObserver) install()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://317e29f9316d400a83a353a959f7898a@o1309520.ingest.sentry.io/6555810',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
)

serviceWorker.unregister()
