import React, { useState, useEffect, useContext, useMemo } from 'react'
import request from 'superagent'
import {
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  Card,
  Alert,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap'
import { UserContext } from '../../app/context/User'
import { saveAs } from 'file-saver'
import moment from 'moment'
import swal from 'sweetalert'
import { Helmet } from 'react-helmet'

const FileCondivisi = () => {
  const { user, isLoading } = useContext(UserContext)
  const [infoFetched, setInfoFetched] = useState(false)
  const [fileCondivisi, setFileCondivisi] = useState([])
  const [modalUpload, setModalUpload] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [fileDaCondividere, setFileDaCondividere] = useState(undefined)
  const [nota, setNota] = useState('')
  const [modalNota, setModalNota] = useState(false)
  const [notaDaVisualizzare, setNotaDaVisualizzare] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [genere, setGenere] = useState({
    ilTuo: 'il tuo commercialista',
    alTuo: 'al tuo commercialista',
    lui: 'lui',
  })

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
        fetchInfoCommercialista()
      }
    }
  }, [isLoading])

  const fetchInfoCommercialista = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/assistenza/idrata`)
      .send({ token: user.auth_token })
      .then((response) => {
        if (response.body.commercialista_uomo === false) {
          setGenere({
            ilTuo: 'la tua commercialista',
            alTuo: 'alla tua commercialista',
            lui: 'lei',
          })
        }
      })
      .catch((e) => console.error(e.message))
  }

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/file-condivisi/list`)
      .send({ token: user.auth_token })
      .then((response) => {
        setFileCondivisi(response.body.fileCondivisi)
      })
      .catch((e) => console.error(e.message))
  }

  const caricaFile = () => {
    if (fileDaCondividere === '') {
      alert('Seleziona un file fattura')
      return
    }
    if (fileDaCondividere.size / 1073741824 > 0.02) {
      alert('La dimensione del file non può superare i 20MB')
      return
    }
    const formData = new FormData()
    formData.append('fileCondiviso', fileDaCondividere)
    formData.append('token', user.auth_token)
    formData.append('nota', nota)
    setUploadLoading(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/file-condivisi/upload`)
      .send(formData)
      .then((response) => {
        setModalUpload(false)
        setFileCondivisi(response.body.fileCondivisi)
        setUploadLoading(false)
      })
      .catch((e) => {
        setModalUpload(false)
        setUploadLoading(false)
        console.error(e.message)
      })
  }

  const scaricaFile = (uuidFile, nomeOriginale) => {
    request
      .post(`${process.env.REACT_APP_API_URL}/file-condivisi/download`)
      .send({ token: user.auth_token, uuidFile })
      .then((response) => {
        fetch(response.body.url)
          .then((r) => r.blob())
          .then((blobFile) => {
            const file = new File([blobFile], nomeOriginale)
            saveAs(file)
          })
      })
      .catch((e) => console.error(e.message))
  }

  const cancellaFile = (uuidFile, nomeFile) => {
    swal({
      title: 'Vuoi cancellare definitivamente il file ' + nomeFile + '?',
      icon: 'warning',
      button: 'SÌ, CANCELLA',
    }).then((isConfirm) => {
      if (isConfirm) {
        request
          .post(`${process.env.REACT_APP_API_URL}/file-condivisi/delete`)
          .send({ token: user.auth_token, uuidFile })
          .then((response) => {
            setFileCondivisi(response.body.fileCondivisi)
          })
          .catch((e) => console.error(e.message))
      }
    })
  }

  const visualizzaNote = (nota) => {
    setNotaDaVisualizzare(nota)
    setModalNota(true)
  }

  const filteredFiles = useMemo(
    () =>
      fileCondivisi.filter((file) =>
        file.nome_file?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [fileCondivisi, searchTerm]
  )

  const componentFile = filteredFiles.map((fileCondiviso, index) => (
    <tr key={index}>
      <td>
        <span
          className="black-link-with-hover"
          onClick={() =>
            scaricaFile(fileCondiviso.uuid_file, fileCondiviso.nome_file)
          }
        >
          {fileCondiviso.nome_file}
        </span>
      </td>
      <td>
        <span
          className="font-regular"
          onClick={() => visualizzaNote(fileCondiviso.nota)}
          style={{ cursor: 'pointer' }}
        >
          {fileCondiviso.nota ? (
            fileCondiviso.nota.length > 28 ? (
              <span className="text-info">
                Leggi la descrizione <i className="bi bi-book pl-2" />
              </span>
            ) : (
              <span>{fileCondiviso.nota}</span>
            )
          ) : (
            '-'
          )}
        </span>
      </td>
      <td>{moment(fileCondiviso.created_at).format('DD/MM/YY')}</td>
      <td>{fileCondiviso.role === 'utente' ? 'Te' : 'Commercialista'}</td>
      <td>
        {fileCondiviso.role === 'utente' ? (
          <span
            onClick={() =>
              cancellaFile(fileCondiviso.uuid_file, fileCondiviso.nome_file)
            }
            className="text-danger"
            style={{ cursor: 'pointer' }}
          >
            <i className="bi bi-trash"></i>
          </span>
        ) : (
          '-'
        )}
      </td>
    </tr>
  ))

  const componentModalNota = (
    <Modal isOpen={modalNota} toggle={() => setModalNota(false)}>
      <ModalHeader className="font-regular" toggle={() => setModalNota(false)}>
        Nota
      </ModalHeader>
      <ModalBody>
        {notaDaVisualizzare === '' ? (
          'Non è stata aggiunta una nota per questo file'
        ) : (
          <h6 className="font-regular interlinea-25">{notaDaVisualizzare}</h6>
        )}
        <br />
      </ModalBody>
    </Modal>
  )

  const componentModalUpload = (
    <Modal isOpen={modalUpload} toggle={() => setModalUpload(false)}>
      <ModalHeader toggle={() => setModalUpload(false)} className="font-regular">
        Carica file
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="fileUpload">
            Carica un file da condividere con {genere.ilTuo}
          </Label>
          <br />
          <br />
          <Input
            type="file"
            name="fileUpload"
            id="fileUpload"
            onChange={(e) => setFileDaCondividere(e.target.files[0])}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="nota" className="font-regular">
            Aggiungi una nota per spiegare {genere.alTuo} che tipo di file stai
            caricando
          </Label>
          <Input
            type="text"
            name="nota"
            id="nota"
            placeholder="Questo è la ricevuta dell'f24 del 06/2022"
            value={nota}
            onChange={(e) => setNota(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      {uploadLoading ? (
        <ModalFooter>
          <Button color="secondary" disabled>
            Caricamento...
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button className="bottone-blu" onClick={() => caricaFile()}>
            <i className="bi bi-cloud-arrow-up"></i> CARICA
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )

  return (
    <Container className="my-4">
      <Helmet>
        <title>File condivisi</title>
        <meta property="title" content="File condivisi" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {componentModalNota}
      {componentModalUpload}
      <Row className="d-flex h-100">
        <Col xs={4} className="d-flex align-items-stretch">
          <Card
            body
            className="d-flex align-items-center justify-content-center h-100"
          >
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="img/filecondivisi.png"
                className="img-fluid"
                style={{ maxHeight: '250px', objectFit: 'contain' }}
                alt="File Condivisi"
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} className="d-flex flex-column">
          <Card body className="flex-grow-1 d-flex flex-column h-100">
            <h4 className="font-medium mt-2">
              <i className="bi bi-cloud"></i> File condivisi
              <div className="d-inline">
                <Button
                  className="bottone-blu float-right"
                  onClick={() => setModalUpload(true)}
                >
                  <i className="bi bi-cloud-arrow-up"></i> CARICA FILE
                </Button>
              </div>
            </h4>
            <h6 className="mt-3 font-regular interlinea-30 flex-grow-1">
              Qui puoi caricare tutti i documenti che vuoi condividere con{' '}
              {genere.ilTuo} e consultare quelli che {genere.lui} ha condiviso con
              te. Se li hai, ti consigliamo di caricare: la certificazione dei
              contributi ENPAM dello scorso anno, il PDF del tuo documento di
              identità e del codice fiscale. Se la tua residenza è diversa da quella
              indicata nel documento, scrivilo nella descrizione.
            </h6>
          </Card>
        </Col>
      </Row>

      <Card className="mt-4 p-2">
        <InputGroup>
          <Input
            className="shadow-none"
            type="text"
            placeholder="Cerca un documento"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ border: 'none' }}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText className="bg-white border-0 d-flex align-items-center h-100">
              <i className="bi bi-search"></i>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Card>
      {fileCondivisi.length === 0 ? (
        <Alert color="info" className="mt-4">
          <i className="bi bi-info-circle"></i> Non hai ancora condiviso nessun file
          con {genere.ilTuo}
        </Alert>
      ) : (
        <Card className="px-4 mt-3">
          <Table className="table-bianca">
            <thead>
              <tr>
                <th className="h6 font-regular">Documento</th>
                <th className="h6 font-regular">Descrizione</th>
                <th className="h6 font-regular">Data</th>
                <th className="h6 font-regular">Condiviso da</th>
                <th className="h6 font-regular"></th>
              </tr>
            </thead>
            <tbody>{componentFile}</tbody>
          </Table>
        </Card>
      )}
    </Container>
  )
}

export default FileCondivisi
