import React, { useState, useContext } from 'react'
import { Input, Button, Container, Row, Col } from 'reactstrap'
import request from 'superagent'
import swal from 'sweetalert'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from '../../app/context/User'
import { Helmet } from 'react-helmet'

const CambioPassword = () => {
  const { isLoggedIn } = useContext(UserContext)
  const [recoverEmail, setRecoverEmail] = useState('')
  const [loading, setLoad] = useState(false)
  const history = useHistory()

  const inviaCambioPassword = () => {
    setLoad(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/request-change-password`)
      .send({ email: recoverEmail })
      .end((err, res) => {
        if (err) {
          alert('Errore generico')
        } else {
          if (res.body.error) {
            alert(res.body.message_error)
            console.log(err)
          } else {
            swal({
              title: 'Email inviata',
              text: 'Segui le istruzioni che ti abbiamo inviato per email per completare il cambio della tua password',
              icon: 'success',
              button: 'OK',
            }).then(() => {
              isLoggedIn() ? history.push('/fiscomed') : history.push('/accesso')
            })
          }
        }
        setLoad(false)
      })
  }

  return (
    <>
      <Helmet>
        <title>Cambio password</title>
        <meta property="title" content="Cambio password" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/cambio-password" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cambio password" />
        <meta
          property="og:description"
          content="Pagina per reimpostare la propria password"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
      </Helmet>
      {isLoggedIn() ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: '-3',
            background:
              'linear-gradient(to right,white 0%,white 50%,#66d98e26 50%,#66d98e26 100%)',
          }}
        >
          <Container style={{ marginTop: '56px' }} fluid>
            <Row style={{ marginTop: '20vh' }}>
              <Col md={6}>
                <Row>
                  <Col md={3}></Col>
                  <Col md={9} className="pr-5">
                    {isLoggedIn() === false && (
                      <Link to="/accesso">
                        <i className="bi bi-arrow-left" /> Torna al login
                      </Link>
                    )}
                    <br />
                    <br />
                    <span className="titolo-3 mb-5">
                      Hai dimenticato la tua password?
                    </span>
                    <br />
                    <br />
                    <h5 className="testo-landing-fiscomed">
                      Non preoccuparti, ti invieremo una mail con un link che puoi
                      utilizzare per <b>cambiare la tua password</b> in modo sicuro
                    </h5>
                    <br />
                    <h6 className="font-medium mb-3">Indirizzo email</h6>
                    <Input
                      type="email"
                      value={recoverEmail}
                      placeholder="ippocrate@email.it"
                      onChange={(e) => setRecoverEmail(e.target.value)}
                      style={{ maxWidth: '400px' }}
                      autoComplete="email"
                    />
                    <br />
                    <br />
                    {loading ? (
                      ''
                    ) : (
                      <Button
                        className="bottone-blu px-5"
                        onClick={() => inviaCambioPassword()}
                        style={{ paddingTop: '14px', paddingBottom: '14px' }}
                      >
                        AVANTI
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <center>
                  <img
                    src="/img_landing/immagine_fiscomed.webp"
                    alt="Mano che saluta"
                    style={{ width: '500px' }}
                  />
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100%',
            zIndex: '1001',
            background: 'white',
          }}
        >
          <div
            style={{
              position: 'absolute',
              minWidth: '100%',
              minHeight: '100%',
              zIndex: '1002',
              background:
                'linear-gradient(to right,white 0%,white 50%,#baffd2 50%,#baffd2 100%)',
            }}
          >
            <Container style={{ marginTop: '56px' }} fluid>
              <Row style={{ marginTop: '25vh' }}>
                <Col md={6}>
                  <Row>
                    <Col md={3}></Col>
                    <Col md={9} className="pr-5">
                      {isLoggedIn() === false && (
                        <Link to="/accesso">
                          <i className="bi bi-arrow-left" /> Torna al login
                        </Link>
                      )}
                      <br />
                      <br />
                      <span className="titolo-3 mb-5">
                        Hai dimenticato la tua password?
                      </span>
                      <br />
                      <br />
                      <h5 className="testo-landing-fiscomed">
                        Non preoccuparti, ti invieremo una mail con un link che puoi
                        utilizzare per <b>cambiare la tua password</b> in modo sicuro
                      </h5>
                      <br />
                      <h6 className="font-medium mb-3">Indirizzo email</h6>
                      <Input
                        type="email"
                        value={recoverEmail}
                        placeholder="ippocrate@email.it"
                        onChange={(e) => setRecoverEmail(e.target.value)}
                        style={{ maxWidth: '400px' }}
                        autoComplete="email"
                      />
                      <br />
                      <br />
                      {loading ? (
                        ''
                      ) : (
                        <>
                          <Button
                            className="bottone-blu px-5"
                            onClick={() => inviaCambioPassword()}
                            style={{ paddingTop: '14px', paddingBottom: '14px' }}
                          >
                            AVANTI
                          </Button>
                          <br />
                          <br />
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <center>
                    <img
                      src="/img_landing/immagine_fiscomed.webp"
                      alt="Mano che saluta"
                      style={{ width: '500px' }}
                    />
                  </center>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  )
}

export default CambioPassword
