import React, { useContext } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Row, Col, Button, Card } from 'reactstrap'

const Start = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Row>
        <Col xs={5}>
          <center>
            <img
              src="/img/enpam/start.webp"
              className="mt-5"
              style={{ width: '90%' }}
            />
          </center>
        </Col>
        <Col xs={7}>
          <br />
          <h1 className="font-bold mt-5">Contribuzione Ridotta e Modello D</h1>
          <h6 className="font-regular mb-3 mt-4 interlinea-30">
            Se vedi questa schermata è perché devi compilare il Modello D ENPAM.
            Questo modello serve a dichiarare il tuo reddito imponibile affinché
            l&apos;ENPAM possa generare la Quota B che devi pagare.
            <br />
            <br />
            Prima di guidarti nella compilazione del Modello D, però, abbiamo bisogno
            che tu risponda a delle domande per capire se puoi (e vuoi) richiedere
            l&apos;aliquota dimezzata o ridotta e versare meno contributi 😊
          </h6>
          <br />
          <br />
        </Col>
      </Row>
      <div className="mt-auto mb-3">
        <Button
          className="bottone-blu float-right"
          onClick={() => context.setScheda('primadomanda')}
        >
          COMINCIAMO
        </Button>
      </div>
    </Card>
  )
}

export default Start
