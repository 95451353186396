import React from 'react'
import { UserContext } from './User'
import {
  idrataDichiarazione,
  chiudiStepFatture,
  chiudiStepContributi,
  chiudiStepQuiz,
  scaricaF24DaServer,
  queryIdrataArchivio,
  scaricaModelloDaServer,
  inviaFile,
  scaricaFileAllegatoUtente,
  eliminaFile,
  salvaRisp,
} from '../utility/QueryDichiarazione'
import swal from 'sweetalert'

const DichiarazioneContext = React.createContext()

const genereMaschile = {
  il: 'il',
  ilTuo: 'il tuo',
  lui: 'lui',
  ilDottore: 'il Dottore',
  dalTuo: 'dal tuo',
  luiStesso: 'lui stesso',
  alTuo: 'al tuo',
}

const genereFemminile = {
  il: 'la',
  ilTuo: 'la tua',
  lui: 'lei',
  ilDottore: 'la Dottoressa',
  dalTuo: 'dalla tua',
  luiStesso: 'lei stessa',
  alTuo: 'alla tua',
}

const emptyState = {
  attivo: false,
  caricamento: true,
  archivio: [],
  genere: genereMaschile,
  tasse: {},
  dati: undefined,
  delega: false,
  modalFileUpload: {
    aperto: false,
    caricamento: false,
    file: undefined,
    tappa: undefined,
    domanda: undefined,
  },
  modalViewFile: {
    aperto: false,
    url: undefined,
  },
}

class DichiarazioneProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  componentDidMount() {
    this.idrata()
    if (this.context.user.commercilista_uomo === false) {
      this.setState({
        ...this.state,
        genere: genereFemminile,
      })
    }
  }

  aggiornaDatiDichiarazioneEStep = (dichiarazione) => {
    this.setState({
      ...this.state,
      ...dichiarazione,
      caricamento: false,
    })
  }

  idrata = () =>
    idrataDichiarazione(this.context.user).then((responseBody) => {
      this.setState({
        ...this.state,
        ...responseBody.dichiarazione,
        tasse: responseBody.tasse,
        delega: responseBody.delega,
        caricamento: false,
      })
    })

  completaStepFatture = (caricatiEIncassati) => {
    chiudiStepFatture(this.context.user, caricatiEIncassati).then((responseBody) => {
      this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  completaStepContributi = (contributiVersati) => {
    chiudiStepContributi(this.context.user, contributiVersati).then(
      (responseBody) => {
        this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
      }
    )
  }

  completaStepQuiz = (risposte) => {
    chiudiStepQuiz(this.context.user, risposte).then((responseBody) => {
      this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  scaricaF24 = (idF24, nomeFile) => {
    scaricaF24DaServer(this.context.user, idF24, nomeFile)
  }

  scaricaModelloRedditi = (idModelloRedditi) => {
    scaricaModelloDaServer(this.context.user, idModelloRedditi)
  }

  idrataArchivio = () =>
    queryIdrataArchivio(this.context.user).then((archivio) =>
      this.setState({ ...this.state, archivio })
    )

  apriFileUpload = (tappa, domanda) => {
    this.setState({
      ...this.state,
      modalFileUpload: {
        aperto: true,
        caricamento: false,
        file: undefined,
        tappa,
        domanda,
      },
    })
  }

  chiudiFileUpload = () => {
    this.setState({
      ...this.state,
      modalFileUpload: {
        aperto: false,
        caricamento: false,
        file: undefined,
        tappa: undefined,
        domanda: undefined,
      },
    })
  }

  impostaFile = (file) => {
    this.setState({
      ...this.state,
      modalFileUpload: {
        ...this.state.modalFileUpload,
        file,
      },
    })
  }

  uploadFile = () => {
    this.setState({
      ...this.state,
      modalFileUpload: {
        ...this.state.modalFileUpload,
        caricamento: true,
      },
    })
    inviaFile(this.context.user, this.state.modalFileUpload).then((responseBody) => {
      this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
      this.setState({
        ...this.state,
        modalFileUpload: {
          aperto: false,
          caricamento: false,
          file: undefined,
          tappa: undefined,
          domanda: undefined,
        },
      })
    })
  }

  visualizzaFile = (uuid) => {
    this.setState({
      ...this.state,
      modalViewFile: {
        aperto: true,
        url: undefined,
      },
    })
    scaricaFileAllegatoUtente(this.context.user, uuid).then((response) => {
      this.setState({
        ...this.state,
        modalViewFile: {
          aperto: true,
          url: response.url,
        },
      })
    })
  }

  chiudiModalViewFile = () => {
    this.setState({
      ...this.state,
      modalViewFile: {
        aperto: false,
        url: undefined,
      },
    })
  }

  cancellaFile = (tappa, domanda, uuid) => {
    swal({
      title: 'Vuoi eliminare questo documento?',
      icon: 'warning',
      buttons: ['NO, CHIUDI', 'SÌ, CANCELLA'],
    }).then((isConfirm) => {
      if (isConfirm) {
        eliminaFile(this.context.user, tappa, domanda, uuid).then((responseBody) => {
          this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
        })
      }
    })
  }

  salvaRisposta = (tappa, domanda, risposta) => {
    salvaRisp(this.context.user, tappa, domanda, risposta).then((responseBody) => {
      this.aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  render() {
    const { children } = this.props

    const context = {
      ...this.state,
      completaStepFatture: this.completaStepFatture,
      completaStepContributi: this.completaStepContributi,
      completaStepQuiz: this.completaStepQuiz,
      scaricaF24: this.scaricaF24,
      idrataArchivio: this.idrataArchivio,
      scaricaModelloRedditi: this.scaricaModelloRedditi,
      apriFileUpload: this.apriFileUpload,
      chiudiFileUpload: this.chiudiFileUpload,
      impostaFile: this.impostaFile,
      uploadFile: this.uploadFile,
      visualizzaFile: this.visualizzaFile,
      chiudiModalViewFile: this.chiudiModalViewFile,
      cancellaFile: this.cancellaFile,
      salvaRisposta: this.salvaRisposta,
    }

    return (
      <DichiarazioneContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </DichiarazioneContext.Provider>
    )
  }
}

export { DichiarazioneProvider as default, DichiarazioneContext }
