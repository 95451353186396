import React, { useContext, useState } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from 'reactstrap'
import classNames from 'classnames'

const Ordine = () => {
  const fiscoContext = useContext(FiscomedContext)
  const [modal, setModal] = useState(false)

  const aggiornaData = (data) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      data,
    })
  }

  const aggiornaIdOrdine = (idDocumento) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      idDocumento,
    })
  }

  const aggiornaCUP = (codiceCUP) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      codiceCUP,
    })
  }

  const aggiornaCIG = (codiceCIG) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      codiceCIG,
    })
  }

  const aggiornaCodiceCommessa = (codiceCommessa) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      codiceCommessa,
    })
  }

  const cancellaDati = () => {
    fiscoContext.aggiornaDatiAggiuntivi({
      data: '',
      idDocumento: '',
      codiceCUP: '',
      codiceCIG: '',
      codiceCommessa: '',
    })
    setModal(false)
  }

  const modalOrdine = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Aggiungi dati dell&apos;ordine alla fattura
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="font-regular">Identificativo dell&apos;ordine</Label>
          <Input
            type="text"
            onChange={(e) => aggiornaIdOrdine(e.target.value)}
            placeholder="DG2738112"
            value={fiscoContext.dati.datiAggiuntivi.idDocumento}
            invalid={
              fiscoContext.dati.datiAggiuntivi.idDocumento.length > 20 &&
              fiscoContext.dati.datiAggiuntivi.idDocumento.length !== 0
            }
            valid={
              fiscoContext.dati.datiAggiuntivi.idDocumento.length > 0 &&
              fiscoContext.dati.datiAggiuntivi.idDocumento.length <= 20
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo ha caratteri non ammessi oppure è più lungo di 20 caratteri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">Data dell&apos;ordine</Label>
          <Input
            type="date"
            onChange={(e) => aggiornaData(e.target.value)}
            placeholder="01/01/2024"
            disabled={fiscoContext.dati.datiAggiuntivi.idDocumento === ''}
            value={fiscoContext.dati.datiAggiuntivi.data}
            invalid={
              fiscoContext.dati.datiAggiuntivi.data !== '' &&
              fiscoContext.dati.datiAggiuntivi.idDocumento === ''
            }
          />
          <FormFeedback>
            Non puoi inserire questo campo se non compili l&apos;identificativo
            dell&apos;ordine. Sei sicuro di aver ricevuto il documento &quot;Ordine
            di acquisto&quot; dal tuo cliente? Se non hai questo documento non
            compilare nessuno di questi campi
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">
            CUP <span className="text-muted">(Codice Unico di Progetto)</span>
          </Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCUP(e.target.value)}
            value={fiscoContext.dati.datiAggiuntivi.codiceCUP}
            disabled={fiscoContext.dati.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscoContext.dati.datiAggiuntivi.codiceCUP.length > 10 &&
              fiscoContext.dati.datiAggiuntivi.codiceCUP.length !== 0
            }
            valid={
              fiscoContext.dati.datiAggiuntivi.codiceCUP.length <= 10 &&
              fiscoContext.dati.datiAggiuntivi.codiceCUP.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 10 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">
            CIG <span className="text-muted">(Codice Identificativo di Gara)</span>
          </Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCIG(e.target.value)}
            value={fiscoContext.dati.datiAggiuntivi.codiceCIG}
            disabled={fiscoContext.dati.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscoContext.dati.datiAggiuntivi.codiceCIG.length > 15 &&
              fiscoContext.dati.datiAggiuntivi.codiceCIG.length !== 0
            }
            valid={
              fiscoContext.dati.datiAggiuntivi.codiceCIG.length <= 15 &&
              fiscoContext.dati.datiAggiuntivi.codiceCIG.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 15 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">Codice commessa o convenzione</Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCodiceCommessa(e.target.value)}
            value={fiscoContext.dati.datiAggiuntivi.codiceCommessa}
            disabled={fiscoContext.dati.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscoContext.dati.datiAggiuntivi.codiceCommessa.length > 100 &&
              fiscoContext.dati.datiAggiuntivi.codiceCommessa.length !== 0
            }
            valid={
              fiscoContext.dati.datiAggiuntivi.codiceCommessa.length <= 100 &&
              fiscoContext.dati.datiAggiuntivi.codiceCommessa.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 15 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline mr-3"
          onClick={() => cancellaDati()}
        >
          CANCELLA DATI
        </Button>
        <Button
          className={classNames({
            'bottone-blu':
              fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' ||
              fiscoContext.dati.tipologiaFattura === 'Rettifica pr',
            'bottone-verde':
              fiscoContext.dati.tipologiaFattura === 'Fattura cartacea',
            'bottone-giallo': fiscoContext.dati.tipologiaFattura === 'Fattura PA',
            'bottone-bianco-blu-outline':
              fiscoContext.dati.tipologiaFattura === 'Proforma',
          })}
          onClick={() => setModal(false)}
        >
          AGGIUNGI DATI
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <div className="mb-3">
      <Button
        onClick={() => setModal(true)}
        className={classNames({
          'bottone-blu':
            fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' ||
            fiscoContext.dati.tipologiaFattura === 'Rettifica pr',
          'bottone-verde': fiscoContext.dati.tipologiaFattura === 'Fattura cartacea',
          'bottone-giallo': fiscoContext.dati.tipologiaFattura === 'Fattura PA',
          'bottone-bianco-blu-outline':
            fiscoContext.dati.tipologiaFattura === 'Proforma',
        })}
      >
        AGGIUNGI DATI ORDINE&nbsp;
      </Button>
      {modalOrdine}
    </div>
  )
}

export default Ordine
