import React, { useState } from 'react'
import {
  Container,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import swal from '@sweetalert/with-react'
import { leadChiamataGA } from '../../../app/utility/GA'
import { useHistory } from 'react-router-dom'
import { lead } from '../../../app/utility/SwalContent'

const DoctorsaDesktop = () => {
  const [caricamento, setCaricamento] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const history = useHistory()
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if ((nome === '' || email === '' || telefono === '', consenso === false)) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }, 'doctorsa').then(
      (res) => {
        setCaricamento(false)
        swal(lead).then(() => {
          history.push('/')
        })
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Fiscomed x Doctorsa</title>
        <meta property="title" content="Fiscomed x Doctorsa" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/doctorsa" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed x Doctorsa" />
        <meta
          property="og:description"
          content="Apri la partita IVA con Fiscomed per iniziare a lavorare con Doctorsa"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <img
          src="/img_landing/logo_doctorsa.webp"
          alt="Logo Doctorsa"
          style={{ width: '250px' }}
          className="mb-5"
        />
        <h5 className="testo-landing-fiscomed">
          Per lavorare in libera professione, i giovani medici come te devono avere
          la Partita IVA. E sì, lo sappiamo: <b>aprire e gestire la Partita IVA</b>{' '}
          può sembrare un po&apos; complicato, specialmente per chi è agli inizi
          della propria attività come te. Ma non preoccuparti,{' '}
          <b>possiamo aiutarti</b>.
          <br />
          <br />
          <b>Doctorsa collabora con Fiscomed</b> per offrirti tutto il supporto di
          cui hai bisogno per gestire con serenità la tua Partita IVA. Grazie a
          Fiscomed potrai affidarti a un Dottore Commercialista o una Dottoressa
          Commercialista specializzata nel settore medico, ma non solo. Avrai anche
          accesso a una piattaforma semplice e intuitiva da cui tenere d’occhio la
          tua situazione fiscale ed emettere le fatture senza problemi.
          <br />
          <br />
          Se vuoi saperne di più o desideri parlare con un membro del team di
          Fiscomed, <b>lascia qui i tuoi contatti</b>. Saranno felici di rispondere a
          tutte le tue domande sul mondo fiscale e sul servizio offerto.
        </h5>
        <br />
        <br />
        <Row>
          <Col xs="6">
            <h5 className="font-medium mb-3">Come ti chiami?</h5>
            <Input
              type="text"
              placeholder="Ippocrate di Coo"
              autoComplete="name"
              onChange={(event) => setNome(event.target.value)}
              valuer={nome}
            />
            <br />
            <br />
            <h5 className="font-medium mb-3">Qual è la tua email?</h5>
            <Input
              type="text"
              placeholder="ippocrate@email.com"
              autoComplete="email"
              onChange={(event) => setEmail(event.target.value)}
              valuer={email}
            />
            <br />
            <br />
            <h5 className="font-medium mb-3">A quale numero possiamo chiamarti?</h5>
            <Input
              type="text"
              placeholder="3399957060"
              autoComplete="tel"
              onChange={(event) => setTelefono(event.target.value)}
              valuer={telefono}
            />
            <br />
          </Col>
          <Col xs="6">
            <h5 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h5>
            <Input
              type="textarea"
              placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
              rows="6"
              onChange={(event) => setMessage(event.target.value)}
              valuer={message}
            />
            <br />
            <FormGroup check>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '5px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            {caricamento ? (
              <Button className="bottone-verde px-5 mt-4 float-right" disabled>
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-verde px-5 mt-4 float-right"
                onClick={() => prenotaChiamata()}
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
              >
                RICHIEDI UNA CONSULENZA
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <br />
      </Container>
    </>
  )
}

export default DoctorsaDesktop
