import React, { useState, useContext } from 'react'
import { Card, Button, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { UserContext } from '../../app/context/User'
import { Link } from 'react-router-dom'

const CardCodiceCollega = () => {
  const { user } = useContext(UserContext)
  const [copied, setCopied] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.codice_collega)
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  const modal = () => (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
      <ModalHeader className="font-regular" toggle={() => setModalOpen(false)}>
        Condividi il tuo codice
      </ModalHeader>
      <ModalBody>
        Diffondi il tuo codice collega e guadagna 37 € per ogni persona che porti.
        Per lei 27 € di sconto subito, al momento dell&apos;acquisto.
        <br />
        <br />
        Puoi condividerlo dove vuoi: Instagram, Facebook, Telegram e così via. Noi ti
        abbiamo messo il tasto per diffondere il codice attraverso WhatsApp perché
        probabilmente è l&apos;applicazione che usi di più 😊
        <br />
        <br />
        <a
          href={
            'https://web.whatsapp.com/send?text=Ciao 😊 Inserisci il codice ' +
            user.codice_collega +
            ' per avere uno sconto di 27 € sul tuo abbonamento a Fiscomed'
          }
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="bottone-blu float-right"
            style={{ backgroundColor: '#66d98e' }}
          >
            <i className="bi bi-whatsapp"></i> Whatsapp
          </Button>
        </a>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {modal()}
      <Card body className="mb-4" style={{ border: 'solid 2px #f2b705' }}>
        <Row>
          <Col xs={7}>
            <h6 className="font-regular interlinea-30 mt-3">
              Il tuo codice collega è:
            </h6>
          </Col>
          <Col xs={5}>
            <h6 className="font-regular interlinea-30 float-right mt-3">Per te</h6>
          </Col>
          <Col xs={7}>
            <span className="h3 font-bold interlinea-30 testo-verde">
              {user.codice_collega}
            </span>{' '}
            <span
              onClick={() => copyToClipboard()}
              style={{ top: '-3px', position: 'relative', cursor: 'pointer' }}
            >
              {copied ? (
                <span>
                  <small>
                    <i className="bi bi-check testo-verde"></i> copiato
                  </small>
                </span>
              ) : (
                <i className="bi bi-files testo-blu"></i>
              )}
            </span>
          </Col>
          <Col xs={5}>
            <h3 className="font-bold interlinea-30 float-right">37,00 €</h3>
          </Col>
        </Row>
        <div>
          <h6 className="font-regular interlinea-30 float-right mt-2">
            Chi inviti riceverà 27,00 € di sconto
          </h6>
        </div>
        <Button
          className="bottone-blu mt-3"
          size="lg"
          onClick={() => setModalOpen(true)}
        >
          CONDIVIDI
        </Button>
        <Link to="/codice-collega" className="mt-4 interlinea-30 ">
          Che cos&apos;è il codice collega?
        </Link>
        <Link to="/abbonamento" className="interlinea-30 mb-3">
          Scopri il credito che hai accumulato
        </Link>
      </Card>
    </>
  )
}

export default CardCodiceCollega
