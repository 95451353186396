import request from 'superagent'
import { saveAs } from 'file-saver'

// Sincronizza fatture
export const idrataFatture = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/list`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const caricaFattura = (formData) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/upload`)
    .send(formData)
    .then((response) => response.body)
    .catch((e) => {
      console.error(e.message)
    })

export const cancellaFattura = (user, uuidFile, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/delete`)
    .send({ token: user.auth_token, uuidFile, anno })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const inviaModificaFattura = (
  user,
  uuidFile,
  dataIncasso,
  numeroFattura,
  importo,
  anno,
  tipologia,
  trattenutaEnpam,
  trasmessaTS
) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/update`)
    .send({
      token: user.auth_token,
      uuidFile,
      dataIncasso,
      numeroFattura,
      importo,
      anno,
      tipologia,
      trattenutaEnpam,
      trasmessaTS,
    })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const scaricaFattura = (user, uuidFile, nomeOriginale) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/sezionale-blocchetto/download`)
    .send({ token: user.auth_token, uuidFile })
    .then((response) => {
      fetch(response.body.url)
        .then((r) => r.blob())
        .then((blobFile) => {
          const file = new File([blobFile], nomeOriginale)
          saveAs(file)
        })
    })
    .catch((e) => console.error(e.message))
