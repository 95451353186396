import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { FormFeedback, FormGroup, Input } from 'reactstrap'

const MarcaDaBollo = () => {
  const fiscoContext = useContext(FiscomedContext)

  const setNumeroMarca = (valore) =>
    fiscoContext.aggiornaIdentificativoMarcaDaBollo(valore)

  return (
    <FormGroup className="mt-2 mb-3">
      <Input
        type="text"
        onChange={(e) => setNumeroMarca(e.target.value)}
        value={fiscoContext.dati.numeroMarca}
        placeholder="Identificativo marca da bollo"
        valid={
          fiscoContext.dati.identificativoMarcaDaBollo.length > 0 &&
          fiscoContext.dati.identificativoMarcaDaBollo.length === 14
        }
        invalid={
          fiscoContext.dati.identificativoMarcaDaBollo.length > 0 &&
          fiscoContext.dati.identificativoMarcaDaBollo.length !== 14
        }
      />
      <FormFeedback valid>Sembra corretto</FormFeedback>
      <FormFeedback>
        L&apos;identificativo della marca da bollo è composto da 14 caratteri
      </FormFeedback>
    </FormGroup>
  )
}

export default MarcaDaBollo
