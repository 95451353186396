import React, { useState, useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import swal from 'sweetalert'

const Contributi = () => {
  const context = useContext(DichiarazioneContext)
  const [ENPAMCaricato, setENPAMCaricato] = useState(null)

  const controllaEProsegui = () => {
    if (ENPAMCaricato !== null) {
      context.completaStepContributi(ENPAMCaricato)
    } else {
      swal({
        title: 'Manca qualcosa',
        text: 'Spunta una delle due caselle prima di andare avanti',
        icon: 'warning',
        button: 'CHIUDI',
      })
    }
  }

  const filesContributi = () => {
    const tappa = context.dati?.tappe.find((t) => t.nome === 'contributi')
    const domanda = tappa?.domande.find((d) => d.numero === 0)
    return domanda?.files?.map((f, index) => (
      <div key={index} className="mb-2">
        <i
          className="bi bi-trash3 testo-rosso"
          style={{ cursor: 'pointer' }}
          onClick={() => context.cancellaFile('contributi', 0, f.uuid)}
        ></i>{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => context.visualizzaFile(f.uuid)}
        >
          {f.nome}
        </span>
      </div>
    ))
  }

  return (
    <div>
      <h4 className="testo-verde font-bold mb-4">
        SECONDA TAPPA: CERTIFICAZIONE DEI CONTRIBUTI ENPAM
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Dato che {context.genere?.ilTuo} commercialista può vedere tutto ciò che si
        trova nel tuo cassetto fiscale, c&apos;;è solo un tipo di documento che ti
        chiediamo di caricare. Si tratta della{' '}
        <span className="font-bold">certificazione dei contributi ENPAM</span>.
        <br />
        La puoi trovare nella tua area riservata del sito dell’ENPAM, dopo aver fatto
        l&apos;accesso. Ti basterà cliccare su &quot;<i>Certificazioni</i>&quot;
        nella barra a sinistra e scaricare il file sotto la voce &quot;
        <i>Oneri deducibili anno 2024 versati nel 2023</i>&quot; sotto la voce &quot;
        <i>Oneri deducibili</i>&quot;.
        <br />
        <br />
        Prima di andare avanti,{' '}
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => context.apriFileUpload('contributi', 0)}
        >
          <u>clicca qui</u>
        </span>{' '}
        per caricare la certificazione.
      </h6>
      {filesContributi()}
      <FormGroup check className="mt-4">
        <Input
          type="checkbox"
          onChange={(e) => setENPAMCaricato(e.target.checked)}
          checked={ENPAMCaricato}
          valid={ENPAMCaricato}
        />{' '}
        <Label check>
          Ho caricato la certificazione ENPAM presente nella mia area riservata
        </Label>
      </FormGroup>
      <FormGroup check className="mb-4 mt-2">
        <Input
          type="checkbox"
          onChange={(e) => setENPAMCaricato(!e.target.checked)}
          checked={ENPAMCaricato !== null && !ENPAMCaricato}
          valid={ENPAMCaricato !== null && !ENPAMCaricato}
        />{' '}
        <Label check>
          Non ho caricato la certificazione perché nella mia area riservata non
          c&apos;è nulla
        </Label>
      </FormGroup>
      <Button
        className="float-right bottone-blu mb-4"
        onClick={() => controllaEProsegui()}
      >
        PROSSIMA TAPPA
      </Button>
    </div>
  )
}

export default Contributi
