import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import '../node_modules/video-react/dist/video-react.css'
import NavBar from './features/NavBar'
import ConfermaPassword from './features/login/ConfermaPassword'
import UserContextProvider from './app/context/User'
import FiscomedProvider from './app/context/Fiscomed'
import GAPageSentinel from './app/utility/AnalyticsPageSentinel'
import CookieConsentBar from './features/cookie/CookieConsent'
import FiscoMed from './features/fiscoMed/Home'
import Scadenze from './features/fiscoMed/Scadenze'
import TesseraSanitaria from './features/sistemaTS/MainContainer'
import Statistiche from './features/fiscoMed/Statistiche/Statistiche'
import Abbonamento from './features/fiscoMed/Abbonamento'
import InformazioniFiscali from './features/fiscoMed/InformazioniFiscali'
import Rubrica from './features/Rubrica/Rubrica'
import ChatCommercialista from './features/fiscoMed/ChatCommercialista'
import ControllerStatusFisco from './features/fiscoMed/ControlloreStatusFisco'
import FatturaEmessa from './features/fiscoMed/Fattura/FatturaEmessa'
import SoloDesktop from './features/fiscoMed/SoloDesktop'
import FileCondivisi from './features/fiscoMed/FileCondivisi'
import CambioPassword from './features/login/CambioPasswrod'
import FiscoDemiaHome from './features/fiscodemia/FiscoDemiaHome'
import FiscoDemiaVideo from './features/fiscodemia/FiscoDemiaVideo'
import FatturaContainer from './features/Fattura/FatturaContainer'
import EsitoProforma from './features/Fattura/Esiti/EsitoProforma'
import ProformaContainer from './features/Proforma/ProformaContainer'
import EsitoFatturaCartacea from './features/Fattura/Esiti/EsitoFatturaCartacea'
import EsitoFatturaElettronica from './features/Fattura/Esiti/EsitoFatturaElettronica'
import EsitoAutofattura from './features/Fattura/Esiti/EsitoAutofattura'
import MetodoDiPagamento from './features/fiscoMed/MetodoDiPagamento'
import RiepilogoProvider from './app/context/RiepilogoFatture'
import RiepilogoContainer from './features/RiepilogoFatture/RiepilogoContainer'
import Login from './features/login/Login'
import Pagamento from './features/login/Pagamento'
import PagamentoMobile from './features/login/PagamentoMobile'
import ThankYouPage from './features/login/ThankYouPage'
import ScreenSelector from './features/landing/ScreenSelector'
import FiscomedDesktop from './features/landing/Desktop/FiscomedDesktop'
import FiscomedMobile from './features/landing/Mobile/FiscomedMobile'
import PrezziDesktop from './features/landing/Desktop/PrezziDesktop'
import PrezziMobile from './features/landing/Mobile/PrezziMobile'
import GuidaFiscaleMobile from './features/landing/Mobile/GuidaFiscaleMobile '
import GuidaFiscaleDesktop from './features/landing/Desktop/GuidaFiscaleDesktop'
import NewsLetterDesktop from './features/landing/Desktop/NewsLetterDesktop'
import NewsLetterMobile from './features/landing/Mobile/NewsLetterMobile'
import LoginMobile from './features/login/LoginMobile'
import CambioPasswordMobile from './features/login/CambioPasswordMobile'
import ConfermaPasswordMobile from './features/login/ConfermaPasswordMobile'
import ScrollToTop from './features/ScrollToTop'
import OnlyNotLogged from './features/OnlyNotLogged'
import ConfermaPrenotazione from './features/login/ConfermaPrenotazione'
import LoginCommercialista from './features/login/LoginCommercialista'
import ContainerImportaFattura from './features/ImportaFattura/ContainerImportaFattura'
import ImportaFatturaProvider from './app/context/ImportaFattura'
import ContainerImportaBorsa from './features/ImportaBorsa/ContainerImportaBorsa'
import ImportaBorsaProvider from './app/context/ImportaBorsa'
import CodiceCollega from './features/fiscoMed/CodiceCollega'
import BlogHomeDesktop from './features/blog/BlogHomeDesktop'
import BlogHomeMobile from './features/blog/BlogHomeMobile'
import ArticoloDesktop from './features/blog/ArticoloDesktop'
import ArticoloMobile from './features/blog/ArticoloMobile'
import DichiarazioneProvider from './app/context/Dichiarazione'
import DichiarazioneContainer from './features/dichiarazione/DichiarazioneContainer'
import Archivio from './features/dichiarazione/Archivio'
import EnpamProvider from './app/context/Enpam'
import EnpamContainer from './features/Enpam/EnpamContainer'
import DoctorsaDesktop from './features/landing/Desktop/DoctorsaDesktop'
import DoctorsaMobile from './features/landing/Mobile/DoctorsaMobile'
import Contratti from './features/Contratti'
import PrimaConsulenza from './features/PrimaConsulenza'
import VideoIntroduzione from './features/landing/VideoIntroduzione'

const App = () => (
  <>
    <CookieConsentBar />
    <Router>
      <UserContextProvider>
        <NavBar />
        <GAPageSentinel />
        <Contratti />
        <FiscomedProvider>
          <ScrollToTop />
          <Switch>
            <Route exact path="/blog/:categoriaPrimaria">
              <ScreenSelector>
                <BlogHomeMobile />
                <BlogHomeDesktop />
              </ScreenSelector>
            </Route>
            <Route exact path="/blog/articolo/:url">
              <ScreenSelector>
                <ArticoloMobile />
                <ArticoloDesktop />
              </ScreenSelector>
            </Route>
            <Route exact path="/auth-commercialista/:uuidUtente/:token">
              <LoginCommercialista />
            </Route>
            <Route exact path="/conferma-prenotazione">
              <ConfermaPrenotazione />
            </Route>
            <Route exact path="/cambio_password/:codice">
              <ScreenSelector>
                <ConfermaPasswordMobile />
                <ConfermaPassword />
              </ScreenSelector>
            </Route>
            <Route exact path="/cambio-password">
              <ScreenSelector>
                <CambioPasswordMobile />
                <CambioPassword />
              </ScreenSelector>
            </Route>
            <Route exact path="/fiscomed">
              <ControllerStatusFisco>
                <FiscoMed />
              </ControllerStatusFisco>
            </Route>
            <Route
              exact
              path="/fiscomed/nuova-fattura/esito-fattura-elettronica/:esito/:idFattura"
            >
              <ControllerStatusFisco>
                <EsitoFatturaElettronica />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/fiscomed/nuova-fattura/esito-proforma/:idProforma">
              <ControllerStatusFisco>
                <EsitoProforma />
              </ControllerStatusFisco>
            </Route>
            <Route
              exact
              path="/fiscomed/nuova-fattura/esito-fattura-cartacea/:idFattura"
            >
              <ControllerStatusFisco>
                <EsitoFatturaCartacea />
              </ControllerStatusFisco>
            </Route>
            <Route
              exact
              path="/fiscomed/nuova-fattura/esito-autofattura/:esito/:iva/:idFattura"
            >
              <ControllerStatusFisco>
                <EsitoAutofattura />
              </ControllerStatusFisco>
            </Route>
            <Route
              exact
              path="/fiscomed/nuova-fattura/:tipologia/:idFatturaDiRiferimento"
            >
              <ControllerStatusFisco>
                <FatturaContainer />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/fiscomed/proforma">
              <ControllerStatusFisco>
                <ProformaContainer />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/codice-collega">
              <ControllerStatusFisco>
                <CodiceCollega />
              </ControllerStatusFisco>
            </Route>

            <Route exact path="/riepilogo-fatture">
              <ControllerStatusFisco>
                <RiepilogoProvider>
                  <RiepilogoContainer />
                </RiepilogoProvider>
              </ControllerStatusFisco>
            </Route>

            <Route exact path="/enpam">
              <ControllerStatusFisco>
                <EnpamProvider>
                  <EnpamContainer />
                </EnpamProvider>
              </ControllerStatusFisco>
            </Route>

            <Route exact path="/dichiarazione">
              <ControllerStatusFisco>
                <DichiarazioneProvider>
                  <DichiarazioneContainer />
                </DichiarazioneProvider>
              </ControllerStatusFisco>
            </Route>

            <Route exact path="/dichiarazione/archivio">
              <ControllerStatusFisco>
                <DichiarazioneProvider>
                  <Archivio />
                </DichiarazioneProvider>
              </ControllerStatusFisco>
            </Route>

            <Route exact path="/scadenze">
              <ControllerStatusFisco>
                <Scadenze />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/tessera-sanitaria">
              <ControllerStatusFisco>
                <TesseraSanitaria />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/statistiche">
              <ControllerStatusFisco>
                <Statistiche />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/metodo-di-pagamento">
              <ControllerStatusFisco>
                <MetodoDiPagamento />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/abbonamento">
              <ControllerStatusFisco>
                <Abbonamento />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/informazioni-fiscali">
              <ControllerStatusFisco>
                <InformazioniFiscali />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/rubrica">
              <ControllerStatusFisco>
                <Rubrica />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/chat-commercialista">
              <ControllerStatusFisco>
                <ChatCommercialista />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/fattura-emessa/:idFattura">
              <ControllerStatusFisco>
                <FatturaEmessa />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/file-condivisi">
              <ControllerStatusFisco>
                <FileCondivisi />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/importa-fattura">
              <ControllerStatusFisco>
                <ImportaFatturaProvider>
                  <ContainerImportaFattura />
                </ImportaFatturaProvider>
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/importa-borsa">
              <ControllerStatusFisco>
                <ImportaBorsaProvider>
                  <ContainerImportaBorsa />
                </ImportaBorsaProvider>
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/fiscodemia/home">
              <ControllerStatusFisco>
                <FiscoDemiaHome />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/fiscodemia/video/:nomeVideo">
              <ControllerStatusFisco>
                <FiscoDemiaVideo />
              </ControllerStatusFisco>
            </Route>
            <Route exact path="/disponibile-sul-desktop">
              <SoloDesktop />
            </Route>
            <Route exact path="/pagamento/:periodo">
              <ScreenSelector>
                <PagamentoMobile />
                <Pagamento />
              </ScreenSelector>
            </Route>
            <Route exact path="/accesso">
              <OnlyNotLogged>
                <ScreenSelector>
                  <LoginMobile />
                  <Login />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/pagamento-riuscito">
              <ThankYouPage />
            </Route>
            <Route exact path="/">
              <OnlyNotLogged>
                <ScreenSelector>
                  <FiscomedMobile />
                  <FiscomedDesktop />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/newsletter">
              <OnlyNotLogged>
                <ScreenSelector>
                  <NewsLetterMobile />
                  <NewsLetterDesktop />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/prezzi">
              <OnlyNotLogged>
                <ScreenSelector>
                  <PrezziMobile />
                  <PrezziDesktop />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/guida-fiscale">
              <OnlyNotLogged>
                <ScreenSelector>
                  <GuidaFiscaleMobile />
                  <GuidaFiscaleDesktop />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/doctorsa">
              <OnlyNotLogged>
                <ScreenSelector>
                  <DoctorsaMobile />
                  <DoctorsaDesktop />
                </ScreenSelector>
              </OnlyNotLogged>
            </Route>
            <Route exact path="/video-introduttivo">
              <OnlyNotLogged>
                <VideoIntroduzione />
              </OnlyNotLogged>
            </Route>
            <Route exact path="/prima-consulenza/:publicUuid/:commercialista?">
              <PrimaConsulenza />
            </Route>
            <Route>
              <Login />
            </Route>
          </Switch>
        </FiscomedProvider>
      </UserContextProvider>
    </Router>
  </>
)

export default App
