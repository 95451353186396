import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'

const DescrizioneImportoNotaDiVariazionePR = () => {
  const fiscoContext = useContext(FiscomedContext)

  const aggiornaDescrizione = (valore) =>
    fiscoContext.aggiornaDescrzioneNotaDiVariazione(valore)

  const aggiornaImporto = (valore) => {
    let valoreFloat = parseFloat(valore)
    if (isNaN(valoreFloat)) {
      valoreFloat = ''
    }
    fiscoContext.aggiornaImportoNotaDiVariazione(valoreFloat)
  }

  return (
    <>
      <div className="mt-5" />
      <h4 className="d-inline align-bottom font-regular">
        <i className="bi bi-blockquote-left"></i> Descrizione
      </h4>
      <FormGroup>
        <Label className="text-muted">
          Breve descrizione del motivo della nota di variazione
        </Label>
        <Input
          type="text"
          value={fiscoContext.dati.descrizioneNotaDiVariazione}
          onChange={(e) => {
            if (e.target.value.length < 199) {
              aggiornaDescrizione(e.target.value)
            }
          }}
          valid={/^[\w .,!?()]+$/.test(
            fiscoContext.dati.descrizioneNotaDiVariazione
          )}
          invalid={
            !/^$|^[\w .,!?()]+$/.test(fiscoContext.dati.descrizioneNotaDiVariazione)
          }
        />
        <FormFeedback valid>Sembra corretto</FormFeedback>
        <FormFeedback>
          Sembra che tu abbia usato dei caratteri non consentiti
        </FormFeedback>
      </FormGroup>
      <div className="mt-5" />
      <h4 className="d-inline align-bottom font-regular">
        <i className="bi bi-currency-euro"></i> Importo nota
      </h4>
      <FormGroup>
        <Label className="text-muted">
          Importo in numero positivo, quindi va bene 200€, non va bene -200€
        </Label>
        <Input
          type="number"
          value={fiscoContext.dati.importoNotaDiVariazione}
          onChange={(e) => aggiornaImporto(e.target.value)}
        />
      </FormGroup>
    </>
  )
}

export default DescrizioneImportoNotaDiVariazionePR
