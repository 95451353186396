import React, { useContext, useState } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const DueDueRequisitiMantenuti = () => {
  const context = useContext(EnpamContext)
  const [cambioAliquota, setCambioAliquota] = useState(null)

  const avanti = () => {
    if (cambioAliquota) {
      context.setScheda('rinuncia_aliquota_ridotta')
    } else {
      context.setScheda('modello_d')
    }
  }

  const aliquota = () => {
    if (
      context.dati.scheda.includes('mmg') ||
      context.dati.scheda.includes('dipendente')
    ) {
      return '2%'
    } else {
      return '9,75%'
    }
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={50} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">PUOI FARE UNA SCELTA</h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Dato che hai i requisiti per l&apos;aliquota del {aliquota()} hai due
        opzioni: puoi scegliere di continuare a versare i contributi con
        l&apos;aliquota agevolata o cominciare a versarli con l&apos;aliquota intera
        del 19,50%.
        <br />
        <br />
        La scelta dell&apos;aliquota da versare dipende dalla tua propensione al{' '}
        <b>risparmio</b> o all&apos;<b>investimento</b>. Se preferisci risparmiare
        nell&apos;immediato, ti consigliamo di continuare a usufruire
        dell&apos;aliquota del <b>{aliquota()}</b>. In questo caso non dovrai fare
        nulla, poiché la richiesta fatta continua a essere valida.
        <br />
        <br />
        Se, al contrario, desideri investire nella tua pensione futura, allora
        dovresti versare l&apos;aliquota intera. Nel tuo caso questa scelta è{' '}
        <b>irreversibile</b>. Chi, pur avendo una richiesta attiva di contribuzione
        ridotta, sceglie di passare all&apos;aliquota intera poi non potrà mai più
        tornare a beneficiare dell&apos;aliquota agevolata.
        <br />
        <br />
        Che cosa vuoi fare?
      </h6>
      <br />
      <div>
        <Button
          className={classNames(
            { 'bottone-verde': cambioAliquota === false },
            {
              'bottone-verde-outline':
                cambioAliquota === true || cambioAliquota == null,
            },
            'p-3'
          )}
          onClick={() => setCambioAliquota(false)}
        >
          Voglio continuare a usufruire dell&apos;aliquota del {aliquota()}
        </Button>
        <br />
        <Button
          className={classNames(
            { 'bottone-verde': cambioAliquota },
            {
              'bottone-verde-outline':
                cambioAliquota === null || cambioAliquota === false,
            },
            'mt-4 p-3'
          )}
          onClick={() => setCambioAliquota(true)}
        >
          Voglio fare richiesta per l&apos;aliquota intera del 19,50%&nbsp;&nbsp;
        </Button>
      </div>

      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default DueDueRequisitiMantenuti
