import React, { useContext } from 'react'
import { Row, Col } from 'reactstrap'
import { RiepilogoContext } from '../../app/context/RiepilogoFatture'

const SelettoreAnno = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  const cambiaAnno = (anno) => riepilogoContext.impostaAnnoEIdrata(anno)

  return (
    <Row>
      <Col>
        {((Math.min(...riepilogoContext.dati.anniConFattureEmesse) <
          riepilogoContext.dati.annoDiRiferimento &&
          riepilogoContext.dati.anniConFattureEmesse.length > 0) ||
          riepilogoContext.dati.annoDiRiferimento >
            riepilogoContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() => cambiaAnno(riepilogoContext.dati.annoDiRiferimento - 1)}
          >
            <i className="bi bi-arrow-left-square float-right"></i>
          </h3>
        )}
      </Col>
      <Col>
        <center>
          <h3 className="font-regular">
            Anno di emissione {riepilogoContext.dati.annoDiRiferimento}
          </h3>
        </center>
      </Col>
      <Col>
        {(Math.max(...riepilogoContext.dati.anniConFattureEmesse) >
          riepilogoContext.dati.annoDiRiferimento ||
          riepilogoContext.dati.annoDiRiferimento <
            riepilogoContext.dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() => cambiaAnno(riepilogoContext.dati.annoDiRiferimento + 1)}
          >
            <i className="bi bi-arrow-right-square"></i>
          </h3>
        )}
      </Col>
    </Row>
  )
}

export default SelettoreAnno
