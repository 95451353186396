import React, { useContext } from 'react'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import PannelloCartacea from './PannelloCartacea'
import PannelloElettronica from './PannelloElettronica'
import PannelloPA from './PannelloPA'
import PannelloProforma from './PannelloProforma'
import PannelloNDCpa from './PannelloNDCpa'
import PannelloNDCpr from './PannelloNDCpr'
import PannelloNDDpr from './PannelloNDDpr'
import PannelloRettificaPr from './PannelloRettificaPr'
import PannelloAutofattura from './PannelloAutofattura'

const PannelloContainer = () => {
  const fiscoContext = useContext(FiscomedContext)

  return (
    <div>
      {fiscoContext.dati.tipologiaFattura === 'Fattura cartacea' && (
        <PannelloCartacea />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Fattura elettronica' && (
        <PannelloElettronica />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Proforma' && <PannelloProforma />}
      {fiscoContext.dati.tipologiaFattura === 'Nota di credito pa' && (
        <PannelloNDCpa />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Nota di credito pr' && (
        <PannelloNDCpr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Nota di debito pr' && (
        <PannelloNDDpr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Rettifica pr' && (
        <PannelloRettificaPr />
      )}
      {fiscoContext.dati.tipologiaFattura === 'Fattura PA' && <PannelloPA />}
      {fiscoContext.dati.tipologiaFattura === 'Autofattura' && (
        <PannelloAutofattura />
      )}
    </div>
  )
}

export default PannelloContainer
