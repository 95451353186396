import React, { useContext } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'

const ScadenzaPagamento = () => {
  const fiscoContext = useContext(FiscomedContext)
  const setScadenzaPagamento = (scadenzaPagamento) => {
    fiscoContext.aggiornaDatiAggiuntivi({
      ...fiscoContext.dati.datiAggiuntivi,
      scadenzaPagamento,
    })
  }

  return (
    <FormGroup>
      <Label>Scadenza Pagamento</Label>
      <Input
        type="date"
        value={fiscoContext.dati.datiAggiuntivi.scadenzaPagamento}
        onChange={(e) => setScadenzaPagamento(e.target.value)}
      />
    </FormGroup>
  )
}

export default ScadenzaPagamento
