import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import moment from 'moment'

const ModaleNumeroAutofattura = () => {
  const [modal, setModal] = useState(false)
  const fiscoContext = useContext(FiscomedContext)

  const componentModal = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Cambia numero e data autofattura
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Fiscomed imposta il numero e la data dell&apos;autofattura in modo
          automatico. Se pensi che questi dati non siano corretti, cambiali pure.
          <br />
          <br />
          Come <span className="font-bold">data</span> puoi inserire quella in cui
          hai ricevuto la fattura estera o una data che cade nel mese di ricezione
          del documento.
          <br />
          <br />
          Per quanto riguarda il <span className="font-bold">numero</span>, invece,
          bisogna utilizzare un sezionale dedicato (1A, 2A, 3A...). Ci abbiamo già
          pensato noi a crearlo per te.
        </h6>
        <br />
        <Form>
          <FormGroup>
            <Label>Numero autofattura</Label>
            <Input
              type="number"
              value={fiscoContext.dati.numeroAutofattura}
              onChange={(e) =>
                fiscoContext.aggiornaNumeroAutofattura(e.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label>Data autofattura</Label>
            <Input
              type="date"
              value={fiscoContext.dati.dataFattura}
              onChange={(e) => fiscoContext.aggiornaDataFattura(e.target.value)}
            />
          </FormGroup>
        </Form>
        <Button
          className="bottone-blu float-right mt-3"
          style={{ backgroundColor: '#023e73' }}
          onClick={() => setModal(false)}
        >
          FATTO
        </Button>
      </ModalBody>
    </Modal>
  )

  return (
    <span onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>
      <i className="bi bi-pencil"></i> Autofattura n°{' '}
      {fiscoContext.dati.numeroAutofattura}
      {'A'}
      <br /> Data {moment(fiscoContext.dati.dataFattura).format('DD/MM/YYYY')}{' '}
      {componentModal}
    </span>
  )
}

export default ModaleNumeroAutofattura
