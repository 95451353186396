import React, { useState, useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Tooltip,
} from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import { latin1Invalid } from '../../../app/utility/ValidatoreRegex'

const Prestazioni = (props) => {
  const [modal, setModal] = useState(false)
  const [descrizione, setDescrizione] = useState('')
  const [importoNetto, setImportoNetto] = useState('')
  const [importoIVA, setImportoIva] = useState(0.0)
  const [importoTotale, setImportoTotale] = useState('')
  const [iva, setIva] = useState(22.0)
  const [natura, setNatura] = useState('')
  const [fraseIva, setFraseIva] = useState('22%')
  const fiscoContext = useContext(FiscomedContext)
  const [tooltip, setTooltip] = useState(false)

  const aggiungi = () => {
    if (descrizione !== '') {
      fiscoContext.aggiungiPrestazioneAutofattura({
        descrizione,
        importoNetto: Math.round(Number(importoNetto.replace(',', '.')) * 1e2) / 1e2,
        importoTotale,
        importoIVA,
        iva,
        natura,
      })
      setDescrizione('')
      setImportoNetto('')
      setImportoTotale('')
      setImportoIva(0.0)
      setIva(22.0)
      setNatura('')
      setFraseIva('22%')
      setModal(false)
    }
  }

  const rimuoviPrestazioneAutofattura = (posizioneArray) =>
    fiscoContext.rimuoviPrestazioneAutofattura(posizioneArray)

  const impostaIva = (fraseIva) => {
    setFraseIva(fraseIva)
    if (fraseIva === '22%') {
      setIva(22.0)
      setNatura('')
      calcolaImportoTotale(importoNetto, 22.0)
      calcolaImportoIVA(importoNetto, 22.0)
    }
    if (fraseIva === '0% Esente Art.10 DPR 633/72 N4') {
      setIva(0.0)
      setNatura('N4')
      calcolaImportoTotale(importoNetto, 0.0)
      calcolaImportoIVA(importoNetto, 0.0)
    }
  }

  const calcolaImportoTotale = (netto, numeroIva) => {
    if (netto !== '') {
      setImportoTotale(
        Math.round(netto.replace(',', '.') * (1 + numeroIva / 100) * 1e2) / 1e2
      )
    }
  }

  const calcolaImportoIVA = (netto, numeroIva) => {
    if (netto !== '') {
      setImportoIva(
        Math.round(netto.replace(',', '.') * (numeroIva / 100) * 1e2) / 1e2
      )
    } else {
      setImportoIva(0.0)
    }
  }

  const impostaImportoNetto = (netto) => {
    setImportoNetto(netto)
    calcolaImportoTotale(netto, iva)
    calcolaImportoIVA(netto, iva)
  }

  const modalPrestazione = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-regular" toggle={() => setModal(false)}>
        Aggiungi bene o servizio
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-25">
          Qui devi semplicemente indicare il bene o servizio che hai comprato dal
          fornitore estero. Dovrebbe essere indicato nella fattura che hai ricevuto
        </h6>
        <br />
        <Form>
          <FormGroup>
            <Label>Cosa hai comprato?</Label>
            <Input
              type="text"
              placeholder="Otoscopio"
              value={descrizione}
              onChange={(e) => {
                if (e.target.value.length < 995) {
                  setDescrizione(e.target.value)
                }
              }}
              invalid={latin1Invalid(descrizione)}
            />
            <FormFeedback>
              Sembra che tu abbia usato dei caratteri non consentiti
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Quanto lo hai pagato? (Importo netto)</Label>
            <Input
              type="text"
              value={importoNetto}
              placeholder="Scrivi qui l'importo netto, in euro"
              onChange={(e) => impostaImportoNetto(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Aliquota IVA <i className="bi bi-question-circle" id="tooltipIVA"></i>
            </Label>
            <Tooltip
              placement="top"
              isOpen={tooltip}
              target="tooltipIVA"
              toggle={() => setTooltip(!tooltip)}
            >
              Il bene o servizio che hai comprato può essere esente IVA (quindi
              seleziona 0%) oppure non esente. In questo caso controlla l&apos;IVA
              applicata a ciò che hai acquistato. Se si tratta del 22%, lascia tutto
              così com&apos;è. Se, invece, hai bisogno di indicare un&apos;IVA
              diversa, scrivici su WhatsApp
            </Tooltip>
            <Input
              type="select"
              value={fraseIva}
              onChange={(e) => impostaIva(e.target.value)}
            >
              <option>22%</option>
              <option>0% Esente Art.10 DPR 633/72 N4</option>
            </Input>
          </FormGroup>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label>Importo IVA</Label>
                <Input type="text" value={importoIVA} disabled />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label>Importo totale</Label>
                <Input type="text" value={importoTotale} disabled />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline mr-3"
          onClick={() => setModal(false)}
        >
          ANNULLA
        </Button>
        <Button className="bottone-blu-scuro" onClick={() => aggiungi()}>
          AGGIUNGI BENE O SERVIZIO
        </Button>
      </ModalFooter>
    </Modal>
  )

  const componentPrestazioni = fiscoContext.dati.prestazioniAutofattura.map(
    (prestazione, i) => (
      <div key={i} className="mb-3">
        <Row>
          <Col xs="8">
            <h6 className="text-muted">
              <u
                className="text-danger"
                onClick={() => rimuoviPrestazioneAutofattura(i)}
                style={{ cursor: 'pointer' }}
              >
                <i className="bi bi-trash3"></i>
              </u>
              &nbsp;
              {prestazione.descrizione}
            </h6>
          </Col>
          <Col xs="4">
            <h6 className="float-right font-regular">
              {prestazione.importoTotale}€
            </h6>
          </Col>
        </Row>
      </div>
    )
  )

  return (
    <div className="mb-3">
      {componentPrestazioni}
      <Button
        onClick={() => setModal(true)}
        className="bottone-blu-scuro"
        disabled={fiscoContext.dati.prestazioniAutofattura.length > 0}
      >
        AGGIUNGI PRESTAZIONE
      </Button>
      {modalPrestazione}
    </div>
  )
}

export default Prestazioni
