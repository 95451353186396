import React from 'react'
import { UserContext } from './User'
import moment from 'moment'
import {
  idrataBorse,
  caricaBorsa,
  cancellaBorsa,
  inviaModificaBorsa,
  scaricaBorsa,
} from '../utility/QueryImportaBorsa'

const ImportaBorsaContext = React.createContext()

const emptyState = {
  borse: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConBorseEmesse: [],
  loading: true,
  modalImportOpen: false,
  modalUpdateOpen: false,
  tipologia: 'borsa_specializzazione',
  borsaSelezionata: null,
  dataIncasso: null,
  importo: null,
  fileBorsa: null,
  upLoading: false,
}

class ImportaBorsaProvider extends React.PureComponent {
  static contextType = UserContext
  constructor() {
    super()
    this.state = emptyState
  }

  idrata = () =>
    idrataBorse(this.context.user, this.state.annoDiRiferimento).then(
      (responseBody) => {
        this.setState({
          ...this.state,
          loading: false,
          borse: responseBody.borse,
          anniConBorseEmesse: responseBody.anniConBorseEmesse,
        })
      }
    )

  impostaAnnoEIdrata = (anno) =>
    this.setState({ loading: true }, () => {
      idrataBorse(this.context.user, anno).then((responseBody) => {
        this.setState({
          ...this.state,
          annoDiRiferimento: anno,
          borse: responseBody.borse,
          anniConBorsaEmessa: responseBody.anni_con_borsa_emessa,
          loading: false,
        })
      })
    })

  statoModalImport = (stato) => {
    this.setState({
      ...this.state,
      dataIncasso: null,
      tipologia: 'borsa_specializzazione',
      importo: null,
      fileBorsa: null,
      modalImportOpen: stato,
    })
  }

  statoModalUpdate = (stato) => {
    this.setState({
      ...this.state,
      dataIncasso: null,
      importo: null,
      fileBorsa: null,
      modalUpdateOpen: stato,
    })
  }

  impostaTipologia = (tipologia) => {
    this.setState({
      ...this.state,
      tipologia,
    })
  }

  impostaAnnoIncasso = (anno) => {
    this.setState({
      ...this.state,
      dataIncasso: anno,
    })
  }

  impostaImporto = (importo) => {
    this.setState({
      ...this.state,
      importo,
    })
  }

  impostaFileBorsa = (file) => {
    this.setState({
      ...this.state,
      fileBorsa: file,
    })
  }

  aggiungiBorsa = async () => {
    if (this.state.dataIncasso < 2010 || this.state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (this.state.fileBorsa === '' || this.state.fileBorsa === null) {
      alert('Seleziona un file borsa')
      return
    }
    if (this.state.fileBorsa.size / 1073741824 > 0.05) {
      alert('La dimensione del file non può superare i 20MB')
      return
    }
    const formData = new FormData()
    formData.append('tipologia', this.state.tipologia)
    formData.append('fileBorsa', this.state.fileBorsa)
    formData.append('token', this.context.user.auth_token)
    formData.append('dataIncasso', this.state.dataIncasso)
    formData.append(
      'importo',
      this.state.tipologia === 'borsa_specializzazione' ? 0 : this.state.importo
    )
    formData.append('anno', this.state.annoDiRiferimento)
    this.setState({ upLoading: true })
    const response = await caricaBorsa(formData)
    if (response) {
      this.setState({
        upLoading: false,
        borse: response.borse,
        anniConBorseEmesse: response.anniConBorseEmesse,
        modalImportOpen: false,
        dataIncasso: null,
        importo: null,
        fileBorsa: null,
        tipologia: 'borsa_specializzazione',
      })
    }
    return response
  }

  cancellaFile = async (uuidFile) => {
    this.setState({ upLoading: true })
    const response = await cancellaBorsa(
      this.context.user,
      uuidFile,
      this.state.annoDiRiferimento
    )
    if (response) {
      this.setState({
        upLoading: false,
        borse: response.borse,
        anniConBorseEmesse: response.anniConBorseEmesse,
      })
    }
    return response
  }

  modificaBorsa = async (uuidFile) => {
    const borsaSelezionata = this.state.borse.find(
      (borsa) => borsa.uuid_file === uuidFile
    )
    this.setState({
      ...this.state,
      modalUpdateOpen: true,
      borsaSelezionata,
      dataIncasso: borsaSelezionata.data_incasso,
      importo: borsaSelezionata.importo / 100,
      tipologia: borsaSelezionata.tipologia,
    })
  }

  inviaModifica = async () => {
    if (this.state.dataIncasso < 2010 || this.state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    this.setState({ upLoading: true })
    const response = await inviaModificaBorsa(
      this.context.user,
      this.state.borsaSelezionata.uuid_file,
      this.state.dataIncasso,
      this.state.importo,
      this.state.annoDiRiferimento,
      this.state.tipologia
    )
    if (response) {
      this.setState({
        upLoading: false,
        borse: response.borse,
        anniConBorseEmesse: response.anniConBorseEmesse,
        modalUpdateOpen: false,
        dataIncasso: null,
        importo: null,
        fileBorsa: null,
        tipologia: 'borsa',
      })
    }
    return response
  }

  scaricaFile = async (uuidFile, nomeFile) => {
    const response = await scaricaBorsa(this.context.user, uuidFile, nomeFile)
    return response
  }

  render() {
    const { children } = this.props

    const context = {
      dati: this.state,
      idrata: this.idrata,
      statoModalImport: this.statoModalImport,
      impostaAnnoEIdrata: this.impostaAnnoEIdrata,
      impostaTipologia: this.impostaTipologia,
      impostaAnnoIncasso: this.impostaAnnoIncasso,
      impostaImporto: this.impostaImporto,
      impostaFileBorsa: this.impostaFileBorsa,
      aggiungiBorsa: this.aggiungiBorsa,
      cancellaFile: this.cancellaFile,
      modificaBorsa: this.modificaBorsa,
      statoModalUpdate: this.statoModalUpdate,
      inviaModifica: this.inviaModifica,
      scaricaFile: this.scaricaFile,
    }

    return (
      <ImportaBorsaContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </ImportaBorsaContext.Provider>
    )
  }
}

export { ImportaBorsaProvider as default, ImportaBorsaContext }
