import React, { useContext, useState } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Row, Col, Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const PrimaDomanda = () => {
  const context = useContext(EnpamContext)
  const [richiestaAttiva, setRichiestaAttiva] = useState(false)

  const avanti = () => {
    if (richiestaAttiva) {
      context.setScheda('21')
    } else {
      context.setScheda('.11')
    }
  }

  return (
    <Card body className="h-100 d-flex d-column">
      <Progress value={10} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        IN QUESTO MOMENTO, HAI UNA RICHIESTA DI CONTRIBUZIONE RIDOTTA ATTIVA?
      </h4>
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Se in passato hai compilato il modulo &quot;<b>Contribuzione Ridotta</b>
        &quot; e non lo hai annullato, allora probabilmente la risposta è sì. Se,
        invece, non hai idea di che cosa stiamo parlando, allora significa che non
        hai mai fatto una richiesta di questo tipo all&apos;ENPAM.
        <br />
        <br />
        In ogni caso, puoi <b>verificare la tua situazione</b> dal sito
        dell&apos;ENPAM.
        <br />
        <br />
        Fai così:
        <br />
        1. Accedi alla tua <b>Area Riservata</b>
        <br />
        2. Clicca su &quot;<b>Domande e dichiarazioni online</b>&quot;, nel menu a
        sinistra
        <br />
        3. Clicca su &quot;<b>Contribuzione Ridotta</b>&quot;, sempre nel menu a
        sinistra
        <br />
        4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
        <br />
        In questa pagina puoi vedere se hai una richiesta di contribuzione ridotta
        attiva.
      </h6>
      <br />
      <Row>
        <Col xs={7}>
          <h6 className="font-regular mb-5 interlinea-30">
            Per tornare alla domanda iniziale: hai una richiesta attiva?
          </h6>
        </Col>
        <Col xs={5}>
          <div className="mb-5">
            <Button
              className={classNames(
                { 'bottone-verde': richiestaAttiva },
                { 'bottone-verde-outline': !richiestaAttiva },
                'mr-5'
              )}
              onClick={() => setRichiestaAttiva(true)}
            >
              Sì
            </Button>
            <Button
              className={classNames(
                { 'bottone-verde': !richiestaAttiva },
                {
                  'bottone-verde-outline':
                    richiestaAttiva == null || richiestaAttiva,
                },
                'mr-5'
              )}
              onClick={() => setRichiestaAttiva(false)}
            >
              No
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <div>
        <Ricomincia />
        <Button className="bottone-blu float-right" onClick={() => avanti()}>
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
      <br />
    </Card>
  )
}

export default PrimaDomanda
