import React, { useEffect, useContext } from 'react'
import { Container, Col, Row } from 'reactstrap'
import { FiscomedContext } from '../../app/context/Fiscomed'
import HeaderFattura from './Header/HeaderFattura'
import PannelloContainer from './Pannello/PannelloContainer'
import PreviewContainer from './Preview/PreviewContainer'
import CardCaricamento from './Componenti/CardCaricamento'
import { useParams } from 'react-router-dom'
import HeaderNDCpa from './Header/HeaderNDCpa'
import HeaderNDCpr from './Header/HeaderNDCpr'
import HeaderNDDpr from './Header/HeaderNDDpr'
import HeaderRettificaPr from './Header/HeaderRettificaPr'
import { Helmet } from 'react-helmet'

const FatturaContainer = () => {
  const fiscoContext = useContext(FiscomedContext)
  const { tipologia, idFatturaDiRiferimento } = useParams()

  useEffect(() => {
    if (tipologia === 'nota-di-credito-pa') {
      fiscoContext.aggiornaTipologiaFattura('Nota di credito pa')
      fiscoContext.idrataNDVariazionePa(idFatturaDiRiferimento)
      return
    }
    if (tipologia === 'nota-di-credito-pr') {
      fiscoContext.aggiornaTipologiaFattura('Nota di credito pr')
      fiscoContext.idrataNDVariazionePr(idFatturaDiRiferimento)
      return
    }
    if (tipologia === 'nota-di-debito-pr') {
      fiscoContext.aggiornaTipologiaFattura('Nota di debito pr')
      fiscoContext.idrataNDVariazionePr(idFatturaDiRiferimento)
      return
    }
    if (tipologia === 'rettifica-pr') {
      fiscoContext.aggiornaTipologiaFattura('Rettifica pr')
      fiscoContext.idrataFatturaDiRettifica(idFatturaDiRiferimento)
      return
    }
    if (tipologia === 'nota-di-credito-pa') {
      fiscoContext.aggiornaTipologiaFattura('Nota di credito PA')
      return
    }
    if (tipologia === 'nota-di-debito-pa') {
      fiscoContext.aggiornaTipologiaFattura('Nota di debito PA')
      return
    }
    if (tipologia === 'autofattura') {
      fiscoContext.aggiornaTipologiaFattura('Autofattura')
      return
    }

    fiscoContext.aggiornaTipologiaFattura('Fattura elettronica')
    fiscoContext.idrata()
  }, [])

  return (
    <>
      <Helmet>
        <title>Crea fattura</title>
        <meta property="title" content="Crea fattura" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {fiscoContext.dati.loading ? (
        <CardCaricamento />
      ) : (
        <Container>
          {tipologia === 'fattura' && <HeaderFattura />}
          {tipologia === 'nota-di-credito-pa' && <HeaderNDCpa />}
          {tipologia === 'nota-di-credito-pr' && <HeaderNDCpr />}
          {tipologia === 'nota-di-debito-pr' && <HeaderNDDpr />}
          {tipologia === 'rettifica-pr' && <HeaderRettificaPr />}

          <Row>
            <Col md="4">
              <PannelloContainer />
            </Col>
            <Col md="8">
              <PreviewContainer />
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default FatturaContainer
