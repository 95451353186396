import React, { useEffect, useContext, useState } from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import request from 'superagent'
import { UserContext } from '../../app/context/User'
import { Helmet } from 'react-helmet'

const ChatCommercialista = () => {
  const { user, isLoading } = useContext(UserContext)
  const [emailCommercialista, setEmailCommercialista] = useState(
    'francescorusso@gmail.com'
  )
  const [infoFetched, setInfoFetched] = useState(false)
  const [genere, setGenere] = useState({
    del: 'del tuo commercialista',
    al: 'al commercialista',
    ilTuo: 'il tuo commercialista',
    ilProprio: 'il proprio commercialista',
    alTuo: 'al tuo commercialista',
  })

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/assistenza/idrata`)
      .send({ token: user.auth_token })
      .then((response) => {
        setEmailCommercialista(response.body.email)
        if (response.body.commercialista_uomo === false) {
          setGenere({
            del: 'della tua commercialista',
            al: 'alla commercialista',
            ilTuo: 'la tua commercialista',
            ilProprio: 'la propria commercialista',
            alTuo: 'alla tua commercialista',
          })
        }
      })
      .catch((e) => console.error(e.message))
  }

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Vuoi una mano?</title>
        <meta property="title" content="Assistenza" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Row>
        <Col xs={3}>
          <Card body>
            <center>
              <img
                src="img/fisco/chat_commercialista.webp"
                style={{ width: '100%' }}
              />
            </center>
          </Card>
        </Col>
        <Col xs={9}>
          <Card body className="h-100">
            <h4 className="mt-2 font-medium">Siamo pronti ad aiutarti</h4>
            <h6 className="interlinea-30 font-regular mt-2">
              Con l’apertura della Partita IVA si viene risucchiati in un buco nero
              di dubbi e domande. Per aiutarti a risolverli, abbiamo pensato di darti{' '}
              <span className="font-bold">due canali di assistenza</span>: una chat
              WhatsApp e l’email {genere.del}. Se non sai quale dei due scegliere,
              scrivici su WhatsApp. Saremo noi a indirizzarti {genere.al} , se
              necessario.
            </h6>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={9}>
          <Card body className="h-100">
            <h5 className="mt-2 font-medium testo-verde">
              Hai bisogno di aiuto e di educazione fiscale? 🧞‍♂️
            </h5>
            <br />
            <h6 className="font-regular interlinea-30">
              Agli inizi della propria attività le{' '}
              <span className="font-bold">domande</span> che si fanno sono{' '}
              <span className="font-bold">sempre le stesse</span>.
              <ul>
                <li>
                  <i>Come si fa una fattura?</i>
                </li>
                <li>
                  <i>Devo applicare l’IVA?</i>
                </li>
                <li>
                  <i>Devo trasmettere i dati al Sistema Tessera Sanitaria?</i>
                </li>
                <li>
                  <i>Cosa devo fare se sbaglio una fattura?</i>
                </li>
                <li>
                  <i>
                    Mi conviene tenere la Partita IVA aperta durante la
                    specializzazione?
                  </i>
                </li>
              </ul>
              Ogni giorno riceviamo decine di domande di questo tipo:{' '}
              <span className="font-bold">molto semplici</span> per noi,{' '}
              <span className="font-bold">ma diffusissime</span> fra i giovani medici
              alle prese con i primi lavori.
              <br />
              <br />
              <span className="font-bold">
                Se hai dubbi di questo tipo scrivici su WhatsApp
              </span>
              . Così, {genere.ilTuo} può concentrarsi sulle consulenze e le procedure
              più complesse e tu puoi ricevere risposte chiare, esaustive e
              immediate.
              <br />
              <br />
              Se preferisci possiamo comunicare anche per email: scrivici a{' '}
              <b className="testo-blu font-medium">pill@fiscomed.it</b>
            </h6>
          </Card>
        </Col>
        <Col xs={3}>
          <Card body className="h-100">
            <center>
              <img src="/img/qr_whatsapp.png" style={{ height: '150px' }} />
            </center>
            <br />
            <h6 className="font-regular interlinea-30">
              Ci trovi online dal{' '}
              <span className="font-bold">lunedì al venerdì</span>, dalle{' '}
              <span className="font-bold">9:00 alle 18:00</span>. Se vuoi iniziare
              una chat con noi inquadra il QR o scrivici direttamente al numero{' '}
              <b className="testo-blu font-medium">3395601221</b>.
              <br />
              <br />
            </h6>
            <small className="text-muted">
              Non ti preoccupare per la tua privacy. Non abbiamo intenzione di
              chiamarti se non per motivi urgenti legati alla tua situazione fiscale
              e non cederemo il tuo numero a nessuno.
            </small>
          </Card>
        </Col>
      </Row>

      <br />
      <Card body>
        <h5 className="mt-2 font-medium testo-verde">
          Ti serve una consulenza strana e fiscaleggiante? 🧟‍♂️
        </h5>
        <br />
        <h6 className="interlinea-30">
          Ci sono questioni che è meglio affrontare con{' '}
          <span className="font-bold">{genere.ilProprio}</span>. Per capirci:
          <br />
          <ul>
            <li>hai ricevuto un avviso bonario (roba brutta, ma non troppo)</li>
            <li>
              devi fare delle modifiche sulla dichiarazione dei redditi o sulla
              Partita IVA
            </li>
            <li>hai bisogno di consulenze esterne al mondo medico,</li>
          </ul>
          allora ti conviene mandare una <span className="font-bold">email</span>{' '}
          direttamente {genere.alTuo}. Questo è il suo indirizzo, usalo con
          parsimonia: <b className="font-medium testo-blu">{emailCommercialista}</b>
        </h6>
      </Card>
    </Container>
  )
}

export default ChatCommercialista
