import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalVerificaSdI = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalVerificaSdiOpen}
      toggle={() => riepilogoContext.chiudiModalVerificaSdi()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalVerificaSdi()}
        className="h4 font-regular font-grigio-scuro"
      >
        Lo SdI sta verificando la tua fattura
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Che significa? 🤔 <br /> <br /> Che la tua fattura è stata inviata al
          Sistema Di Interscambio, il quale sta ancora controllando che tutti i dati
          siano corretti. Alcune volte lo SdI ci mette davvero poco a fare i
          controlli, ma in altri casi possono volerci fino a 5 giorni.
          <br /> <br />
          Quindi non allarmarti se non vedi ancora l&apos;esito della tua fattura, lo
          SdI ha i suoi tempi 🥱
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalVerificaSdI
