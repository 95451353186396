import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalRicevutaDiConsegna = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalConsegnataOpen}
      toggle={() => riepilogoContext.chiudiModalConsegnata()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalConsegnata()}
        className="h4 font-regular font-grigio-scuro"
      >
        Fattura consegnata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata consegnata al cliente, puoi tirare un sospiro di
          sollievo 😊
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Anche se il tuo cliente ha ricevuto la fattura, è sempre buona norma
          inviargli un&lsquo;email o, comunque, avvertire il cliente che la fattura è
          stata emessa
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalRicevutaDiConsegna
