import React, { useContext } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'
import { Link } from 'react-router-dom'
import ItemFatturaDiCortesia from './ItemFatturaDiCortesia'

const OpzioniConsegnata = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => riepilogoContext.apriModalEmail(props.dati?.fattura?.id)}
          >
            Invia per email
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            tag={Link}
            to={
              '/fiscomed/nuova-fattura/nota-di-credito-pr/' + props.dati.fattura.id
            }
          >
            Crea nota di credito
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to={'fiscomed/nuova-fattura/nota-di-debito-pr/' + props.dati.fattura.id}
          >
            Crea nota di debito
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => riepilogoContext.scaricaXML(props.dati.fattura.id)}
          >
            Scarica xml
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              riepilogoContext.scaricaPDFElettronica(props.dati.fattura.id)
            }
          >
            Scarica formato ministeriale
          </DropdownItem>
          <ItemFatturaDiCortesia dati={props.dati} />
          <DropdownItem divider />
          {props.dati.fattura.incassata === 'Si' ? (
            <DropdownItem
              onClick={() =>
                riepilogoContext.modificaInDaIncassare(props.dati.fattura.id)
              }
            >
              Modifica in &quot;da incassare&quot;
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() =>
                riepilogoContext.apriModalIncasso(props.dati.fattura.id)
              }
            >
              Incassa
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniConsegnata
