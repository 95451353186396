import React, { useContext } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const PreModelloD = () => {
  const context = useContext(EnpamContext)

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={70} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        ALLORA NON PUOI FARE RICHIESTA PER LA CONTRIBUZIONE RIDOTTA
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Questo vuol dire che sull&apos;importo di libera professione ti verrà
        applicata in automatico l&apos;aliquota del <b>19,50 %</b>.
        <br />
        <br />
        Il fatto di versare i contributi con l&apos;aliquota intera non è poi così
        male. Infatti, anche se non risparmi nel presente, stai facendo un{' '}
        <b>investimento</b> per la tua pensione futura.
        <br />
        <br />
        Nella prossima schermata ti spieghiamo come compilare il <b>Modello D</b>.
      </h6>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => context.setScheda('modello_d')}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
      </div>
    </Card>
  )
}

export default PreModelloD
