import React, { useContext, useState } from 'react'
import { EnpamContext } from '../../../app/context/Enpam'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const UnoCinque = () => {
  const context = useContext(EnpamContext)
  const [richiedoRidotta, setRichiedoRidotta] = useState(null)

  const avanti = () => {
    if (richiedoRidotta) {
      context.setScheda('.16')
    } else {
      context.setScheda('modello_d')
    }
  }

  const aliquota = () => {
    if (context.dati.scheda.includes('due')) {
      return {
        numero: '2%',
        frase:
          "I dipendenti che percepiscono compensi per l'attività intramoenia e i tirocinanti nel corso di formazione di medicina generale",
      }
    } else {
      return {
        numero: '9,75%',
        frase:
          "I medici che sono soggetti a un'altra forma di previdenza obbligatoria",
      }
    }
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={60} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        BENE. SE VUOI, PUOI BENEFICIARE DELL&apos;ALIQUOTA DEL {aliquota().numero}{' '}
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        {aliquota().frase} possono richiedere l&apos;aliquota del{' '}
        <b>{aliquota().numero} </b>.
        <br />
        <br />
        Fare questa richiesta <b>non rappresenta un obbligo</b>: puoi benissimo
        scegliere di versare i contributi con l&apos;aliquota intera del 19,50%.
        <br />
        <br />
        La scelta dell&apos;aliquota da versare dipende dalla tua propensione al{' '}
        <b>risparmio</b> o all&apos;<b>investimento</b>. Se preferisci risparmiare
        nell&apos;immediato, ti consigliamo di compilare il modulo
        &quot;Contribuzione Ridotta&quot; e usufruire dell&apos;aliquota del{' '}
        {aliquota().numero}. Se, al contrario, desideri investire nella tua pensione
        futura, allora dovresti versare l&apos;aliquota intera.
        <br />
        <br /> Che cosa vuoi fare?
      </h6>
      <br />
      <div>
        <Button
          className={classNames(
            { 'bottone-verde': richiedoRidotta },
            {
              'bottone-verde-outline':
                richiedoRidotta === false || richiedoRidotta == null,
            },
            'p-3'
          )}
          style={{ width: '700px' }}
          onClick={() => setRichiedoRidotta(true)}
        >
          Voglio compilare il modulo &quot;Contribuzione ridotta&quot; e usufruire
          dell&apos;aliquota del {aliquota().numero}
        </Button>
        <br />
        <Button
          className={classNames(
            { 'bottone-verde': richiedoRidotta === false },
            {
              'bottone-verde-outline':
                richiedoRidotta === null || richiedoRidotta === true,
            },
            'mt-4 p-3'
          )}
          style={{ width: '700px' }}
          onClick={() => setRichiedoRidotta(false)}
        >
          Voglio usufruire dell&apos;aliquota intera del 19,50%
        </Button>
      </div>

      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia />
        <Button
          className="bottone-blu float-right"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default UnoCinque
