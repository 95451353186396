import React, { useEffect, useContext, useState } from 'react'
import { Card, FormGroup, Input } from 'reactstrap'
import { FiscomedContext } from '../../../app/context/Fiscomed'
import ModaleNumeroFattura from '../Componenti/ModaleNumeroFattura'
import ModaleNumeroAutofattura from '../Componenti/ModaleNumeroAutofattura'
import 'moment/locale/it'
import classNames from 'classnames'

const HeaderFattura = () => {
  const [colore, setColore] = useState('#0583f2')
  const fiscoContext = useContext(FiscomedContext)

  useEffect(() => {
    if (fiscoContext.dati.tipologiaFattura === 'Fattura cartacea') {
      setColore('#66d98e')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Fattura elettronica') {
      setColore('#0583f2')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Fattura PA') {
      setColore('#f2b705')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Proforma') {
      setColore('white')
    }
    if (fiscoContext.dati.tipologiaFattura === 'Autofattura') {
      setColore('#023e73')
    }
  }, [fiscoContext.dati.tipologiaFattura])

  const cambiaTipologiaFattura = (e) =>
    fiscoContext.aggiornaTipologiaFattura(e.target.value)

  return (
    <Card
      className="mt-4"
      body
      style={{
        borderRadius: '10px',
        border: 'none',
        backgroundColor: colore,
      }}
    >
      <div className="d-flex justify-content-between">
        <FormGroup>
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            value={fiscoContext.dati.tipologiaFattura}
            onChange={(e) => cambiaTipologiaFattura(e)}
            style={{ maxWidth: '220px' }}
            className="font-regular mt-3"
          >
            <option>Fattura elettronica</option>
            <option>Fattura cartacea</option>
            <option>Fattura PA</option>
            <option>Proforma</option>
            <option>Autofattura</option>
          </Input>
        </FormGroup>
        {fiscoContext.dati.tipologiaFattura !== 'Proforma' &&
          fiscoContext.dati.tipologiaFattura !== 'Autofattura' && (
            <span
              className={classNames('h5 font-medium interlinea-30 text-inline', {
                'text-white': colore !== 'white',
              })}
            >
              <ModaleNumeroFattura />
            </span>
          )}
        {fiscoContext.dati.tipologiaFattura === 'Autofattura' && (
          <span
            className={classNames('h5 font-medium interlinea-30 text-inline', {
              'text-white': colore !== 'white',
            })}
          >
            <ModaleNumeroAutofattura />
          </span>
        )}
      </div>
    </Card>
  )
}

export default HeaderFattura
