import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../app/context/User'
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap'
import {
  ottieniURLLetteraDiIncarico,
  firmaContratti,
} from '../app/utility/QueryMiscellanea'

const Contratti = () => {
  const { user, isLoggedIn, isLoading } = useContext(UserContext)
  const [dati, setDati] = useState({
    controlloDocumentiEseguito: false,
    contrattiFirmati: true,
    terminiECondizioniFirmato: false,
    letteraDiIncaricoFirmato: false,
    firmaTermini: false,
    firmaLettera: false,
    urlLetteraDiIncarico: '',
    loading: false,
    nomeUtente: '',
  })

  useEffect(() => {
    if (isLoggedIn() && !isLoading) {
      controllaPresenzaDocumenti()
    }
  }, [isLoggedIn()])

  useEffect(() => {
    if (!dati.letteraDiIncaricoFirmato && dati.controlloDocumentiEseguito) {
      ottieniURLLetteraDiIncarico(user).then((url) => {
        setDati({ ...dati, urlLetteraDiIncarico: url })
      })
    }
  }, [dati.controlloDocumentiEseguito])

  const controllaPresenzaDocumenti = () => {
    const terminiECondizioniFirmato = user.documenti.documenti.some(
      (doc) => doc.nome === 'Termini e condizioni' && doc.versione > 0
    )
    const letteraDiIncaricoFirmato = user.documenti.documenti.some(
      (doc) => doc.nome === 'Lettera di incarico' && doc.versione > 0
    )
    const contrattiFirmati = terminiECondizioniFirmato && letteraDiIncaricoFirmato
    // Imposto anche in maiuscolo il nome dell'utente, è necessario far iniziare per maiuscola il nome,
    // il problema nasce con i doppi nomi, es. Maria Teresa che non sono sempre salvati in maiuscolo
    const nomeUtenteMaiuscolo = user.fisco_nome
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    setDati({
      contrattiFirmati,
      terminiECondizioniFirmato,
      letteraDiIncaricoFirmato,
      firmaTermini: terminiECondizioniFirmato,
      firmaLettera: letteraDiIncaricoFirmato,
      controlloDocumentiEseguito: true,
      nomeUtente: nomeUtenteMaiuscolo,
    })
  }

  const salvaFirme = () => {
    setDati({ ...dati, loading: true })
    firmaContratti(user, {
      terminiECondizioniFirmato: dati.firmaTermini,
      letteraDiIncaricoFirmato: dati.firmaLettera,
    }).then(() => {
      setDati({ ...dati, loading: false, contrattiFirmati: true })
    })
  }

  return (
    <Modal isOpen={!dati.contrattiFirmati} size="lg">
      <ModalHeader>Prima di continuare su Fiscomed</ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-30">Ciao {dati.nomeUtente} 😊</h6>
        <br />
        <h6 className="font-regular interlinea-30">
          Ogni giorno lavoriamo dietro le quinte per cercare di offrirti un servizio
          sempre migliore. Questa volta abbiamo{' '}
          <span className="font-medium">aggiornato due documenti</span>: i termini e
          condizioni di acquisto e la lettera di incarico. Vogliamo solo assicurarci
          che tu sia al corrente di queste modifiche e che le accetti per continuare
          con noi il tuo percorso fiscale.
        </h6>
        <br />
        <h6 className="font-medium interlinea-30">Cosa è cambiato?</h6>
        <h6 className="font-regular interlinea-30">
          <ul>
            <li>
              I vecchi <span className="font-medium">termini e condizioni</span>{' '}
              rispecchiavano la prima versione della nostra piattaforma. Ora che
              siamo cresciuti, è arrivato il momento di aggiornare anche questo
              contratto per descrivere nel migliore dei modi il nostro impegno nei
              tuoi confronti
            </li>
            <br />
            <li>
              La <span className="font-medium">lettera di incarico</span> è quel
              documento che regola il rapporto fra te e il tuo o la tua
              commercialista. Prima non facevamo distinzione fra l&apos;abbonamento
              annuale e quello mensile e abbiamo pensato che sarebbe stato più giusto
              fare due lettere di incarico separate. In questo modo, possiamo
              presentarti al meglio i tuoi diritti e le tue responsabilità, così come
              quelli del tuo o della tua commercialista
            </li>
          </ul>
        </h6>
        <br />
        <h6 className="font-regular interlinea-30">
          Detto ciò, spunta le caselle qui sotto per confermare che hai dato
          un&apos;occhiata a tutto e ti vanno bene i nuovi contratti:
        </h6>
        <br />
        <FormGroup check>
          <Input
            type="checkbox"
            name="articoli"
            id="articoli"
            className="mt-2"
            checked={dati.firmaTermini}
            onChange={(e) => setDati({ ...dati, firmaTermini: e.target.checked })}
          />
          <Label for="articoli" className="h6-landing">
            Accetto i{' '}
            <a
              href="/documenti/termini_e_condizioni.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <u>termini e condizioni di acquisto</u>
            </a>
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            name="lettera"
            id="lettera"
            className="mt-2"
            checked={dati.firmaLettera}
            onChange={(e) => setDati({ ...dati, firmaLettera: e.target.checked })}
          />
          <Label for="lettera" className="h6-landing">
            Accetto la{' '}
            <a href={dati.urlLetteraDiIncarico} target="_blank" rel="noreferrer">
              <u>lettera di incarico</u>
            </a>
          </Label>
        </FormGroup>
        <br />
        <h6 className="font-regular interlinea-30">
          Approfittiamo dello spiegone per ringraziarti di nuovo di averci dato
          fiducia. Per noi è davvero bello poterti supportare nella gestione della
          tua Partita IVA
        </h6>
        <br />
        <Button
          className="bottone-blu mt-3 float-right"
          disabled={!dati.firmaTermini || !dati.firmaLettera || dati.loading}
          onClick={() => salvaFirme()}
        >
          {dati.loading ? 'CARICAMENTO...' : 'VAI ALLA TUA AREA PERSONALE'}
        </Button>
        <br />
        <br />
        <br />
      </ModalBody>
    </Modal>
  )
}
export default Contratti
