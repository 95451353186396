import React, { useContext } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const ModalRicevutaDiConsegnaPA = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <Modal
      isOpen={riepilogoContext.dati.modalConsegnataPAOpen}
      toggle={() => riepilogoContext.chiudiModalConsegnataPA()}
    >
      <ModalHeader
        toggle={() => riepilogoContext.chiudiModalConsegnataPA()}
        className="h4 font-regular font-grigio-scuro"
      >
        Fattura PA consegnata 😊
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura PA è stata consegnata. La fattura elettronica è stata trasmessa
          correttamente al sistema informatico del destinatario (in questo caso la
          pubblica amministrazione) e il destinatario ha ricevuto e registrato la
          fattura. Tuttavia, ciò non significa necessariamente che la fattura sia
          stata accettata o che il pagamento sarà effettuato.
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          La pubblica amministrazione ha 15 giorni di tempo per inviare un esito
          positivo o negativo al fornitore. Se entro 15 giorni non verrà inviato
          alcun esito, riceverai una notifica di decorrenza dei termini. Per ora non
          devi fare nulla, solo aspettare che la pubblica amministrazione risponda.
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalRicevutaDiConsegnaPA
