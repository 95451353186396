import React, { useContext } from 'react'
import { Button } from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const EsitoNegativoPA = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <Button
        className="bottone-rosso"
        size="sm"
        onClick={() =>
          riepilogoContext.apriModalEsitoNegativoPA(props.dati.fattura.id)
        }
      >
        ESITO NEGATIVO
      </Button>
    </center>
  )
}

export default EsitoNegativoPA
