import React, { useContext } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const OpzioniCartacea = (props) => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => riepilogoContext.apriModalEmail(props.dati?.fattura?.id)}
          >
            Invia per email
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              riepilogoContext.modificaStatoSTS(props.dati?.fattura?.id)
            }
          >
            {props.dati?.fattura?.inviata_ts
              ? 'Segna come non inviata a STS'
              : 'Segna come inviata a STS'}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() =>
              riepilogoContext.scaricaFatturaCartacea(props.dati?.fattura?.id)
            }
          >
            Scarica pdf
          </DropdownItem>
          {props.dati?.fattura?.incassata === 'Si' ? (
            <DropdownItem
              onClick={() =>
                riepilogoContext.modificaInDaIncassare(props.dati?.fattura?.id)
              }
            >
              Modifica in &quot;da incassare&quot;
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() =>
                riepilogoContext.apriModalIncasso(props.dati?.fattura?.id)
              }
            >
              Incassa
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              if (
                window.confirm(
                  'Cancellare definitavemente la fattura di ' +
                    props.dati?.fattura?.totale +
                    ' euro?'
                )
              ) {
                riepilogoContext.eliminazioneFattura(props.dati?.fattura?.id)
              }
            }}
          >
            Elimina fattura
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniCartacea
