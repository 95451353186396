import React, { useState, useEffect } from 'react'
import { FormGroup, Input, Label, Button, Container, Spinner } from 'reactstrap'
import {
  urlLetteraDaUUID,
  firmaLetteraDaUUID,
} from '../app/utility/QueryMiscellanea'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const PrimaConsulenza = () => {
  const [consenso, setConsenso] = useState(false)
  const [letteraFirmata, setLetteraFirmata] = useState(false)
  const [urlLettera, setUrlLettera] = useState('')
  const { publicUuid } = useParams()
  const [urlCalendly, setUrlCalendly] = useState('')
  const [preposizioni, setPreposizioni] = useState({
    il: 'il',
    del: 'del',
  })
  const smallScreen = window.innerWidth <= 992
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    urlLetteraDaUUID(publicUuid).then((body) => {
      setUrlLettera(body.url_lettera)
      setUrlCalendly(body.url_calendly)
      if (body.commercialista_uomo === false) {
        setPreposizioni({ il: 'la', del: 'della' })
      }
    })
  }, [])

  const firmaLettera = () => {
    setLoading(true)
    firmaLetteraDaUUID(publicUuid).then(() => setLetteraFirmata(true))
  }

  return (
    <>
      <Helmet>
        <title>Firma lettera di incarico</title>
        <meta property="title" content="Fiscomed.it" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed.it" />
        <meta
          property="og:description"
          content="Qui è possibile firmare la lettera di incarico del tuo commercialista"
        />
        <meta
          property="description"
          content="Qui è possibile firmare la lettera di incarico del tuo commercialista"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/lettera.png");background-size: 100% auto;background-repeat: no-repeat;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>

      <Container style={{ marginTop: '123px' }}>
        <h1 className="titolo-3">
          Prima di prenotare <br />
          la consulenza 🧑‍🏫
        </h1>
        <br />
        <h6 className="testo-landing-fiscomed interlinea-25">
          C&apos;è una cosa che dovresti fare: accettare un documento particolare che
          si chiama &quot;lettera di incarico&quot;.
        </h6>
        <br />
        <h6 className="testo-landing-fiscomed interlinea-25">
          La <span className="font-medium">lettera di incarico</span> è quel
          contratto che regola il rapporto fra te e {preposizioni.il} commercialista
          che ti supporterà nella gestione fiscale. Lì sono indicati i tuoi diritti e
          le tue responsabilità, cosi come quelli {preposizioni.del} commercialista.
        </h6>
        <br />
        <h6 className="testo-landing-fiscomed interlinea-25">
          Per favore,{' '}
          <span className="font-medium">spunta la casella qui sotto</span>. In questo
          modo possiamo assicurarci che tu abbia dato un&apos;occhiata a questo
          documento legale.
        </h6>
        <br />
        <h6 className="testo-landing-fiscomed interlinea-25">
          Una volta fatto, potrai andare al calendario degli appuntamenti{' '}
          {preposizioni.del} commercialista e prenotare la consulenza.
        </h6>

        <br />
        <FormGroup check>
          <Input
            type="checkbox"
            name="lettera"
            id="lettera"
            style={{ marginTop: '12px' }}
            checked={consenso}
            onChange={(e) => setConsenso(e.target.checked)}
          />
          <Label for="lettera" className="testo-landing-fiscomed">
            Accetto la{' '}
            <a href={urlLettera} target="_blank" rel="noreferrer">
              <u>lettera di incarico</u>
            </a>
          </Label>
        </FormGroup>
        <br />
        <br />
        {!letteraFirmata ? (
          <Button
            className="bottone-blu"
            disabled={consenso === false || loading === true}
            onClick={() => firmaLettera()}
            block={smallScreen}
          >
            {loading ? <Spinner size="sm" /> : 'FIRMA E PROSEGUI'}
          </Button>
        ) : (
          <a
            href={urlCalendly}
            target="_blank"
            rel="noreferrer"
            className="btn btn-primary bottone-blu float-right"
            block={smallScreen}
          >
            VAI AL CALENDARIO
          </a>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  )
}
export default PrimaConsulenza
