import React, { useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from 'reactstrap'
import { ImportaFatturaContext } from '../../app/context/ImportaFattura'
import moment from 'moment'

const ModalUpdate = () => {
  const importaFattureContext = useContext(ImportaFatturaContext)

  return (
    <Modal
      isOpen={importaFattureContext.dati.modalUpdateOpen}
      toggle={() => importaFattureContext.statoModalUpdate(false)}
    >
      <ModalHeader
        className="h4 font-medium"
        toggle={() => importaFattureContext.statoModalUpdate(false)}
      >
        Modifica
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Tipologia</Label>
              <Input
                value={
                  importaFattureContext.dati.tipologia.charAt(0).toUpperCase() +
                  importaFattureContext.dati.tipologia.slice(1)
                }
                onChange={(e) =>
                  importaFattureContext.impostaTipologia(
                    e.target.value.toLowerCase()
                  )
                }
                type="select"
              >
                <option>Fattura</option>
                <option>Cedolino</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Data di incasso</Label>
              <Input
                type="date"
                value={
                  importaFattureContext.dati.dataIncasso === null
                    ? ''
                    : moment(importaFattureContext.dati.dataIncasso).format(
                        'yyyy-MM-DD'
                      )
                }
                onChange={(e) =>
                  importaFattureContext.impostaAnnoIncasso(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Numero</Label>
              <Input
                type="text"
                placeholder="1s"
                value={
                  importaFattureContext.dati.numeroFattura === null
                    ? ''
                    : importaFattureContext.dati.numeroFattura
                }
                onChange={(e) =>
                  importaFattureContext.impostaNumeroFattura(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Importo</Label>
              <Input
                type="number"
                placeholder="120,23"
                value={
                  importaFattureContext.dati.importo === null
                    ? ''
                    : importaFattureContext.dati.importo
                }
                onChange={(e) =>
                  importaFattureContext.impostaImporto(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {importaFattureContext.dati.tipologia === 'cedolino' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">Trattenuta ENPAM</Label>
                <Input
                  type="number"
                  placeholder="12,23"
                  value={
                    importaFattureContext.dati.trattenutaEnpam === null
                      ? ''
                      : importaFattureContext.dati.trattenutaEnpam
                  }
                  onChange={(e) =>
                    importaFattureContext.impostaTrattenutaEnpam(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        {importaFattureContext.dati.tipologia === 'fattura' && (
          <FormGroup>
            <Label for="exampleEmail">
              È stata trasmessa a Sistema Tessera Sanitaria
            </Label>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  checked={importaFattureContext?.dati?.trasmessaTS === true}
                  onChange={() => importaFattureContext.impostaTrasmessaTS(true)}
                  value="Si"
                />
                Si
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  checked={importaFattureContext?.dati?.trasmessaTS === false}
                  onChange={() => importaFattureContext.impostaTrasmessaTS(false)}
                  value="No"
                />
                No
              </Label>
            </FormGroup>
          </FormGroup>
        )}
        <br />
        <br />

        {importaFattureContext.dati.uploadLoading ? (
          <Button className=" float-right bottone-blu" disabled>
            Caricamento...
          </Button>
        ) : (
          <Button
            className="bottone-blu float-right"
            onClick={() => importaFattureContext.inviaModifica()}
          >
            <i className="bi bi-check"></i> MODIFICA
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdate
