import React, { useContext } from 'react'
import { RiepilogoContext } from '../../../app/context/RiepilogoFatture'

const MancataConsegnaAutofattura = () => {
  const riepilogoContext = useContext(RiepilogoContext)

  return (
    <>
      Mancata consegna{' '}
      <i
        className="bi bi-info-circle-fill"
        onClick={() => riepilogoContext.apriModalMancataConsegnaAutofattura()}
        style={{ cursor: 'pointer' }}
      ></i>
    </>
  )
}

export default MancataConsegnaAutofattura
