import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'

function getCookie(key) {
  const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
  return b ? b.pop() : ''
}

const CookieConsentBar = () => {
  const onAccept = () => {
    window.gtag('js', new Date())
    window.gtag('config', 'G-G6HHJEE5S2', {
      send_page_view: false,
      debug_mode: false,
    })
  }

  const onDecline = () => {
    console.log('Il permesso di tracciamento è stato rifiutato.')
  }

  useEffect(() => {
    if (getCookie('tracking-consent-cookie') === 'ture') {
      window.gtag('js', new Date())
      window.gtag('config', 'G-G6HHJEE5S2', {
        send_page_view: false,
        debug_mode: false,
      })
    }
  }, [])

  return (
    <CookieConsent
      flipButtons
      enableDeclineButton
      location="bottom"
      buttonText="Accetta tutti"
      declineButtonText="Rifiuta tutti"
      cookieName="tracking-consent-cookie"
      overlayClasses="container"
      onAccept={onAccept}
      onDecline={onDecline}
      style={{
        background: 'white',
        zIndex: 50000,
        color: '#373d3f',
        display: 'inline-block',
        border: '1px solid #0583f2',
        borderRadius: '10px',
        marginBottom: '15px',
        marginLeft: '5%',
        marginRight: '5%',
        maxWidth: '90%',
        padding: '10px',
      }}
      buttonWrapperClasses="float-right"
      buttonStyle={{
        color: 'white',
        borderRadius: '10px',
        backgroundColor: '#0583f2',
        border: 'none',
        fontWeight: '500',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '11px',
        paddingRight: '11px',
      }}
      declineButtonStyle={{
        backgroundColor: '#f2b705',
        color: 'white',
        borderRadius: '10px',
        border: 'none',
        fontWeight: '500',
        paddingTop: '7px',
        paddingBottom: '7px',
        paddingLeft: '11px',
        paddingRight: '11px',
      }}
    >
      Sul nostro sito usiamo cookie 🍪 di diverso tipo. Quelli tecnici ci servono per
      farlo funzionare bene, i cookie statistici per raccogliere dei dati aggregati e
      quelli di profilazione anche di terze parti per fornirti contenuti commerciali
      e servizi che rispecchiano le tue preferenze. Se la cosa non ti sta bene, basta
      che clicchi su “Rifiuta tutti”. In questo modo continuerai a navigare sul
      nostro sito senza cookie o altri strumenti di tracciamento, diversi da quelli
      tecnici. Se ti sta bene, clicca semplicemente su “Accetta tutti”. Abbiamo
      descritto le finalità in questa{' '}
      <a href="/documenti/cookie_policy.pdf" target="_blank">
        cookie policy
      </a>{' '}
    </CookieConsent>
  )
}

export default CookieConsentBar
