import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../app/context/User'
import { useHistory } from 'react-router-dom'

const OnlyNotLogged = ({ children }) => {
  const { isLoggedIn, isLoading } = useContext(UserContext)
  const history = useHistory()
  const [attivo, setAttivo] = useState(false)

  useEffect(() => {
    if (isLoading === false) {
      setAttivo(false)
      if (isLoggedIn()) {
        history.push('/fiscomed')
      } else {
        setAttivo(true)
      }
    }
  }, [isLoading, isLoggedIn])

  return <>{attivo && children}</>
}

export default OnlyNotLogged
